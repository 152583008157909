import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import "./EmbedSection.scss"

const EmbedSection = ({embed}) => {
  return (
    <div className="embed-section my-10">
      <div className="container">
        <div className="row">
          <div className="iframe-wrap w-47 m-auto" dangerouslySetInnerHTML={{__html: embed}} />
        </div>
      </div>
    </div>
  )
}

EmbedSection.propTypes = {
  embed: PropTypes.string
}

export const query = graphql`
  fragment PageEmbedSectionFragment on WpPage_Flex_Content_EmbedSection {
    embed
  }
  fragment PostEmbedSectionFragment on WpPost_Flex_Content_EmbedSection {
    embed
  }
  fragment PressFeatureEmbedSectionFragment on WpPressFeature_Flex_Content_EmbedSection {
    embed
  }
  fragment ProjectEmbedSectionFragment on WpProject_Flex_Content_EmbedSection {
    embed
  }
  fragment CompetenceEmbedSectionFragment on WpCompetence_Flex_Content_EmbedSection {
    embed
  }
  fragment JobEmbedSectionFragment on WpJob_Flex_Content_EmbedSection {
    embed
  }
`

export default EmbedSection
