const createTimeline = params => {
  const { keyFrames, startTime, stepTime, reverse } = params;

  const steps = Object.values(keyFrames).reduce((accumulator, currentValue) => {
    return Array.isArray(currentValue)
      ? accumulator + currentValue.length
      : accumulator + 1;
  }, 0);

  const timeline = new Array(steps)
    .fill(0)
    .map((step, index) => startTime + index * stepTime);
  if (reverse) timeline.reverse();

  return timeline;
};

const colormatch = colorName => {
  let colorValue = "";

  switch (colorName) {
    case "":
    case "white":
      colorValue = "#fff";
      break;
    case "brown":
      colorValue = "#cd8f54";
      break;
    case "beige":
      colorValue = "#e6d1bd";
      break;
    case "gray":
      colorValue = "#9d9b95";
      break;
    case "lightblue":
      colorValue = "#bccacb";
      break;
    default:
      colorValue = "#fff";
  }
  return colorValue;
};

const isEvenNumber = n => {
  return n % 2 === 0;
};

export { createTimeline, isEvenNumber, colormatch };
