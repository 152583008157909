/* eslint-disable no-var */
/* eslint-disable vars-on-top */

const reporter = require(`./reporter`);

/**
 * @param {object}          localeDirsRef:     Definition of locale direcotry names. "root" is required. Structure:
 *                                             localeDirectories: {
 *                                                DIRECTORY_KEY: {
 *                                                  LANG_KEY: "/localeDirectoryName"
 *                                                },
 *                                              }
 * @param {string}          languageKey:       As specified in localeDirs.
 * @param {array[string]}   directoryKeys:     As specified in localeDirs. Minimum is empty array.
 * @param {string}          slug:              Locale slug without leading /.
 */

const createPath = (localeDirsRef, languageKey, directoryKeys, slug) => {
  if (!directoryKeys || !Array.isArray(directoryKeys) || !languageKey) {
    reporter.log(
      "waring",
      'Path warning: No directory keys and/or no language key provided. Pass at least: createPath("languageKey", [])'
    );

    return null;
  }

  const getLocaleDir = (arr, lang, dir) => {
    let dirTemp = "";

    if (arr[dir]) {
      if (arr[dir][lang]) {
        dirTemp = arr[dir][lang];
      } else {
        reporter.log(
          "waring",
          `Path warning: Language Key "${lang}" does not exist.`
        );
      }
    } else {
      reporter.log(
        "waring",
        `Path warning: Directory key "${dir}" does not exist.`
      );
    }

    return dirTemp;
  };

  let langPrefix = getLocaleDir(localeDirsRef, languageKey, "root");
  let pageDirectory = "";
  const pageSlug = slug ? `/${slug}` : "";

  if (directoryKeys.length > 0) {
    pageDirectory = directoryKeys.reduce(
      (accumulator, currentKey) =>
        `${accumulator}${getLocaleDir(localeDirsRef, languageKey, currentKey)}`,
      ""
    );
  }

  if (langPrefix === "/") {
    if (pageDirectory || slug) {
      langPrefix = "";
    }
  }

  return `${langPrefix}${pageDirectory}${pageSlug}`;
};

module.exports = { createPath };
