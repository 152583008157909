/* eslint-disable no-underscore-dangle */
import PropTypes from "prop-types"
import React from "react"
import config from "data/SiteConfig"
import {MapToComponents} from "react-map-to-components"
import HeroSection from "Sections/HeroSection/HeroSection.jsx"
import HeroSectionSlider from "Sections/HeroSectionSlider/HeroSectionSlider.jsx"
import ServicesTeaserSection from "Sections/ServicesTeaserSection/ServicesTeaserSection.jsx"
import BasicSliderSection from "Sections/BasicSliderSection/BasicSliderSection.jsx"
import TeaserSection from "Sections/TeaserSection/TeaserSection.jsx"
import CurrentNewsTeaserSection from "Sections/CurrentNewsTeaserSection/CurrentNewsTeaserSection.jsx"
import ProgressSliderSection from "Sections/ProgressSliderSection/ProgressSliderSection.jsx"
import RelatedProjectsSection from "Sections/RelatedProjectsSection/RelatedProjectsSection.jsx"
import RelatedStorySection from "Sections/RelatedStorySection/RelatedStorySection.jsx"
import TextItemsColumnsSection from "Sections/TextItemsColumnsSection/TextItemsColumnsSection.jsx"
import JobOverviewSection from "Sections/JobOverviewSection/JobOverviewSection.jsx"
import JobTeaserSection from "Sections/JobTeaserSection/JobTeaserSection.jsx"
import LocationSection from "Sections/LocationSection/LocationSection.jsx"
import InquirySection from "Sections/InquirySection/InquirySection.jsx"
import HeadlineSection from "Sections/HeadlineSection/HeadlineSection.jsx"
import TextSection from "Sections/TextSection/TextSection.jsx"
import TextAndImagesColumnSection from "Sections/TextAndImagesColumnSection/TextAndImagesColumnSection.jsx"
import ProjectOverviewSection from "Sections/ProjectOverviewSection/ProjectOverviewSection.jsx"
import CompetenceSection from "Sections/CompetenceSection/CompetenceSection.jsx"
import JobApplicationSection from "Sections/JobApplicationSection/JobApplicationSection.jsx"
import JobApplicationForm from "Sections/JobApplicationForm/JobApplicationForm.jsx"
import ContactPersonsSection from "Sections/ContactPersonsSection/ContactPersonsSection.jsx"
import TeamOverviewSection from "Sections/TeamOverviewSection/TeamOverviewSection.jsx"
import TimelineSliderSection from "Sections/TimelineSliderSection/TimelineSliderSection.jsx"
import NewsOverviewSection from "Sections/NewsOverviewSection/NewsOverviewSection.jsx"
import FeaturedContentSection from "Sections/FeaturedContentSection/FeaturedContentSection.jsx"
import DynamicContentSection from "Sections/DynamicContentSection/DynamicContentSection.jsx"
import CustomerSurveySection from "Sections/CustomerSurveySection/CustomerSurveySection.jsx"
import DefaultSection from "Sections/DefaultSection/DefaultSection.jsx"
import EmbedSection from "Sections/EmbedSection/EmbedSection.jsx"

const FlexibleContent = ({
    content,
    location,
    slug,
    // pageName,
    relatedProjects,
    relatedStories,
    dynamicContent,
    shuffledEmployees
  }) => {

    // filter for right page and delete id afterwards
    let contentWithKeys

    if(content) {
      let counter  = 0
      const contentFiltered = content.filter(item => item && Object.keys(item).length > 1)
      contentWithKeys = Object.keys(contentFiltered).map((key) =>{
        const section = contentFiltered[key]
        // console.log("contentFiltered", contentFiltered)
        // console.log("key", key)
        // console.log("section", section)

        // generate type which fits the respective component name
        // for example from page_Flex_Content_HeroSection to Text
        const typeFromQuery = section.__typename || ""
        const type = typeFromQuery.split("Flex_Content_")[1]
        // console.log("type", type)

        const newItem = {
          ...section,
          key: `${section.__typename}--${counter}`,
          type
        }
        counter += 1
        return newItem
      })
    }

  return (
    <div className="flexible-content-wrapper">
      {
        contentWithKeys && contentWithKeys.length > 0 && (
          <MapToComponents
            getKey={section => section.key}
            getType={section => section.type}
            list={contentWithKeys}
            default={DefaultSection}
            map={{
              HeroSection,
              HeroSectionSlider,
              ServicesTeaserSection,
              BasicSliderSection,
              TeaserSection,
              CurrentNewsTeaserSection,
              ProgressSliderSection,
              RelatedProjectsSection,
              RelatedStorySection,
              TextItemsColumnsSection,
              JobOverviewSection,
              JobTeaserSection,
              LocationSection,
              InquirySection,
              HeadlineSection,
              TextSection,
              TextAndImagesColumnSection,
              ProjectOverviewSection,
              CompetenceSection,
              JobApplicationSection,
              JobApplicationForm,
              ContactPersonsSection,
              TeamOverviewSection,
              TimelineSliderSection,
              NewsOverviewSection,
              FeaturedContentSection,
              DynamicContentSection,
              CustomerSurveySection,
              EmbedSection
            }}
            mapDataToProps={{
              HeroSection: ({ data }) => ({
                headline: data.headline,
                contentType: data.selectContentType,
                image: data.image,
                video: data.video,
                poster: data.videoPosterImage,
                hasControls: data.hasControls
              }),
              HeroSectionSlider: ({ data }) => ({
                headline: data.headline,
                slides: data.slides,
              }),
              ServicesTeaserSection: ({ data }) => ({
                headline: data.headline,
                images: data.images,
                fade: data.fade,
                teaserHeadline: data.teaserHeadline,
                teaserText: data.teaserText,
                teaserHtml: data.teaserHtml,
                teaserLinkText: data.teaserLinkText,
                teaserInternalLink: data.teaserInternalLink,
                teaserLinkTarget: data.teaserLinkTarget,
                showTeaserAsButton: !!data.showTeaserAsButton,
              }),
              BasicSliderSection: ({ data }) => ({
                headline: data.headline,
                images: data.images,
                autoplay: data.autoplay,
              }),
              TeaserSection: ({ data }) => ({
                headline: data.headline,
                teaserItems: data.teaserItems,
                linkTarget: data.linkTarget,
                internalLink: data.internalLink && data.internalLink.uri,
                linkText: data.linkText,
                showAllTeaser: slug === config.localeDirectories.competences.de || slug === config.localeDirectories.competences.en,
              }),
              CurrentNewsTeaserSection: ({ data }) => ({
                headline: data.headline,
                linkText: data.linkText,
                internalLink: data.internalLink && data.internalLink.uri,
              }),
              ProgressSliderSection: ({ data }) => ({
                headline: data.headline,
                teaserHeadline: data.teaserHeadline,
                teaserText: data.teaserText,
                teaserLink: data.teaserLink,
                teaserInternalLink: data.teaserInternalLink,
                teaserLinkText: data.teaserLinkText,
                teaserImage: data.teaserBild,
                slides: data.slides,
              }),
              RelatedProjectsSection: ({ data }) => ({
                headline: data.headline,
                relatedProjects: relatedProjects || [],
                location,
              }),
              RelatedStorySection: ({ data }) => ({
                headline: data.headline,
                relatedStories: relatedStories && relatedStories.nodes || [],
              }),
              TextItemsColumnsSection: ({ data }) => ({
                headline: data.headline,
                items: data.textItems,
              }),
              JobOverviewSection: ({ data }) => ({
                headline: data.headline,
              }),
              JobTeaserSection: ({ data }) => ({
                image: data.image,
                headline: data.headline,
                listTitle: data.listTitle,
                applyCta: data.applyCta,
                applicationPage: data.applicationPage,
                jobs: data.jobs,
                hidden: data.hidden,
              }),
              LocationSection: ({ data }) => ({
                headline: data.headline,
                description: data.locationDescription,
                coords: data.locationCoordinates,
              }),
              InquirySection: ({ data }) => ({
                text: data.text,
                inquirySelectionHeadline: data.inquirySelectionHeadline,
              }),
              HeadlineSection: ({ data }) => ({
                headline: data.headline,
                subHeadline: data.subHeadline,
              }),
              TextSection: ({ data }) => ({
                headline: data.headline,
                text: data.text,
                textColumns: data.textColumns,
              }),
              TextAndImagesColumnSection: ({ data }) => ({
                imagesColumn: data.imagesColumn,
                textColumn: data.textColumn,
              }),
              ProjectOverviewSection: ({ data }) => ({
                headline: data.headline,
              }),
              CompetenceSection: ({ data }) => ({
                text: data.text,
              }),
              JobApplicationSection: ({ data }) => ({
                headline: data.headline,
                subHeadline: data.subHeadline,
                contactPerson: data.contactPerson,
                applicationFormPage: data.applicationFormPage,
                showVideo: !!data.showVideo,
                applicationFormPageCta: data.applicationFormPageCta,
                video: data.video,
                poster: data.videoPosterImage,
              }),
              JobApplicationForm: ({ data }) => ({
                buttonLabelApply: data.buttonLabelApply,
                buttonLabelCv: data.buttonLabelCv,
                buttonLabelMoreDocuments: data.buttonLabelMoreDocuments,
                documentInfos: data.documentInfos,
                failureMessage: data.failureMessage,
                headline: data.headline,
                labelDocuments: data.labelDocuments,
                labelPersonalData: data.labelPersonalData,
                labelPosition: data.labelPosition,
                placeholderCity: data.placeholderCity,
                placeholderEmailAddress: data.placeholderEmailAddress,
                placeholderFirstname: data.placeholderFirstname,
                placeholderHouseNumber: data.placeholderHouseNumber,
                placeholderLastname: data.placeholderLastname,
                placeholderMobileNumber: data.placeholderMobileNumber,
                placeholderPhoneNumber: data.placeholderPhoneNumber,
                placeholderPosition: data.placeholderPosition,
                placeholderStreet: data.placeholderStreet,
                placeholderZip: data.placeholderZip,
                labelApplicationText: data.labelApplicationText,
                placeholderApplicationText: data.placeholderApplicationText,
                successMessageHeadline: data.successMessageHeadline,
                successMessage: data.successMessage,
                successMessageLink: data.successMessageLink,
                successMessageLinkText: data.successMessageLinkText,
                successImage: data.successImage,
                location,
              }),
              ContactPersonsSection: ({ data }) => ({
                headline: data.headline,
                subHeadline: data.subHeadline,
                contactPersons: data.contactPersons,
              }),
              TeamOverviewSection: ({ data }) => ({
                headline: data.headline,
                shuffledEmployees: shuffledEmployees && shuffledEmployees || [],
              }),
              TimelineSliderSection: ({ data }) => ({
                headline: data.headline,
                slides: data.slides,
              }),
              NewsOverviewSection: ({ data }) => ({
                headline: data.headline,
              }),
              FeaturedContentSection: ({ data }) => ({
                sectionHeadline: data.sectionHeadline,
                items: data.items,
              }),
              DynamicContentSection: ({ data }) => ({
                sectionHeadline: data.sectionHeadline,
                dynamicContent: dynamicContent || [],
              }),
              CustomerSurveySection: ({ data }) => ({
                sectionHeadline: data.sectionHeadline,
                variant: data.variant,
                steps: data.steps,
                location,
              }),
              EmbedSection: ({ data }) => ({
                embed: data.embed,
              }),
            }}
          />
        )
      }
    </div>
  )
}

FlexibleContent.propTypes = {
  relatedStories: PropTypes.instanceOf(Object),
  relatedProjects: PropTypes.instanceOf(Object)
}

export default FlexibleContent
