/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import classNames from "classnames"
import { useInView } from 'react-intersection-observer'
import PropTypes from "prop-types"
import './RemoveClassInview.scss'

const RemoveClassInview = props => {
  const { children, animationClass, triggerOnce, threshold, rootMargin, root, onClick, other } = props;
  if(!children) return null

  const [ref, inView] = useInView({
    triggerOnce,
    threshold,
    rootMargin,
    root,
  })

  const getClasses = element => {
    return classNames(
      element.props.className,
    );
  }
  const childrenClasses = getClasses(children)

  const removeParent = element => {
    return element.props.children;
  }
  const newChildren = removeParent(children)

  return (
    <div
      ref={ref}
      id={children.props.id}
      className={classNames(
        "inview-wrapper",
        inView ? "" : animationClass,
        childrenClasses,
      )}
      onClick={onClick}
      {...other}
    >
      {newChildren}
    </div>
  );
};

RemoveClassInview.propTypes = {
  animationClass: PropTypes.string,
  onClick: PropTypes.func,
  triggerOnce: PropTypes.bool,
  threshold: PropTypes.number,
  root: PropTypes.element,
  rootMargin: PropTypes.string,
};

RemoveClassInview.defaultProps = {
  animationClass: "will-animate",
  onClick: () => {},
  triggerOnce: true,
  threshold: 0.1,
  root: undefined,
  rootMargin: undefined,
};

export default RemoveClassInview;
