/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../Icon/Icon';
import './Search.scss';

const Search = props => {
  const { 
    placeholder,
    onChange,
    className
  } = props;

  const inputRef = useRef(null);
  const [ value, setValue ] = useState("");
  const [ isFocus, setFocus ] = useState(false);

  const handleChange = event => {
    setValue(event.target.value)
  }

  const handleFocus = () => {
    setFocus(true)
  }

  const handleBlur = event => {
    setFocus(false)

    if(event.keyCode === 13) {
      inputRef.current.blur();
    }

    if(event.keyCode === 27) {
      setValue("");
      inputRef.current.blur();
    }
  }

  useEffect(() => {
    if(onChange !== null) onChange(value);
  }, [value])

  return (
    <div className={classNames("custom-input search-input align-self-end", className)}>
      <div className="search-content d-flex">
        <input
          ref={inputRef}
          type="text"
          spellCheck="false"
          placeholder={isFocus === true ? "" : placeholder}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleBlur}
          className=""
        />
        {
          value !== "" && (
            <div 
              className="icon"
              onClick={() => setValue("")}
            >
              <Icon iconType="plus-small" />
            </div>
          )
        }
      </div>
    </div>
  )
}

Search.defaultProps = {
  onChange: null,
}

Search.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.instanceOf(Function),
  className: PropTypes.string,
}

export default Search
