import React, {useState, useEffect} from 'react'
import {useGlobal, setGlobal} from 'reactn'
import {graphql, useStaticQuery} from 'gatsby'
import classNames from 'classnames'
import PostOverview from 'Layout/PostOverview/PostOverview.jsx'
import SectionHeadline from 'Accessories/SectionHeadline/SectionHeadline.jsx'
import ProjectFilter from './ProjectFilter'

import './ProjectOverviewSection.scss'

const ProjectOverviewSection = ({headline}) => {
  const data = useStaticQuery(graphql`query ProjectOverviewSectionFilterQuery {
  projects: allWpProject(filter: {title: {ne: "DO NOT DELETE"}}, sort: {order: DESC, fields: date}) {
    edges {
      node {
        databaseId
        title
        language {
          slug
        }
        slug
        uri
        livingAreas {
          nodes {
            taxonomy {
              node {
                name
              }
            }
          }
        }
        projectTeaser {
          listTeaser
        }
        terms {
          nodes {
            ... on WpLivingArea {
              id
              name
              slug
              nodeType
            }
          }
        }
        postImages {
          postImages {
            portaitImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 862, quality: 80, placeholder: NONE, layout: CONSTRAINED)
                }
              }
            }
            landscapeImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
}
`)

  const [lang] = useGlobal("lang")
  const [postOverviewItemsVisible] = useGlobal("postOverviewItemsVisible")

  const projects = data.projects.edges
  const items = projects.filter(project => project.node.language.slug === lang)
  const [initialItems] = useState(items || [])
  const [filteredItems, setFilteredItems] = useState(initialItems);
  const [livingArea, setLivingArea] = useState("")

  const updateItemsVisible = () => {
    if (postOverviewItemsVisible < filteredItems.length) {
      setGlobal({postOverviewItemsVisible: postOverviewItemsVisible + 10})
    }
  }

  // Get all unique Living Area Taxonomy Options for Projects to use for generating the Filter Select Options
  const livingAreaOptionTemp = new Set();
  items.map(item => {
    const livingAreas = item.node.terms.nodes.filter(itemAttribute => itemAttribute.nodeType === "LivingArea")
    return livingAreas.map(livingAreaName => {
      return livingAreaOptionTemp.add(livingAreaName.name)
    })
  })

  const livingAreaOptions = Array.from(livingAreaOptionTemp).sort().reverse()

  const changeHandlerLivingArea = (value) => (
    setLivingArea(value !== "all" ? value : "")
  )

  const filterItems = () => {
    let updatedList = initialItems;

    if(livingArea) updatedList = updatedList.filter(item => item.node.terms.nodes.some(i => i.nodeType === "LivingArea" && i.name === livingArea))

    setFilteredItems(updatedList);
  }

  useEffect(() => {
    filterItems()
  }, [livingArea])

  return (
    <div className="project-overview-section section">
      <div className="container">
        {headline && (
          <SectionHeadline
            headline={headline}
          />
        )}
        <ProjectFilter
          livingAreaOptions={livingAreaOptions}
          changeHandlerLivingArea={changeHandlerLivingArea}
        />
        <PostOverview items={filteredItems.slice(0, postOverviewItemsVisible)} initialItems={initialItems} postType="project" />
        <div className={classNames("row", postOverviewItemsVisible >= filteredItems.length ? "d-none" : "")}>
          <div className="w-37 w-sm-18 w-md-14 mx-auto mt-5 mt-md-10">
            <div
              className="showmore-button d-flex justify-content-center"
              onClick={updateItemsVisible}
              onKeyDown={updateItemsVisible}
              tabIndex={0}
              role="button"
            >
              <span className="text-link">{lang === "de" ? "Mehr Projekte anzeigen." : "Show more projects."}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageProjectOverviewSectionFragment on WpPage_Flex_Content_ProjectOverviewSection {
    headline
  }
  fragment PostProjectOverviewSectionFragment on WpPost_Flex_Content_ProjectOverviewSection {
    headline
  }
  fragment PressFeatureProjectOverviewSectionFragment on WpPressFeature_Flex_Content_ProjectOverviewSection {
    headline
  }
  fragment ProjectProjectOverviewSectionFragment on WpProject_Flex_Content_ProjectOverviewSection {
    headline
  }
  fragment CompetenceProjectOverviewSectionFragment on WpCompetence_Flex_Content_ProjectOverviewSection {
    headline
  }
  fragment JobProjectOverviewSectionFragment on WpJob_Flex_Content_ProjectOverviewSection {
    headline
  }
`

export default ProjectOverviewSection
