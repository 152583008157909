import React, { useState, useEffect } from "react"
import { useGlobal } from "reactn"
import shuffle from 'shuffle-array'
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import SectionHeadline from "Accessories/SectionHeadline/SectionHeadline.jsx"
import Teaser from "Layout/Teaser/Teaser.jsx"
import TeamFilter from "./TeamFilter"
import "./TeamOverviewSection.scss"
import {getImage} from "gatsby-plugin-image";

const TeamOverviewSection = ({ headline, shuffledEmployees }) => {
  const data = useStaticQuery(graphql`
    query TeamOverviewSectionFilterQuery {
      departmentsToFilter: allWpDepartment (
        filter: {filterDepartments: {taxInFilter: {eq: true}}},
        sort: {order: DESC, fields: name},
      ) {
        edges {
          node {
            databaseId
          }
        }
      }
    }
  `)

  const [lang] = useGlobal("lang")
  const departmentsToFilter = data.departmentsToFilter.edges
  const employees = shuffledEmployees

  // Get all unique Comptence Taxonomy Options for Projects to use for generating the Filter Select Options
  const departmentOptionsTemp = new Set()
  const checkFilters = (WPID) => {
    let check
    departmentsToFilter.forEach((item) => {
      if(item.node.databaseId === WPID){
        check = true
      }
      return check
    })
    return check ? true : null
  }

  employees.map(item => {
    const departments = item.node.departments.nodes.filter(itemAttribute => checkFilters(itemAttribute.termTaxonomyId) === true)
    return departments.map(departmentName => {
      return departmentOptionsTemp.add(departmentName.name.toString())
    })
  })

  const departmentOptions = (Array.from(departmentOptionsTemp)).reverse()


  const initialItems = employees || []
  const [items, setItems] = useState(initialItems)
  const [department, setDepartment] = useState("")
  const [itemsVisible, setItemsVisible] = useState(8)

  const updateItemsVisible = () => {
    if (itemsVisible < items.length) setItemsVisible(itemsVisible + 8)
  }

  const changeHandlerDepartment = (value) => (
    setDepartment(value !== "all" ? value : "")
  )

  const filterItems = () => {
    let updatedList = initialItems
    updatedList = updatedList.filter(item => {
      const departmentQueryString = item.node.departments.nodes.filter(itemTaxonomy => itemTaxonomy.taxonomy.node.name === "department")[0] ? item.node.departments.nodes.filter(itemTaxonomy => itemTaxonomy.taxonomy.node.name === "department")[0].name.toString().toLowerCase() : ""

      return (
        (department === "" ||
          departmentQueryString.search(department.toLowerCase()) !== -1)
      )
    })
    setItems(updatedList)
  }

  const searchItems = (value) => {
    let updatedList = null

    if (department) {
      updatedList = items !== null ? items : initialItems
    } else {
      updatedList = initialItems
    }
    updatedList = updatedList.filter((item) =>  {
      const itemName = item.node.title.toString().toLowerCase()
      return (
        itemName.search( value.toLowerCase()) !== -1
      )
    })
    setItems(updatedList)
  }

  useEffect(() => {
    filterItems()
  }, [department])

  useEffect(() => {
    setItems(shuffle(items))
  }, [])

  return (
    <div className="team-overview-section section">
      <div className="container">
        {headline && <SectionHeadline headline={headline} />}
        <TeamFilter
          departmentOptions={departmentOptions}
          changeHandlerDepartment={changeHandlerDepartment}
          department={department}
          searchItems={searchItems}
          searchPlaceholderTextDe="Namen eingeben"
          searchPlaceholderTextEn="Search name"
        />
        <div className="team-grid grid-2 grid-md-4 grid-sm-2 grid-rg-15 grid-xs-1 px-xs-2 px-md-0 ml-md-1">
          {
            items && items.slice(0, itemsVisible).map((item, index) => (
              <Teaser
                key={item.node && item.node.databaseId || item.id || index}
                alignCenter
                altText={item?.node?.employeeInfo?.employee?.image?.altText || item?.node?.employeeInfo?.employee?.image?.title}
                image={getImage(item?.node?.employeeInfo?.employee?.image?.localFile)}
                previewSrc={{src: item?.node?.employeeInfo?.employee?.image?.sourceUrl || `${item?.node?.employeeInfo?.employee?.image}` || null}}
                text={item?.node?.employeeInfo?.employee?.name}
                subText={item?.node?.employeeInfo?.employee?.function || ""}
                linkText={item?.node?.employeeInfo?.employee?.email}
                externalLink={item?.node?.employeeInfo?.employee?.email}
                linkSecondaryTarget={item?.node?.employeeInfo?.employee?.phone}
                linkType="email"
                linkStyle="appear"
                aspectRatios={[1.335]}
              />
            ))
          }
        </div>
        <div className={classNames("row", itemsVisible >= items.length ? "d-none" : "")}>
          <div className="w-37 w-sm-18 w-md-14 mx-auto mt-5 mt-md-10">
            <div
              className="showmore-button d-flex justify-content-center"
              onClick={updateItemsVisible}
              onKeyDown={updateItemsVisible}
              tabIndex={0}
              role="button"
            >
              <span className="text-link">{lang === "de" ? "Mehr Mitarbeiter*innen anzeigen." : "Show more people."}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment PageTeamOverviewSectionFragment on WpPage_Flex_Content_TeamOverviewSection {
    headline
  }
  fragment PostTeamOverviewSectionFragment on WpPost_Flex_Content_TeamOverviewSection {
    headline
  }
  fragment PressFeatureTeamOverviewSectionFragment on WpPressFeature_Flex_Content_TeamOverviewSection {
    headline
  }
  fragment ProjectTeamOverviewSectionFragment on WpProject_Flex_Content_TeamOverviewSection {
    headline
  }
  fragment CompetenceTeamOverviewSectionFragment on WpCompetence_Flex_Content_TeamOverviewSection {
    headline
  }
  fragment JobTeamOverviewSectionFragment on WpJob_Flex_Content_TeamOverviewSection {
    headline
  }
`

export default TeamOverviewSection
