import React from "react"
import {graphql} from "gatsby"
import PropTypes from "prop-types"
import SectionHeadline from "Accessories/SectionHeadline/SectionHeadline.jsx"
import SurveyForm from "Layout/Forms/SurveyForm/SurveyForm.jsx"
import "./CustomerSurveySection.scss"

const CustomerSurveySection = ({sectionHeadline, variant, steps, location}) => {
  return steps ? (
    <div className="section related-stories-section customer-survey-section-container">
      <div className="container d-flex flex-column">
        {sectionHeadline && (
          <SectionHeadline headline={sectionHeadline} />
        )}
        <div className="row w-46 align-self-end">
          <div className="form-container">
            <SurveyForm
              variant={variant}
              survey={steps}
              location={location}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null
}

CustomerSurveySection.propTypes = {
  sectionHeadline: PropTypes.string,
  variant: PropTypes.string,
  steps: PropTypes.instanceOf(Object)
}

export const query = graphql`fragment PageCustomerSurveySectionFragment on WpPage_Flex_Content_CustomerSurveySection {
  sectionHeadline
  steps {
    background {
      image {
        landscape {
          sourceUrl
          mediaDetails {
            height
            width
          }
          altText
          title
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        portrait {
          sourceUrl
          mediaDetails {
            height
            width
          }
          altText
          title
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
      type
      video {
        landscape {
          mediaItemUrl
          mediaDetails {
            height
            width
          }
        }
        portrait {
          mediaItemUrl
          mediaDetails {
            height
            width
          }
        }
      }
    }
    buttons {
      label
      value
      skip
      link {
        ... on WpPost {
          internal {
            type
          }
          language {
            slug
          }
          databaseId
          uri
          title
        }
        ... on WpPage {
          internal {
            type
          }
          language {
            slug
          }
          databaseId
          uri
          title
        }
        ... on WpJob {
          internal {
            type
          }
          language {
            slug
          }
          databaseId
          uri
          title
        }
        ... on WpProject {
          internal {
            type
          }
          language {
            slug
          }
          databaseId
          uri
          title
        }
        ... on WpCompetence {
          internal {
            type
          }
          language {
            slug
          }
          databaseId
          uri
          title
        }
        ... on WpEmployee {
          internal {
            type
          }
          language {
            slug
          }
          databaseId
          uri
          title
        }
        ... on WpPressFeature {
          internal {
            type
          }
          language {
            slug
          }
          databaseId
          uri
          title
        }
      }
    }
    icon {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
    paragraph
    question
    theme
    title
    type
  }
  variant
}

fragment PostCustomerSurveySectionFragment on WpPost_Flex_Content_CustomerSurveySection {
  sectionHeadline
  variant
}

fragment PressFeatureCustomerSurveySectionFragment on WpPressFeature_Flex_Content_CustomerSurveySection {
  sectionHeadline
  variant
}

fragment ProjectCustomerSurveySectionFragment on WpProject_Flex_Content_CustomerSurveySection {
  sectionHeadline
  variant
}

fragment CompetenceCustomerSurveySectionFragment on WpCompetence_Flex_Content_CustomerSurveySection {
  sectionHeadline
  variant
}

fragment JobCustomerSurveySectionFragment on WpJob_Flex_Content_CustomerSurveySection {
  sectionHeadline
  variant
}
`

export default CustomerSurveySection
