/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-distracting-elements */
import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import PageTransitionLink from "Layout/PageTransition/PageTransitionLink.jsx"
import SectionHeadline from "Accessories/SectionHeadline/SectionHeadline.jsx"
import Teaser from "Layout/Teaser/Teaser.jsx"
import classNames from "classnames"
import NewsletterForm from "../Forms/NewsletterForm/NewsletterForm"
import {errorDirectory} from "../../../../data/SiteConfig"
import "./Footer.scss"
import {getImage} from "gatsby-plugin-image";

const Footer = ({
  teasers,
  headline,
  location,
  lang,
  translations,
}) => {
  const isLangDE = lang === "de"
  const isErrorPage = location && location.pathname.includes(errorDirectory)

  const translationLinkDE = translations && translations.filter(item => item.language.slug === "de")[0] || { uri: null }
  const translationLinkEN = translations && translations.filter(item => item.language.slug === "en")[0] || { uri: null }

  const data = useStaticQuery(graphql`query FooterQuery {
  menus: allWpMenu {
    edges {
      node {
        name
        menuItems {
          nodes {
            url
            label
          }
        }
      }
    }
  }
  optionsPage: wp {
    globalData {
      footer {
        headline
        footerTeasers {
          teaserText
          teaserLink
          internalTeaserLink {
            ... on WpContentNode {
              uri
            }
          }
          teaserLinkText
          teaserVisual {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 805, quality: 80, placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
        }
      }
      footerEn {
        headlineEn
        footerTeasersEn {
          teaserText
          teaserLink
          internalTeaserLink {
            ... on WpContentNode {
              uri
            }
          }
          teaserLinkText
          teaserVisual {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 805, quality: 80, placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
}
`)
  const { menus, optionsPage } = data
  const legalMenu = isLangDE ? menus.edges.filter(item => item.node.name === "Legal Menu - DE") : menus.edges.filter(item => item.node.name === "Legal Menu - EN")
  const footerMenu = isLangDE ? menus.edges.filter(item => item.node.name === "Footer Menu - DE") : menus.edges.filter(item => item.node.name === "Footer Menu - EN")

  const footerTeasers = isLangDE && optionsPage.globalData.footer.footerTeasers || optionsPage.globalData.footerEn.footerTeasersEn
  const teaserItems = teasers || footerTeasers
  const sectionHeadline = headline || isLangDE && optionsPage.globalData.footer.headline || optionsPage.globalData.footerEn.headlineEn

  return <>
    <footer className="footer-section section pb-0">
      {
        !isErrorPage && (
          <div className="footer-main pt-15 container">
            { sectionHeadline && ( <SectionHeadline headline={sectionHeadline} /> ) }
            <div className="row flex-column flex-md-row justify-content-center pb-15 pb-md-30">
              {
                teaserItems && teaserItems.map(teaser => (
                  <Teaser
                    key={teaser.teaserText}
                    altText={teaser.teaserVisual && teaser.teaserVisual.altText || teaser.teaserVisual && teaser.teaserVisual.title}
                    image={getImage(teaser?.teaserVisual?.localFile)}
                    previewSrc={{
                      src: teaser.teaserVisual && teaser.teaserVisual.sourceUrl || typeof teaser.teaserVisual === "string" && teaser.teaserVisual || null
                    }}
                    text={teaser.teaserText}
                    internalLink={teaser.internalTeaserLink && teaser.internalTeaserLink.uri}
                    externalLink={teaser.teaserLink}
                    linkText={teaser.teaserLinkText}
                    classes={classNames(teaserItems.length === 1 ? "w-45 w-md-21" : "w-45 w-md-14", "mb-5 mb-md-0 ml-1 ml-md-0")}
                  />
                ))
              }
            </div>
          </div>
        )
      }
      {
        !isErrorPage && <NewsletterForm key={`Newsletter--${lang}`} lang={lang} />
      }
      <div className="footer-bottom flex-column flex-md-row d-flex justify-content-center pb-12 pb-md-9">
        <div className="footer-nav-container mb-1 mb-lg-0">
          <nav>
            <ul className="d-flex justify-content-center flex-wrap">
              {
                footerMenu.length && footerMenu[0].node.menuItems.nodes.map((menuItem) => (
                  <li key={menuItem.url} className="text-s">
                    <PageTransitionLink
                      internalLink={!menuItem.url.includes("www.") && menuItem.url}
                      externalLink={menuItem.url.includes("www.") && menuItem.url}
                      className="nav-item-link text-link"
                    >
                      {menuItem.label}
                    </PageTransitionLink>
                  </li>
                ))
              }
              <div className="legal d-flex">
                {
                  legalMenu.length > 0 && legalMenu[0].node.menuItems.nodes.map((menuItem) => (
                    <li key={menuItem.url} className="text-s">
                      <PageTransitionLink
                        internalLink={menuItem.url}
                        className="nav-item-link text-link legal-nav"
                      >
                        {menuItem.label}
                      </PageTransitionLink>
                    </li>
                  ))
                }
              </div>
              <li className="text-s language-switcher">
                {isLangDE && (
                  <PageTransitionLink
                    internalLink={translationLinkEN.uri || `${location.pathname}/`}
                    lang={translationLinkEN.uri && "en" || ""}
                    activeClassName="is-active"
                  >
                    {translationLinkEN.uri && "EN" || "DE"}
                  </PageTransitionLink>
                ) || (
                  <PageTransitionLink
                    internalLink={translationLinkDE.uri || `${location.pathname}/`}
                    lang={translationLinkDE.uri && "de" || ""}
                    activeClassName="is-active"
                  >
                    {translationLinkDE.uri && "DE" || "EN"}
                  </PageTransitionLink>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  </>;
}

export const query = graphql`fragment PageFooterFragment on WpPage {
  footer {
    headline
    footerTeasers {
      teaserLink
      internalTeaserLink {
        ... on WpContentNode {
          uri
        }
      }
      teaserLinkText
      teaserText
      teaserVisual {
        sourceUrl
        mediaDetails {
          height
          width
        }
        altText
        title
        localFile {
          childImageSharp {
            gatsbyImageData(width: 805, quality: 80, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}

fragment PostFooterFragment on WpPost {
  footer {
    headline
    footerTeasers {
      teaserLink
      internalTeaserLink {
        ... on WpContentNode {
          uri
        }
      }
      teaserLinkText
      teaserText
      teaserVisual {
        sourceUrl
        mediaDetails {
          height
          width
        }
        altText
        title
        localFile {
          childImageSharp {
            gatsbyImageData(width: 805, quality: 80, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}

fragment PressFeatureFooterFragment on WpPressFeature {
  footer {
    headline
    footerTeasers {
      teaserLink
      internalTeaserLink {
        ... on WpContentNode {
          uri
        }
      }
      teaserLinkText
      teaserText
      teaserVisual {
        sourceUrl
        mediaDetails {
          height
          width
        }
        altText
        title
        localFile {
          childImageSharp {
            gatsbyImageData(width: 805, quality: 80, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}

fragment ProjectFooterFragment on WpProject {
  footer {
    headline
    footerTeasers {
      teaserLink
      internalTeaserLink {
        ... on WpContentNode {
          uri
        }
      }
      teaserLinkText
      teaserText
      teaserVisual {
        sourceUrl
        mediaDetails {
          height
          width
        }
        altText
        title
        localFile {
          childImageSharp {
            gatsbyImageData(width: 805, quality: 80, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}

fragment CompetenceFooterFragment on WpCompetence {
  footer {
    headline
    footerTeasers {
      teaserLink
      internalTeaserLink {
        ... on WpContentNode {
          uri
        }
      }
      teaserLinkText
      teaserText
      teaserVisual {
        sourceUrl
        mediaDetails {
          height
          width
        }
        altText
        title
        localFile {
          childImageSharp {
            gatsbyImageData(width: 805, quality: 80, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}

fragment JobFooterFragment on WpJob {
  footer {
    headline
    footerTeasers {
      teaserLink
      internalTeaserLink {
        ... on WpContentNode {
          uri
        }
      }
      teaserLinkText
      teaserText
      teaserVisual {
        sourceUrl
        mediaDetails {
          height
          width
        }
        altText
        title
        localFile {
          childImageSharp {
            gatsbyImageData(width: 805, quality: 80, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}
`

export default Footer
