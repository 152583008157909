import React from "react"
import { PropTypes } from "prop-types"
import classNames from "classnames"

import { ReactComponent as SvgIconArrow } from "static/assets/ui/arrow.svg"
import { ReactComponent as SvgIconCircledArrow } from "static/assets/ui/circled-arrow.svg"
import { ReactComponent as SvgIconCheckboxBlank } from "static/assets/ui/checkbox-blank.svg"
import { ReactComponent as SvgIconCheckboxChecked } from "static/assets/ui/checkbox-checked.svg"
import { ReactComponent as SvgIconChevron } from "static/assets/ui/chevron.svg"
import { ReactComponent as SvgIconMenu } from "static/assets/ui/menu.svg"
import { ReactComponent as SvgIconPlus } from "static/assets/ui/plus.svg"
import { ReactComponent as SvgIconPlusSmall } from "static/assets/ui/plus-small.svg"
import { ReactComponent as SvgIconCheck } from "static/assets/ui/check.svg"
import { ReactComponent as SvgIconPlay } from "static/assets/ui/play.svg"
import { ReactComponent as SvgIconPause } from "static/assets/ui/pause.svg"
import { ReactComponent as SvgIconUnmute } from "static/assets/ui/unmute.svg"
import { ReactComponent as SvgIconMute } from "static/assets/ui/mute.svg"
import "./Icon.scss"

const useIcon = (string) => {
  switch (string) {
    case "arrow":
      return <SvgIconArrow />

    case "circled-arrow":
      return <SvgIconCircledArrow />

    case "checkboxBlank":
      return <SvgIconCheckboxBlank />

    case "checkboxChecked":
      return <SvgIconCheckboxChecked />

    case "chevron":
      return <SvgIconChevron />

    case "menu":
      return <SvgIconMenu />

    case "plus":
      return <SvgIconPlus />

    case "plus-small":
      return <SvgIconPlusSmall />

    case "check":
      return <SvgIconCheck />

    default:
      break
  }
  return false
}

const Icon = ({ iconType, rotation, classes, onClick }) => {
  return (
    <div
      className={classNames("icon", classes, {
        "rotate-45deg": rotation === "45deg",
        "rotate-90deg": rotation === "90deg",
        "rotate-180deg": rotation === "180deg",
        "rotate-270deg": rotation === "270deg"
      })}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      role="button"
    >
      {useIcon(iconType)}
    </div>
  )
}

Icon.propTypes = {
  iconType: PropTypes.string.isRequired,
  rotation: PropTypes.string,
  classes: PropTypes.string,
  onClick: PropTypes.func
}

export default Icon
