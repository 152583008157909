import React from 'react';
import { graphql } from "gatsby";
import PropTypes from 'prop-types';
import RemoveClassInview from "Accessories/RemoveClassInview/RemoveClassInview.jsx";
import ImageContainer from 'Accessories/ImageContainer/ImageContainer.jsx'

import './TextAndImagesColumnSection.scss';
import {getImage} from "gatsby-plugin-image";

const TextAndImagesColumnSection = props => {

  // eslint-disable-next-line no-unused-vars
  const { imagesColumn, textColumn } = props

  return (
    <div className="section text-and-images-column-section">
      <div className="container">
        <div className="row">
          <RemoveClassInview>
            <div className="images-column w-47 w-md-18 w-lg-23">
              {
                imagesColumn && imagesColumn.map(image => (
                  <ImageContainer
                    altText={image.image && image.image.altText || image.image && image.image.title}
                    image={getImage(image?.image?.localFile)}
                    previewSrc={{
                      src: image.image && image.image.sourceUrl || typeof image.image === "string" && image.image || null
                    }}
                    useStandardImage
                    aspectRatios={[1.333, 0.70]}
                    classes="mb-5"
                  />
                ))
              }
            </div>
          </RemoveClassInview>
          <RemoveClassInview>
            <div className="text-column w-md-26 w-lg-22 ml-md-auto mr-md-1">
              {
                textColumn && textColumn.map(textItem => (
                  <div className="text-container mb-5 mx-1 mx-md-0">
                    <h3 className="mb-1 w-md-15">{textItem.headline}</h3>
                    <p>{textItem.text}</p>
                  </div>
                ))
              }
            </div>
          </RemoveClassInview>
        </div>
      </div>
    </div>
  );
};

TextAndImagesColumnSection.propTypes = {
  imagesColumn: PropTypes.instanceOf(Array),
  textColumn: PropTypes.string,
};

export const query = graphql`fragment PageTextAndImagesColumnSectionFragment on WpPage_Flex_Content_TextAndImagesColumnSection {
  imagesColumn {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  textColumn {
    headline
    text
  }
}

fragment PostTextAndImagesColumnSectionFragment on WpPost_Flex_Content_TextAndImagesColumnSection {
  imagesColumn {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  textColumn {
    headline
    text
  }
}

fragment PressFeatureTextAndImagesColumnSectionFragment on WpPressFeature_Flex_Content_TextAndImagesColumnSection {
  imagesColumn {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  textColumn {
    headline
    text
  }
}

fragment ProjectTextAndImagesColumnSectionFragment on WpProject_Flex_Content_TextAndImagesColumnSection {
  imagesColumn {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  textColumn {
    headline
    text
  }
}

fragment CompetenceTextAndImagesColumnSectionFragment on WpCompetence_Flex_Content_TextAndImagesColumnSection {
  imagesColumn {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  textColumn {
    headline
    text
  }
}

fragment JobTextAndImagesColumnSectionFragment on WpJob_Flex_Content_TextAndImagesColumnSection {
  imagesColumn {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
  textColumn {
    headline
    text
  }
}
`

export default TextAndImagesColumnSection;
