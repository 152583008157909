import React from 'react'
import PageTransitionLink from 'Layout/PageTransition/PageTransitionLink.jsx'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './TeaserDescription.scss'

const TeaserDescription = ({
    headline,
    text,
    subText,
    subTextIsHeadline,
    linkText,
    linkTarget,
    internalLink,
    linkSecondaryTarget,
    showLinkAsButton,
    alignCenter,
    classes,
    isHtml
  }) => {
    // console.log("subTextIsHeadline", subTextIsHeadline)

  const renderContent = () => (
    <>
      {
        headline && <h3 className="mb-2">{headline}</h3>
      }
      {
        text && (text && !isHtml) ? <div className="teaser-text">{text}</div> : <div className="teaser-html" dangerouslySetInnerHTML={{__html: text}} />
      }
    </>
  )

  const resolvedLinkTarget = linkTarget || linkText

  return (
    <>
      {
        internalLink ? (
          <PageTransitionLink
            internalLink={internalLink}
            externalLink={linkTarget}
            className={classNames("teaser-description teaser-link", alignCenter && "text-align-center", classes)}
          >
            {
              renderContent()
            }
            {
              linkText && <p className={`${showLinkAsButton ? "btn btn-submit btn-border" : "text-link"}`}>{linkText}</p>
            }
          </PageTransitionLink>
        ) : (
          <div className={classNames("teaser-description", alignCenter && "text-align-center", classes)}>
            {
              renderContent()
            }
            {linkText && !linkSecondaryTarget && (
              <div className="text-part">
                {subText && !subTextIsHeadline && (<span className="d-block" dangerouslySetInnerHTML={{__html: subText}} />)}
                <a href={resolvedLinkTarget.indexOf('@') !== -1 ? `mailto:${resolvedLinkTarget}` : resolvedLinkTarget} className="teaser-mail d-inline text-link">{linkText}</a>
              </div>
            )}
            {!linkText && linkSecondaryTarget && (
              <div className="text-part">
                {subText && !subTextIsHeadline && (<span className="d-block" dangerouslySetInnerHTML={{__html: subText}} />)}
                <a href={`tel:${linkSecondaryTarget}`} className="teaser-phone d-inline text-link">{linkSecondaryTarget}</a>
              </div>
            )}
            {!linkText && !linkSecondaryTarget && !subTextIsHeadline && (
              <div className="text-part">
                {subText && (<span className="d-block" dangerouslySetInnerHTML={{__html: subText}} />)}
              </div>
            )}
            {(linkText && linkSecondaryTarget) && (
              <div className="multi-link-wrapper">
                {subText && !subTextIsHeadline && (<span className="d-block" dangerouslySetInnerHTML={{__html: subText}} />)}
                <a href={resolvedLinkTarget.indexOf('@') !== -1 ? `mailto:${resolvedLinkTarget}` : resolvedLinkTarget} className="teaser-mail d-inline text-link">{linkText}</a>
                <br />
                <a href={`tel:${linkSecondaryTarget}`} className="teaser-phone d-inline text-link">{linkSecondaryTarget}</a>
              </div>
            )}
          </div>
        )
      }
    </>
  )
}

TeaserDescription.propTypes = {
  headline: PropTypes.string,
  text: PropTypes.string,
  subText: PropTypes.string,
  subTextIsHeadline: PropTypes.bool,
  linkText: PropTypes.string,
  linkTarget: PropTypes.string,
  internalLink: PropTypes.string,
  alignCenter: PropTypes.bool,
  classes: PropTypes.string,
  showLinkAsButton: PropTypes.bool,
}

TeaserDescription.defaultProps = {
  showLinkAsButton: false,
}

export default TeaserDescription
