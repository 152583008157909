/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../Icon/Icon';
import './Select.scss';

const Select = props => {
  const {
    label,
    unselect,
    options,
    onChange,
    isFullWidth,
    className
  } = props;

  const [ value, setValue ] = useState("");
  const [ inputLabel, setInputLabel ] = useState(value || label);
  const [ isExpanded, setExpanded ] = useState(false);

  const handleSelect = (optionLabel, optionValue) => {
    setValue(optionValue);
    setInputLabel(optionLabel || label);
    setExpanded(false)
  }

  useEffect(() => {
    if(onChange !== null) onChange(value);
  })

  return (
    <div
      role="listbox"
      tabIndex="0"
      onMouseLeave={() => setExpanded(false)}
      onClick={() => setExpanded(!isExpanded)}
      className={classNames("custom-input select-input align-self-end", isFullWidth && "full-width", value && "has-selection", className)}
    >
      <div className="label">
        <div className="label-content">
          <div className="text">
            {inputLabel || "Filter"}
          </div>
          <div className={classNames("icon", isExpanded && "rotate")}>
            <Icon iconType="chevron" />
          </div>
        </div>
      </div>
      <input
        value={value}
        type="hidden"
      />
      {/* {
        isExpanded && (
        )
      } */}
      <div className={classNames("options", isExpanded && "is-expanded")}>
        {
          unselect && (
            <div
              role="option"
              tabIndex="-1"
              aria-selected={value !== ""}
              onClick={() => handleSelect("", "")}
              className="option unselect"
            >
              {unselect}
            </div>
          )
        }
        {
          options && options.map(option => (
            <div
              role="option"
              tabIndex="-1"
              aria-selected={value === option.value}
              onClick={() => handleSelect(option.label, option.value)}
              key={option.value}
              className={classNames("option", value === option.value && "is-active")}
            >
              {option.label}
            </div>
          ))
        }
      </div>
    </div>
  )
}

Select.defaultProps = {
  onChange: null,
}

Select.propTypes = {
  label: PropTypes.string,
  unselect: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape(
    {
      label: PropTypes.string,
      value: PropTypes.string
    }
  )),
  className: PropTypes.string,
  onChange: PropTypes.instanceOf(Function),
  isFullWidth: PropTypes.bool,
};

export default Select;
