import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useGlobal } from "reactn"
import classNames from "classnames"
import CookieConsent from "react-cookie-consent"
import Icon from "../Icon/Icon"
import "./CookieNotice.scss"

const CookieNotice = () => {
  const data = useStaticQuery(graphql`
    query CookieMessageQuery {
      optionsPage: wp {
        globalData {
          cookieBanner {
            cookie {
              text
              textEn
            }
          }
        }
      }
    }
  `)

  const [lang] = useGlobal("lang")
  const isLangDE = lang === "de"
  const [cookiePreference, setCookiePreference] = useState(null)
  const { optionsPage } = data

  const handleCookiePreference = (value) => {
    setCookiePreference(value)

    if(value && (typeof window !== "undefined")) {
      window.trackGoogleAnalytics()
    }
  }

  return (
    <CookieConsent
      cookieName="EhamCookieConsent"
      buttonText={<Icon iconType="check" />}
      declineButtonText={<Icon iconType="plus" rotation="45deg" />}
      location="bottom"
      disableStyles
      disableButtonStyles
      enableDeclineButton
      flipButtons
      setDeclineCookie
      hideOnAccept={false}
      hideOnDecline={false}
      containerClasses={classNames("cookie-notice-wrapper d-flex justify-content-between px-1 px-lg-1 py-2 py-lg-1 bg-sand", cookiePreference !== null ? "is-hidden" : "")}
      contentClasses="d-flex align-items-center mr-1 mr-md-0 flex-grow-1"
      buttonWrapperClasses="d-flex"
      declineButtonClasses="ml-2 ml-lg-1"
      onAccept={() => handleCookiePreference(true)}
      onDecline={() => handleCookiePreference(false)}
      expires={90}
    >
      <div
        dangerouslySetInnerHTML={{ __html:
          isLangDE ?
            optionsPage.globalData.cookieBanner.cookie.text :
            optionsPage.globalData.cookieBanner.cookie.textEn
        }}
      />
    </CookieConsent>
  )
}

export default CookieNotice
