import React, {useState} from 'react'
import {useGlobal} from 'reactn'
import classNames from 'classnames'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import Icon from 'Accessories/Icon/Icon.jsx'
import Button from 'Accessories/Button/Button.jsx'
import './InquiryForm.scss'

const InquiryForm = ({classes}) => {
  const [lang] = useGlobal("lang")

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [tel, setTel] = useState("")
  const [telCallback, setTelCallback] = useState(false)
  const [message, setMessage] = useState("")
  const [submitRequestSuccess, setSubmitRequestSuccess] = useState()
  const [submitConfirmationSuccess, setSubmitConfirmationSuccess] = useState(false)

  const submitToServer = async () => {
    try {
      const data = await fetch(`/.netlify/functions/mail`, {
        method: "POST",
        body: JSON.stringify({
          name, email, tel, telCallback, message
        }),
        headers: {'Content-Type': 'application/json'}
      })
      setSubmitRequestSuccess(true)
      return data
    }
    catch(err) {
      // eslint-disable-next-line no-console
      console.log('Error: ', err)
      setSubmitRequestSuccess(false)
      return false
    }
  }

  const submitConfirmation = async () => {
    try {
      const confirmationData = await fetch(`/.netlify/functions/confirmation`, {
        method: "POST",
        body: JSON.stringify({
          email, telCallback, lang
        }),
        headers: {'Content-Type': 'application/json'}
      })
      setSubmitConfirmationSuccess(true)
      return confirmationData
    }
    catch(err) {
      // eslint-disable-next-line no-console
      console.log('Error: ', err)
      setSubmitConfirmationSuccess(false)
      return false
    }
  }

  const toggleTelCallback = () => {
    setTelCallback(!telCallback)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    submitToServer()
    submitConfirmation()
  }

  return (
    <div className={classNames("flex-column form-container flex-lg-row justify-content-between", classes)}>
      <form className="form-wrapper inquiry-form" onSubmit={(e) => handleSubmit(e)}>
        <div className="input-wrapper d-flex flex-column">
          {
            !submitRequestSuccess && !submitConfirmationSuccess && (
            <>
              <Input
                placeholder={lang === "de" ? "Vorname und Nachname" : "First name and surname"}
                inputProps={{
                  'aria-label': 'first name and surname',
                }}
                className="input-text mb-2"
                required
                autoComplete="name"
                fullWidth
                disableUnderline
                onChange={(e) => setName(e.target.value)}
                disabled={submitRequestSuccess && submitConfirmationSuccess}
              />
              <Input
                placeholder={lang === "de" ? "E-Mail" : "Email"}
                inputProps={{
                  'aria-label': 'email',
                }}
                className="input-text mb-2"
                type="email"
                required={!telCallback}
                autoComplete="email"
                fullWidth
                disableUnderline
                onChange={(e) => setEmail(e.target.value)}
                disabled={submitRequestSuccess && submitConfirmationSuccess}
              />
              <Input
                placeholder={lang === "de" ? "Telefon" : "Phone"}
                inputProps={{
                  'aria-label': 'phone',
                }}
                className="input-text mb-2"
                type="phone"
                required={telCallback}
                autoComplete="tel"
                fullWidth
                disableUnderline
                onChange={(e) => setTel(e.target.value)}
                disabled={submitRequestSuccess && submitConfirmationSuccess}
              />
              <Input
                placeholder={lang === "de" ? "Nachricht" : "Message"}
                inputProps={{
                  'aria-label': 'text',
                }}
                className="input-text mb-2"
                required
                multiline
                rows="3"
                type="text"
                fullWidth
                disableUnderline
                onChange={(e) => setMessage(e.target.value)}
                disabled={submitRequestSuccess && submitConfirmationSuccess}
              />
              <div className="d-flex mt-2">
                <div className="telcallback-wrapper d-flex mt-1">
                  <Checkbox
                    value="checkedC"
                    inputProps={{
                      'aria-label': 'callback',
                    }}
                    icon={<Icon iconType="checkboxBlank" />}
                    checkedIcon={<Icon iconType="checkboxChecked" />}
                    className="input-checkbox mb-auto"
                    disableRipple
                    color="default"
                    onChange={toggleTelCallback}
                    disabled={submitRequestSuccess && submitConfirmationSuccess}
                  />
                  <div className="telcallback" dangerouslySetInnerHTML={{ __html: lang === "de" ? "Bitte um Rückruf" : "Please call me" }} />
                </div>
              </div>
              <div className="privacy-wrapper d-flex mt-3 mt-md-1">
                <Checkbox
                  value="checkedC"
                  inputProps={{
                    'aria-label': 'privacy',
                  }}
                  icon={<Icon iconType="checkboxBlank" />}
                  checkedIcon={<Icon iconType="checkboxChecked" />}
                  className="input-checkbox mb-auto"
                  disableRipple
                  required
                  color="default"
                  disabled={submitRequestSuccess && submitConfirmationSuccess}
                />
                <div className="telcallback" dangerouslySetInnerHTML={{ __html: lang === "de" ? "Hiermit erklären Sie sich mit unserem <a href='/datenschutz' target='_blank' rel='noopener'>Datenschutz</a> einverstanden." : "I agree to the <a href='/en/privacy' target='_blank' rel='noopener'>data protection policy</a>" }} />
              </div>
              <div className="privacy-wrapper d-flex mt-3 mt-md-5">
                <Button
                  classes="btn-submit btn-border"
                  realButton
                  text={lang === "de" ? "Abschicken" : "Submit"}
                  disabled={submitRequestSuccess && submitConfirmationSuccess}
                />
              </div>
            </>
            )
          }
          {
            submitRequestSuccess && submitConfirmationSuccess && (
              <p>
                {
                  lang === "de" ? "Danke für Ihre Anfrage, wir melden uns in Kürze bei Ihnen." : "Thank you for your request, we will contact you soon."
                }
              </p>
            )
          }
        </div>
      </form>
    </div>
  )
}

export default InquiryForm
