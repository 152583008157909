import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from "gatsby-plugin-image";
import classnames from "classnames";

import './ImageContainer.scss';

const ImageContainer = props => {
  const { image, altText, previewSrc, cropImage, aspectRatios, classes, dataSwiperParallax, dataSwiperParallaxScale } = props;
  let { useStandardImage } = props
  let src = null;
  let aspectRatio = null;

  // Determine which image must be rendered, GatsbyImage always wins
  if (image && !image.src) {
    useStandardImage = false;
    aspectRatio = image.width / image.height;
  } else if (image && image.src && image.aspectRatio) {
    useStandardImage = true;
    src = image.src;
    aspectRatio = image.aspectRatio;
  } else if (previewSrc.src && previewSrc.aspectRatio) {
    useStandardImage = true;
    src = previewSrc.src;
    aspectRatio = previewSrc.aspectRatio;
  } else {
    return null;
  }

  const loadingBackgroundColor = "#ebe4e0";
  let croppingStylesWrapper = {};
  let croppingStylesImage = {}

  const renderCropping = () => {
    const possibleAspectRatios = aspectRatios || [0.666, 1.333];
    if(cropImage) {
      const aspectRatioConverted = aspectRatio < 1 ? aspectRatio + 1 : aspectRatio - 1;

      const findMatchingCropping = () => {
        let smallestDiff = Math.abs(aspectRatioConverted - possibleAspectRatios[0]);
        let closest = 0;

        for (let i = 1; i < possibleAspectRatios.length; i += 1) {
          const currentDiff = Math.abs(aspectRatioConverted - possibleAspectRatios[i]);
          if (currentDiff < smallestDiff) {
            smallestDiff = currentDiff;
            closest = i;
          }
        }
        return `${possibleAspectRatios[closest] * 100}%`
      }

      croppingStylesWrapper = {
        width: "100%",
        position: "relative",
        overflow: "hidden",
        height: 0,
        paddingTop: findMatchingCropping(),
      }

      croppingStylesImage = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        minWidth: "100%",
        height: "auto",
        minHeight: "100%",
        objectFit: "cover",
        objectPosition: "50% 50%"
      }
    }
  }

  renderCropping()

  const standardImage = (
    <div
      className="image-wrapper"
      style={{...croppingStylesWrapper}}
    >
      <img
        src={src}
        alt={altText}
        style={{...croppingStylesImage}}
        data-swiper-parallax={dataSwiperParallax}
        data-swiper-parallax-scale={dataSwiperParallaxScale}
      />
    </div>
  )

  let gatsbyImage = (<GatsbyImage
    image={image}
    alt={altText}
    backgroundColor={loadingBackgroundColor}
    style={{...croppingStylesWrapper}}
  />);

  if (dataSwiperParallax || dataSwiperParallaxScale) {
    gatsbyImage = (
      <div
        data-swiper-parallax={dataSwiperParallax}
        data-swiper-parallax-scale={dataSwiperParallaxScale}
      >
        {gatsbyImage}
      </div>
    )
  }

  return (
    <div
      className={classnames("image-container", classes)}
    >
      {useStandardImage && standardImage}

      {!useStandardImage && gatsbyImage}
    </div>
  );
};

ImageContainer.defaultProps = {
  cropImage: true,
  useStandardImage: false,
  previewSrc: null
};

ImageContainer.propTypes = {
  image: PropTypes.instanceOf(Object),
  altText: PropTypes.string,
  aspectRatios: PropTypes.instanceOf(Array),
  cropImage: PropTypes.bool,
  classes: PropTypes.string,
  useStandardImage: PropTypes.bool,
  previewSrc: PropTypes.instanceOf(Object)
};

export default ImageContainer;
