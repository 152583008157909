/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react'
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Swiper from "react-id-swiper"
import classNames from "classnames"
import SectionHeadline from "Accessories/SectionHeadline/SectionHeadline.jsx"
import ImageContainer from "Accessories/ImageContainer/ImageContainer.jsx"
import VideoPlayer from "Accessories/VideoPlayer/VideoPlayer.jsx"
import "./HeroSectionSlider.scss"
import {getImage, getSrc} from "gatsby-plugin-image";

const HeroSectionSlider = ({headline, slides}) => {

  if(!slides || !slides.length) return null

  const [swiper, updateSwiper] = useState(null)
  const mySwiperRef = useRef(null)

  const swiperParams = {
    slidesPerView: 'auto',
    grabCursor: false,
    speed: 3000,
    delay: 6000,
    autoplay: {
      disableOnInteraction: false,
    },
    loop: true,
    lazy: true,
    loopedSlides: slides.length * 2,
    parallax: true,
    activeSlideKey: 0,
  }

  const progressBarARef = useRef()
  const progressBarBRef = useRef()
  const updateProgressBar = () => {
    const {realIndex} = swiper

    if(realIndex % 2 === 0) {
      progressBarARef.current.classList.remove("animate")
      progressBarBRef.current.classList.add("animate")

    } else {
      progressBarARef.current.classList.add("animate")
      progressBarBRef.current.classList.remove("animate")
    }
  }

  const renderSlides = (node, index) => {
    const slideClasses = "w-46"

    return (
      <div key={getSrc(node?.image?.localFile) || index} className="slide-container">
        {
          node.selectContentType === "image" ? (
            <ImageContainer
              altText={node?.image?.altText || node?.image?.title}
              image={getImage(node?.image?.localFile)}
              dataSwiperParallax="10%"
              dataSwiperParallaxScale="1.2"
              previewSrc={{src: node?.image?.sourceUrl || typeof node.image === "string" && node.image || null}}
              aspectRatios={[0.5625]}
              useStandardImage
              classes={slideClasses}
            />
          ) : (
            <VideoPlayer
              video={node?.video?.sourceUrl}
              poster={getSrc(node?.videoPosterImage?.localFile)}
              hasControls={node?.hasControls}
              dataSwiperParallax="10%"
              dataSwiperParallaxScale="1.2"
              classes={slideClasses}
            />
          )
        }
      </div>
    );
  }

  useEffect(() => {
    let timeout

    if (swiper !== null && slides.length > 0) {
      timeout = setTimeout(() => {
          mySwiperRef.current.classList.add("show-swiper")

          swiper.on('transitionStart', updateProgressBar)
          swiper.on('init', updateProgressBar)
          swiper.update()
      }, 1000)
    }
    return () => {
      clearTimeout(timeout)
    }
  },[swiper])

  useEffect(() => {
    let timeout;

    // This is a hacky workaround as the legacy version of Swiper used in this project does not work properly
    // with React
    if (swiper && swiper.params.loopedSlides) {
      timeout = setTimeout(() => {
        swiper.loopDestroy();
        swiper.loopCreate();
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [swiper]);

  return (
    <div ref={mySwiperRef} className="section hero-section-slider">
      <div className="container">
        {
          headline && (
            <SectionHeadline
              headline={headline}
              isH1
            />
          )
        }
        <div className={classNames(slides.length > 1 && "swiper-element w-46 ml-auto", "row")}>
          <Swiper {...swiperParams} getSwiper={updateSwiper}>
            {
              slides && slides.map((slide, index) => (renderSlides(slide, index)))
            }
          </Swiper>
          <div className="progress-bar-wrapper ml-auto w-6 mr-2 mr-md-1 pt-2">
            <div className="progress-bar-background">
              <div ref={progressBarARef} className="progress-bar" />
              <div ref={progressBarBRef} className="progress-bar" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


HeroSectionSlider.propTypes = {
  headline: PropTypes.string,
  slides: PropTypes.instanceOf(Array),
}

export const query = graphql`fragment PageHeroSectionSliderFragment on WpPage_Flex_Content_HeroSectionSlider {
  headline
  slides {
    selectContentType
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
    video {
      sourceUrl
      mediaDetails {
        height
        width
      }
    }
    videoPosterImage {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    hasControls
  }
}

fragment PostHeroSectionSliderFragment on WpPost_Flex_Content_HeroSectionSlider {
  headline
  slides {
    selectContentType
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
    video {
      sourceUrl
      mediaDetails {
        height
        width
      }
    }
    videoPosterImage {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    hasControls
  }
}

fragment PressFeatureHeroSectionSliderFragment on WpPressFeature_Flex_Content_HeroSectionSlider {
  headline
  slides {
    selectContentType
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
    video {
      sourceUrl
      mediaDetails {
        height
        width
      }
    }
    videoPosterImage {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    hasControls
  }
}

fragment ProjectHeroSectionSliderFragment on WpProject_Flex_Content_HeroSectionSlider {
  headline
  slides {
    selectContentType
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
    video {
      sourceUrl
      mediaDetails {
        height
        width
      }
    }
    videoPosterImage {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    hasControls
  }
}

fragment CompetenceHeroSectionSliderFragment on WpCompetence_Flex_Content_HeroSectionSlider {
  headline
  slides {
    selectContentType
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
    video {
      sourceUrl
      mediaDetails {
        height
        width
      }
    }
    videoPosterImage {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    hasControls
  }
}

fragment JobHeroSectionSliderFragment on WpJob_Flex_Content_HeroSectionSlider {
  headline
  slides {
    selectContentType
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
    video {
      sourceUrl
      mediaDetails {
        height
        width
      }
    }
    videoPosterImage {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    hasControls
  }
}
`

export default HeroSectionSlider
