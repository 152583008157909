import React, {useEffect, useLayoutEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RemoveClassInview from "Accessories/RemoveClassInview/RemoveClassInview.jsx";
import "./SectionHeadline.scss"

const SectionHeadline = props => {
  const { isH1, headline, copy } = props;

  useLayoutEffect(() => {
    const allSectionHeadlines = document.getElementsByClassName('section-headline')
    if(allSectionHeadlines.length > 0){
      allSectionHeadlines[0].classList.add('first-section-headline')
    }
  })

  return (
    <RemoveClassInview>
      <div className={classNames("section-headline mt-10 mb-20 text-align-center")}>
        <div className="row">
          <div className="w-37 w-sm-18 w-md-14 m-auto">
            {
              isH1 ? (
                <h1>{headline}</h1>
              ) : (
                <h2>{headline}</h2>
              )
            }
            {
              copy &&
              <p className="mt-2">{copy}</p>
            }
          </div>
        </div>
      </div>
    </RemoveClassInview>
  );
};

SectionHeadline.defaultProps = {
  isH1: false,
  headline: null,
  copy: null,
};

SectionHeadline.propTypes = {
  isH1: PropTypes.bool,
  headline: PropTypes.string,
  copy: PropTypes.string,
};

export default SectionHeadline;
