import React from "react"
import { useGlobal } from "reactn"
import classNames from "classnames"
import PageTransitionLink from "Layout/PageTransition/PageTransitionLink.jsx"
import { ReactComponent as MenuCloseIconSvg } from "static/assets/svg/icons/x.svg"
import "./NavigationOverlay.scss"

const NavigationOverlay = ({ menus, menuOpen, closeHandler }) => {
  const [lang] = useGlobal("lang")
  const isLangDE = lang === "de"
  const mainMenu = isLangDE ? menus.edges.filter(item => item.node.name === "Main Menu - DE") : menus.edges.filter(item => item.node.name === "Main Menu - EN")

  return (
    <div id="menu-overlay" className={classNames(menuOpen && "is-open", "menu-overlay pt-3 pr-1 pl-1")}>
      <div id="menu-column-wrapper" className="menu-column-wrapper column-wrapper d-flex flex-column justify-content-start">
        <div className="overlay-header d-flex">
          <MenuCloseIconSvg
            className="d-block ml-auto d-lg-none close-icon"
            onClick={closeHandler}
            fill="#342616"
          />
        </div>
        <div className="navigation pt-5 pb-15">
          <nav className="ml-auto">
            <ul className="d-flex primary-menu flex-wrap justify-content-between">
              {
                mainMenu[0].node.menuItems.nodes.map(menuItem => {
                  if(menuItem.parentId !== null) return null
                  return (
                    <li key={menuItem.url} className="menu-item-level-1 w-20 mb-5">
                      <PageTransitionLink
                        internalLink={menuItem.url}
                        activeClassName="is-active"
                        className="mb-2 d-block"
                        onClick={closeHandler}
                      >
                        {menuItem.label}
                      </PageTransitionLink>
                      {
                        menuItem.childItems && menuItem.childItems.nodes && (
                          <ul className="d-flex flex-column sub-menu">
                            {
                              menuItem.childItems.nodes.map(item => (
                                <li key={item.objectSlug || item.url} className="menu-item-level-2">
                                  <PageTransitionLink
                                    internalLink={item.url}
                                    activeClassName="is-active"
                                    className="mb-2 d-block"
                                    onClick={closeHandler}
                                  >
                                    {item.label}
                                  </PageTransitionLink>
                                </li>
                              ))
                            }
                          </ul>
                        )
                      }
                    </li>
                  )
                })
              }
            </ul>
          </nav>
          <div className="language-switcher">
            <PageTransitionLink
              internalLink="/"
              lang="de"
              activeClassName="is-active"
            >
              DE
            </PageTransitionLink>
            <span> &#9642; </span>
            <PageTransitionLink
              internalLink="/"
              lang="en"
              activeClassName="is-active"
            >
              EN
            </PageTransitionLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavigationOverlay
