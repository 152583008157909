/* eslint-disable no-unused-vars */
import React from 'react'
import { navigate } from "gatsby"
import { useWindowWidth } from '@react-hook/window-size'
import ImageContainer from "Accessories/ImageContainer/ImageContainer.jsx"
import VideoPlayer from "Accessories/VideoPlayer/VideoPlayer.jsx"
import './SurveyStep.scss'
import {getImage, getSrc} from "gatsby-plugin-image";

const IntroOutroStep = ({step, onComplete = () => {}, orientation}) => {
  const windowWidth = useWindowWidth()
  const handleButton = (link) => () => {
    if (link?.uri) {
      navigate(link.uri)
    } else {
      onComplete()
    }
  }

  return (
    <div className="survey-step-content-aspect-ratio">
      {step.background.type === "video" ? (
        <VideoPlayer
          video={step.background.video[orientation].mediaItemUrl}
          poster={getSrc(step?.background?.image[orientation]?.localFile)}
          hasControls={false}
          classes="survey-step-content-aspect-ratio"
        />
      ) : (
        <ImageContainer
          altText={step.background.image[orientation].altText || step.background.image[orientation].title}
          image={getImage(step?.background?.image[orientation]?.localFile)}
          previewSrc={{src: step.background.image[orientation].sourceUrl}}
          aspectRatios={orientation === 'portrait' ? [2] : [590 / 1337]}
        />
      )}
      <div className={`survey-step-content-container survey-step-content-container--${step.theme}`}>
        <div className="survey-step-content-heading">{step.title}</div>
        <div className="survey-step-content-paragraph" dangerouslySetInnerHTML={{__html: step.paragraph}} />
        <div className="survey-step-content-buttons" data-num-buttons={step.buttons.length}>
          {step.buttons.map(({label, link}) => <button key={label} type="button" onClick={handleButton(link)} className="survey-step-content-buttons-rating">{label}</button>)}
        </div>
      </div>
    </div>
  );
}

export default IntroOutroStep
