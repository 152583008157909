/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import ImageContainer from "Accessories/ImageContainer/ImageContainer.jsx"
import VideoPlayer from "Accessories/VideoPlayer/VideoPlayer.jsx"
import './SurveyStep.scss'
import {getImage, getSrc} from "gatsby-plugin-image";

const QuestionnaireStep = ({step, onComplete, currentValue, orientation}) => {
  const [questionValue, setQuestionValue] = useState("");
  const windowWidth = useWindowWidth()

  const handleClick = (value) => () => {
    onComplete(value)
  }

  const handleQuestionChange = (e) => setQuestionValue(e.target.value)
  const answerButtons = step.buttons.filter(button => !button.skip)
  const skipButtons = step.buttons.filter(button => button.skip)

  return (
    <div className="survey-step-content-aspect-ratio">
      {step.background.type === "video" ? (
        <VideoPlayer
          video={step.background.video[orientation].mediaItemUrl}
          poster={getSrc(step?.background?.image[orientation]?.localFile)}
          hasControls={false}
          classes="survey-step-content-aspect-ratio"
        />
      ) : (
        <ImageContainer
          altText={step.background.image[orientation].altText || step.background.image[orientation].title}
          image={getImage(step?.background?.image[orientation]?.localFile)}
          previewSrc={{src: step.background.image[orientation].sourceUrl}}
          aspectRatios={orientation === 'portrait' ? [2] : [590 / 1337]}
          dataSwiperParallax="10%"
          dataSwiperParallaxScale="1.2"
          useStandardImage
        />
      )}
      <div className={`survey-step-content-container survey-step-content-container--${step.theme}`}>
        <div className="survey-step-content-heading">
          <img className="survey-step-content-icon" src={step.icon.sourceUrl} alt={step.icon.alt || step.icon.title} />
          {step.title}
        </div>
        <div className="survey-step-content-heading" dangerouslySetInnerHTML={{__html: step.paragraph}} />
        {step.type === "isQuestion" && <textarea className="survey-step-content-question" onChange={handleQuestionChange} placeholder={step.question} rows={4} value={questionValue} />}
        <div className="survey-step-content-buttons" data-num-buttons={step.buttons.length}>
          {answerButtons.map(({label, value}) => (
            <button key={label} type="button" onClick={handleClick(step.type === "isQuestion" ? questionValue : value)} className={`survey-step-content-buttons-rating ${currentValue === value ? 'survey-step-content-buttons-rating--active' : ''}`}>
              {label}
            </button>
          ))}
        </div>
        {
          skipButtons.length > 0 && (
            <div className="survey-step-content-skip-buttons">
              {skipButtons.map(({label, value}) => (
                <button type="button" key={label} onClick={handleClick(value || 0)} className="survey-step-content-skip-button">{label}</button>
              ))}
            </div>
          )
        }
      </div>
    </div>
  );
}

export default QuestionnaireStep
