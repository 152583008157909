const shuffle = require(`shuffle-array`);

const dynamicContentShuffle = (relatedProjects, relatedStories) => {
  const shuffledRelatedProjects = shuffle(
    relatedProjects.edges
      .sort((a, b) => (a.node.date > b.node.date ? -1 : 1))
      .slice(0, Math.ceil(relatedProjects.edges.length / 5))
  ).slice(0, 2);
  const shuffledRelatedStories = shuffle(
    relatedStories.nodes
      .sort((a, b) => (a.date > b.date ? -1 : 1))
      .slice(0, Math.ceil(relatedStories.nodes.length / 5))
  ).slice(0, 2);

  const dynamicContent = [
    shuffledRelatedStories[0],
    shuffledRelatedProjects[0],
    shuffledRelatedProjects[1],
    shuffledRelatedStories[1],
  ];

  return dynamicContent;
};

module.exports = { dynamicContentShuffle };
