import React  from "react"
import { useGlobal } from "reactn"
import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"
import config from "data/SiteConfig"

import appleTouchIcon from "../../../../static/assets/images/apple-touch-icon.png"
import favicon32 from "../../../../static/assets/images/favicon-32x32.png"
import favicon16 from "../../../../static/assets/images/favicon-16x16.png"
import safariPinnedTab from "../../../../static/assets/images/safari-pinned-tab.svg"

const SEO = (props) => {
  const { metaData } = props
  const [lang] = useGlobal("lang")
  const isLangDE = lang === "de"

  const data = useStaticQuery(graphql`
    query MetaDataQuery {
      options: wp {
        globalData {
          globalMetaInfo {
            globalMetaInfo {
              german {
                metaInfoDescription
                metaInfoKeywords
                metaInfoTitle
                metaInfoShareImage {
                  sourceUrl
                  mediaDetails {
                    height
                    width
                  }
                }
              }
              english {
                metaInfoDescription
                metaInfoKeywords
                metaInfoTitle
                metaInfoShareImage {
                  sourceUrl
                  mediaDetails {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const globalMetaData = isLangDE ? data.options.globalData.globalMetaInfo.globalMetaInfo.german : data.options.globalData.globalMetaInfo.globalMetaInfo.english
  const pageTitle = metaData && metaData.metaInfoTitle || globalMetaData.metaInfoTitle
  const titleWithSuffix = (pageTitle.indexOf(config.siteTitle) > 0 || pageTitle.indexOf(config.siteTitleAlt) > 0) && pageTitle || `${pageTitle} | ${config.siteTitle}`

  const metaDataUpdated = {
    metaInfoDescription: metaData && metaData.metaInfoDescription || globalMetaData.metaInfoDescription,
    metaInfoKeywords: metaData && metaData.metaInfoKeywords || globalMetaData.metaInfoKeywords,
    metaInfoTitle: titleWithSuffix,
    metaInfoShareImage: metaData && metaData.metaInfoShareImage && metaData.metaInfoShareImage.sourceUrl || globalMetaData.metaInfoShareImage && globalMetaData.metaInfoShareImage.sourceUrl,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: `description`,
          content: metaDataUpdated.metaInfoDescription,
        },
        {
          name: `keywords`,
          content: metaDataUpdated.metaInfoKeywords
        },
        {
          name: `image`,
          content: metaDataUpdated.metaInfoShareImage,
        },
        {
          property: `og:title`,
          content: metaDataUpdated.metaInfoTitle,
        },
        {
          property: `og:description`,
          content: metaDataUpdated.metaInfoDescription,
        },
        {
          property: `og:image`,
          content: metaDataUpdated.metaInfoShareImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: config.siteBrand,
        },
        {
          name: `twitter:title`,
          content: metaDataUpdated.metaInfoTitle,
        },
        {
          name: `twitter:description`,
          content: metaDataUpdated.metaInfoDescription,
        },
        {
          name: `application-name`,
          content: config.siteTitle,
        },
        {
          name: `apple-mobile-web-app-status-bar-style`,
          content: `black-translucent`,
        },
        {
          name: `apple-mobile-web-app-title`,
          content: config.siteTitle,
        },
        {
          name: `msapplication-TileColor`,
          content: config.sitePrimaryColor,
        },
        {
          name: `theme-color`,
          content: config.sitePrimaryColor,
        },
      ]}
    >
      <title>{metaDataUpdated.metaInfoTitle}</title>
      <link rel="apple-touch-icon" sizes="128x128" href={appleTouchIcon} />
      <link rel="icon" type="image/png" href={favicon32} sizes="32x32" />
      <link rel="icon" type="image/png" href={favicon16} sizes="16x16" />
      <link rel="mask-icon" href={safariPinnedTab} color={config.sitePrimaryColor} />
    </Helmet>
  )
}

export default SEO
