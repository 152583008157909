import React from "react"
import {graphql} from "gatsby"
import PropTypes from "prop-types"
import PostOverview from "Layout/PostOverview/PostOverview.jsx"
import SectionHeadline from "Accessories/SectionHeadline/SectionHeadline.jsx"

const DynamicContentSection = ({sectionHeadline, dynamicContent}) => {
  return (
    <div className="section related-stories-section">
      {sectionHeadline && (
        <SectionHeadline headline={sectionHeadline} />
      )}
      <PostOverview items={dynamicContent} postType="dynamic" />
    </div>
  )
}

DynamicContentSection.propTypes = {
  sectionHeadline: PropTypes.string,
  dynamicContent: PropTypes.instanceOf(Object)
}

export const query = graphql`
  fragment PageDynamicContentSectionFragment on WpPage_Flex_Content_DynamicContentSection {
    sectionHeadline
  }
  fragment PostDynamicContentSectionFragment on WpPost_Flex_Content_DynamicContentSection {
    sectionHeadline
  }
  fragment PressFeatureDynamicContentSectionFragment on WpPressFeature_Flex_Content_DynamicContentSection {
    sectionHeadline
  }
  fragment ProjectDynamicContentSectionFragment on WpProject_Flex_Content_DynamicContentSection {
    sectionHeadline
  }
  fragment CompetenceDynamicContentSectionFragment on WpCompetence_Flex_Content_DynamicContentSection {
    sectionHeadline
  }
  fragment JobDynamicContentSectionFragment on WpJob_Flex_Content_DynamicContentSection {
    sectionHeadline
  }
`

export default DynamicContentSection
