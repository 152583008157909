const reporter = require(`./reporter`);

const convertPath = (path) => {
  let lowestDir = [];
  let slug = "";

  if (typeof path !== "string") {
    reporter.log(
      "warning",
      `Error in convertPath: No propper path as sting provided ${path}`
    );

    return null;
  }

  if (path === "/") {
    slug = "";
  }

  if (path.length > 2) {
    const indexLastFullStop = path.lastIndexOf(".");
    let pathTemp = path.substr(indexLastFullStop + 1, path.length - 1);
    const firstFwdSlash = pathTemp.indexOf("/");
    pathTemp = pathTemp.substr(firstFwdSlash + 1, path.length - 1);

    pathTemp = pathTemp.split("/");
    if (pathTemp[pathTemp.length - 1] === "") pathTemp.pop();
    if (pathTemp[0] === "") pathTemp.shift();
    if (pathTemp[0] === "en" || pathTemp[0] === "de") pathTemp.shift();

    const hasDir = pathTemp.length > 1;
    slug = hasDir
      ? pathTemp[pathTemp.length - 1]?.toString()
      : pathTemp[0]?.toString();
    lowestDir = hasDir ? [pathTemp[pathTemp.length - 2]?.toString()] : [];
  }

  if (slug === "home") {
    lowestDir = [];
    slug = "/";
  }

  return { lowestDir, slug };
};

module.exports = { convertPath };
