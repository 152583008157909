import React from 'react'
import {useGlobal} from 'reactn'
import ButtonGroup from 'Accessories/Input/ButtonGroup/ButtonGroup.jsx'

const ProjectFilter = ({
  livingAreaOptions,
  changeHandlerLivingArea
}) => {

  const [lang] = useGlobal("lang");
  const isLangDE = lang === "de"

  const capFirstChar = string => (string[0].toUpperCase() + string.slice(1))

  return (
    <div className="filter-wrapper d-flex flex-column flex-sm-row mb-4">
      <ButtonGroup
        label={isLangDE ? "Wohnbereiche" : "Living Area"}
        unselect={isLangDE ? "Alle" : "All"}
        options={livingAreaOptions.map(o => ({label: capFirstChar(o), value: o}))}
        onChange={changeHandlerLivingArea}
        className="eham-select"
      />
    </div>
  )
}

export default ProjectFilter
