const metaData = (page) => {
  const metaDataUpdated = {
    metaInfoDescription:
      page.specialMetaInfos && page.specialMetaInfos.metaInfoDescription,
    metaInfoKeywords:
      page.specialMetaInfos && page.specialMetaInfos.metaInfoKeywords,
    metaInfoTitle:
      (page.specialMetaInfos && page.specialMetaInfos.metaInfoTitle) ||
      page.title,
    metaInfoShareImage:
      page.specialMetaInfos && page.specialMetaInfos.metaInfoShareImage,
  };

  return metaDataUpdated;
};

module.exports = { metaData };
