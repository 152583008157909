import React from "react"
import { useGlobal } from "reactn"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import SectionHeadline from "Accessories/SectionHeadline/SectionHeadline.jsx"
import Teaser from "Layout/Teaser/Teaser.jsx"
import "./NewsOverviewSection.scss"
import {getImage} from "gatsby-plugin-image";

const NewsOverviewSection = props => {
  const data = useStaticQuery(graphql`query NewsOverviewSectionFilterQuery {
  news: allWpPost(filter: {title: {ne: "DO NOT DELETE"}}, sort: {order: DESC, fields: date}) {
    edges {
      node {
        internal {
          type
        }
        language {
          slug
        }
        title
        uri
        databaseId
        categories {
          nodes {
            databaseId
            name
          }
        }
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 680, quality: 80, placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
}
`)

  const { headline } = props
  const [lang] = useGlobal("lang")
  const isLangDE = lang === "de"
  const itemsInital = data.news.edges.filter(item => item.node.language.slug === lang)

  const itemsFiltered = {
    hasResult: itemsInital.length > 0,
    items: itemsInital
  }

  //  Get all categories options
  let categoriesOptions = []
  itemsInital.map(item => (
    item.node.categories.nodes.forEach(category => {
      if(!categoriesOptions.some(i => i.databaseId === category.databaseId)){
        categoriesOptions.push(category)
      }
    })
  ))

  // Sort categories
  categoriesOptions = categoriesOptions.sort((a, b) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  return (
    <div className="section news-overview">
      <div className="container">
        {headline && <SectionHeadline headline={headline} />}
        {
          !itemsFiltered.hasResult && (
            <div className="no-items-notice w-47 d-flex mx-1 mb-5 mb-lg-0 my-lg-5">
              {isLangDE
                ? <span>Ihr Suche hat leider keine Treffer ergeben. Hier eine Auswahl unserer anderen Beiträge.</span>
                : <span>Your search did not return any results. Find a selection of some of our other posts below.</span>
              }
            </div>
          )
        }
        <div className="grid-md-3 grid-2 grid-rg-15">
          {
            itemsFiltered?.items.map(newsItem => {
              const image = getImage(newsItem?.node?.featuredImage?.node?.localFile)

              if (!image) {
                return null;
              }

              return (
                <Teaser
                  key={newsItem.node.databaseId}
                  alignCenter
                  altText={newsItem?.node?.featuredImage?.node?.altText || newsItem?.node?.featuredImage?.node?.title}
                  image={image}
                  previewSrc={{src: newsItem?.node?.featuredImage?.node?.sourceUrl || newsItem?.node && `${newsItem?.node?.featuredImage?.node}` || null}}
                  text={newsItem?.node?.title}
                  linkText={isLangDE ? "Entdecken" : "Explore"}
                  internalLink={newsItem?.node?.uri}
                />
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

NewsOverviewSection.propTypes = {
  headline: PropTypes.string
}

export const query = graphql`
  fragment PageNewsOverviewSectionFragment on WpPage_Flex_Content_NewsOverviewSection {
    headline
  }
  fragment PostNewsOverviewSectionFragment on WpPost_Flex_Content_NewsOverviewSection {
    headline
  }
  fragment PressFeatureNewsOverviewSectionFragment on WpPressFeature_Flex_Content_NewsOverviewSection {
    headline
  }
  fragment ProjectNewsOverviewSectionFragment on WpProject_Flex_Content_NewsOverviewSection {
    headline
  }
  fragment CompetenceNewsOverviewSectionFragment on WpCompetence_Flex_Content_NewsOverviewSection {
    headline
  }
  fragment JobNewsOverviewSectionFragment on WpJob_Flex_Content_NewsOverviewSection {
    headline
  }
`

export default NewsOverviewSection
