import React, {useEffect} from "react"
import SEO from "Accessories/SEO/SEO.jsx"
import MainNavigation from "Layout/Navigation/MainNavigation/MainNavigation.jsx"
import CookieNotice from "Accessories/CookieNotice/CookieNotice.jsx"
import "./_Scss/style.scss"

const MainLayout = ({
  children,
  location,
  translations,
  metaData,
}) => {

  const handleTouch = () => {
    document.body.classList.add("is-touch-device")
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("touchstart", handleTouch)
    }

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (typeof window !== "undefined") {
        window.removeEventListener("touchstart", handleTouch)
      }
    }
  }, [])

  return (
    <>
      <SEO metaData={metaData} location={location} />
      <MainNavigation location={location} translations={translations} />
      {children}
      <CookieNotice />
    </>
  )
}

export default MainLayout
