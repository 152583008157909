/* eslint-disable no-nested-ternary */
import React from "react"
import {useGlobal} from "reactn"
import Teaser from "Layout/Teaser/Teaser.jsx"
import TeaserDescription from "Layout/Teaser/TeaserDescription/TeaserDescription.jsx"
import {isEvenNumber} from "Accessories/Helper"
import classNames from "classnames"
import {PropTypes} from "prop-types"
import "./PostOverview.scss"
import {getImage, getSrc} from "gatsby-plugin-image";

const PostOverview = ({
  items,
  initialItems,
  postType,
  text,
  internalLink,
  link,
  linkText
}) => {
  const [lang] = useGlobal("lang")
  const isLangDE = lang === "de"

  const getIsLandscape = index => {
    const n = text ? index + 1 : index
    const evenRow = n % 4 > 1
    const evenNum = n % 2 === 0

    if(text) {
      if( evenRow ) {
        if(evenNum) return true
        return false
      } if(!evenNum) return true
      return false
    }
    if( evenRow ) {
      if(evenNum) return false
      return true
    } if(!evenNum) return false
    return true
  }

  const normalizeNode = node => {
    if(postType === "competence" || postType === "project") {
      return {
        imgLandscape: getImage(node?.node?.postImages?.postImages?.landscapeImage?.localFile),
        imgPortrait: getImage(node?.node?.postImages?.postImages?.portaitImage?.localFile),
        altText: node.node.postImages.postImages && node.node.postImages.postImages.portaitImage && node.node.postImages.postImages.portaitImage.altText || node.node.postImages.postImages && node.node.postImages.postImages.portaitImage && node.node.postImages.postImages.portaitImage.title,
        imgLandscapePrev: node.node.postImages.postImages && node.node.postImages.postImages.landscapeImage && node.node.postImages.postImages.landscapeImage.sourceUrl,
        imgPortraitPrev: node.node.postImages.postImages && node.node.postImages.postImages.portaitImage && node.node.postImages.postImages.portaitImage.sourceUrl,
        teaserText: node.node.title,
        subText: postType === "project" ? node.node.projectTeaser.listTeaser : null,
        linkLabel: isLangDE ? "Entdecken" : "Explore",
        href: node.node.uri
      };
    }
    if(postType === "featured") {
      return {
        imgLandscape: getImage(node?.image?.localFile),
        altText: node.image && node.image.altText || node.image && node.image.title,
        imgPortrait: getImage(node?.image?.localFile),
        imgLandscapePrev: node.image && node.image.url,
        imgPortraitPrev: node.image && node.image.url,
        teaserText: node.text,
        subText: null,
        linkLabel: node.linkText,
        href: node.internallLink && node.internallLink.uri
      };
    }
    if(postType === "dynamic") {
      return {
        imgLandscape: getImage(node?.node?.postImages?.postImages?.portaitImage?.localFile) || getImage(node?.featuredImage?.node?.localFile),
        imgPortrait: getImage(node?.node?.postImages?.postImages?.portaitImage?.localFile) || getImage(node?.featuredImage?.node?.localFile),
        imgLandscapePrev: node.node?.postImages?.postImages?.landscapeImage?.sourceUrl || node?.featuredImage?.node?.sourceUrl,
        imgPortraitPrev: node.node?.postImages?.postImages?.portaitImage?.sourceUrl || node?.featuredImage?.node?.sourceUrl,
        altText: node.node?.postImages?.postImages?.portaitImage?.altText || node?.featuredImage?.node?.altText,
        teaserText: node.node?.title || node?.title,
        subText: null,
        linkLabel: isLangDE ? "Entdecken" : "Explore",
        href: node.node?.uri || node?.uri
      };
    }
    return {
      imgLandscape: null,
      imgPortrait: null,
      altText: null,
      imgLandscapePrev: null,
      imgPortraitPrev: null,
      teaserText: null,
      subText: null,
      linkLabel: null,
      href: null,
    }
  }

  const renderTeaser = (item, index) => {
    const {teaserText, linkLabel, href, imgLandscape, imgPortrait, altText, imgLandscapePrev, imgPortraitPrev, subText} = normalizeNode(item)
    const isLandscape = getIsLandscape(index)

    return(
      <Teaser
        classes={
          classNames(
            "mb-10",
            items.length > 2 ? "mb-md-15" : "mb-md-0",
            isLandscape
            ? "w-35 w-md-23 is-landscape"
            : "w-24 w-md-15 is-portrait",
            !text && !isEvenNumber(index) && "ml-auto",
            text && isEvenNumber(index) && "ml-auto",
          )
        }
        altText={altText}
        image={isLandscape ? imgLandscape : imgPortrait}
        previewSrc={{
          src: isLandscape && `${imgLandscapePrev}` || `${imgPortraitPrev}`
        }}
        alignCenter
        subText={subText ?? ""}
        text={teaserText}
        linkText={linkLabel}
        internalLink={href}
        key={getSrc(imgLandscape) || getSrc(imgPortrait) || index}
      />
    )
  }

  return (
    <div className="post-overview-wrapper">
      <div className="row">
        {
          text && (
            <div className="text-side w-md-20 w-lg-12 w-40 d-flex mb-5 mb-md-0">
              <div className="ml-1 mt-md-0 mt-2 d-flex align-self-center">
                <TeaserDescription
                  text={text}
                  isHtml
                  linkText={linkText}
                  externalLink={link}
                  internalLink={internalLink}
                  classes="ml-1 mr-auto main-description"
                />
              </div>
            </div>
          )
        }
        {
          items && items.map((item, index) => renderTeaser(item, index))
        }
        {
          items.length === 0 && initialItems && (
            <div className="no-items-notice w-47 d-flex mx-1 mb-5 mb-lg-0 my-lg-5">
              {
                isLangDE
                ? <span>Ihr Suche hat leider keine Treffer ergeben. Hier eine Auswahl unserer anderen Beiträge.</span>
                : <span>Your search did not return any results. Find a selection of some of our other posts below.</span>
              }
            </div>
          )
        }
        {
          items.length === 0 && initialItems && initialItems.map((item, index) => renderTeaser(item, index))
        }
      </div>
    </div>
  )
}

PostOverview.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  internalLink: PropTypes.string,
  linkText: PropTypes.string,
  items: PropTypes.instanceOf(Array),
}

export default PostOverview
