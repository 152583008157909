import React from 'react'
import { useGlobal } from 'reactn'
import { graphql } from "gatsby"
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SectionHeadline from 'Accessories/SectionHeadline/SectionHeadline.jsx'
import Teaser from 'Layout/Teaser/Teaser.jsx'
import './RelatedProjectsSection.scss'
import {getImage} from "gatsby-plugin-image";

const RelatedProjectsSection = (props) => {
  const [lang] = useGlobal("lang");
  const isLangDE = lang === "de"

  const {headline, location, relatedProjects} = props
  const currentLocation = location ? location.pathname.substr(10) : "";

  const gridStyles = classNames(
    relatedProjects.length > 3 && "grid-md-4 grid-2",
    relatedProjects.length === 3 && "grid-md-3 grid-2",
    relatedProjects.length === 2 && "grid-md-2 grid-2",
    relatedProjects.length === 1 && "grid-md-3 grid-1",
    "grid-rg-5 grid-xs-1 px-xs-2 px-md-0"
  )

  const gridItemStyles = classNames(
    relatedProjects.length === 1 && "grid-item-md-2 grid-item-1",
  )

  return (
    <div className="section related-projects-section">
      {
        headline && (
          <SectionHeadline
            headline={headline}
          />
        )
      }
      <div className={gridStyles}>
        {
          relatedProjects.length > 0 && relatedProjects.map((item, index) => {
            if(item.node.postImages.postImages.landscapeImage &&
              item.node.postImages.postImages.landscapeImage.localFile &&
              item.node.postImages.postImages.portaitImage &&
              item.node.postImages.postImages.portaitImage.localFile &&
              item.node.slug !== currentLocation &&
              index <= 3) {
                return (
                  <Teaser
                    key={item.node.databaseId}
                    altText={index % 2 === 1 ? item.node.postImages.postImages.landscapeImage.altText || item.node.postImages.postImages.landscapeImage.title : item.node.postImages.postImages.portaitImage.altText || item.node.postImages.postImages.portaitImage.title}
                    image={index % 2 === 1 ? getImage(item?.node?.postImages?.postImages?.landscapeImage?.localFile) : getImage(item?.node?.postImages?.postImages?.portaitImage?.localFile)}
                    previewSrc={{
                      src: index % 2 === 1 ? item.node.postImages.postImages.landscapeImage.sourceUrl || `${item.node.postImages.postImages.landscapeImage}` : item.node.postImages.postImages.portaitImage.sourceUrl || `${item.node.postImages.postImages.portaitImage}`
                    }}
                    text={item.node.title}
                    linkText={isLangDE ? "Entdecken" : "Explore"}
                    internalLink={item.node.uri}
                    alignCenter
                    aspectRatios={relatedProjects.length === 2 ? [0.666] : null}
                    classes={gridItemStyles}
                  />
                );
              }
              return null
            }
          )
        }
      </div>
    </div>
  );
};

RelatedProjectsSection.propTypes = {
  headline: PropTypes.string,
  relatedProjects: PropTypes.instanceOf(Object),
};

export const query = graphql`
  fragment PageRelatedProjectsSectionFragment on WpPage_Flex_Content_RelatedProjectsSection {
    headline
  }
  fragment PostRelatedProjectsSectionFragment on WpPost_Flex_Content_RelatedProjectsSection {
    headline
  }
  fragment PressFeatureRelatedProjectsSectionFragment on WpPressFeature_Flex_Content_RelatedProjectsSection {
    headline
  }
  fragment ProjectRelatedProjectsSectionFragment on WpProject_Flex_Content_RelatedProjectsSection {
    headline
  }
  fragment CompetenceRelatedProjectsSectionFragment on WpCompetence_Flex_Content_RelatedProjectsSection {
    headline
  }
  fragment JobRelatedProjectsSectionFragment on WpJob_Flex_Content_RelatedProjectsSection {
    headline
  }
`

export default RelatedProjectsSection;
