/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { graphql } from "gatsby";
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import classNames from 'classnames';
import Button from 'Accessories/Button/Button.jsx'
import ImageContainer from 'Accessories/ImageContainer/ImageContainer.jsx'
import TeaserDescription from 'Layout/Teaser/TeaserDescription/TeaserDescription.jsx';
import SectionHeadline from 'Accessories/SectionHeadline/SectionHeadline.jsx';
import RemoveClassInview from "Accessories/RemoveClassInview/RemoveClassInview.jsx";

import './ServicesTeaserSection.scss';
import {getImage, getSrc} from "gatsby-plugin-image";

const ServicesTeaserSection = props => {
  const {
    headline,
    teaserHeadline,
    images,
    fade,
    teaserText,
    teaserHtml,
    teaserLinkText,
    teaserLinkTarget,
    teaserInternalLink,
    showTeaserAsButton,
  } = props

  if(!images || !images.length) return null

  const [swiper, updateSwiper] = useState(null)
  const mySwiperRef = useRef(null)

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  };
  const setPagination = () => {
    const swiperPagination = mySwiperRef.current.querySelector(".swiper-pagination");
    const imageContainer = mySwiperRef.current.querySelector(".image-container");
    swiperPagination.style.top = `${imageContainer.clientHeight}px`;
  }

  const swiperParams = {
    slidesPerView: 'auto',
    grabCursor: false,
    loop: true,
    loopedSlides: images.length,
    lazy: true,
    speed: 1200,
    parallax: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    shouldSwiperUpdate: true,
    on: {
      resize: () => setPagination(),
    }
  }

  const faderParams = {
    slidesPerView: 'auto',
    grabCursor: false,
    allowTouchMove: false,
    loop: true,
    loopedSlides: images.length,
    lazy: true,
    speed: 1000,
    parallax: false,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: '1000',
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: false
    },
    shouldSwiperUpdate: true,
    on: {
      resize: () => setPagination()
    },
    activeSlideKey: 1,
  }

  useEffect(() => {
    let timeout;

    if (swiper !== null) {
      timeout = setTimeout(() => {
        setPagination();

        mySwiperRef.current.classList.add("show-swiper");
        swiper.update()
      }, 500)
    }
    return () => {
      clearTimeout(timeout)
    }
  },[swiper])

  useEffect(() => {
    let timeout;

    // This is a hacky workaround as the legacy version of Swiper used in this project does not work properly
    // with React
    if (swiper && swiper.params.loopedSlides) {
      timeout = setTimeout(() => {
        swiper.loopDestroy();
        swiper.loopCreate();
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [swiper]);

  const classConstructor = (item) => {
    let classes;
    if(item.image && item.image.localFile){
      const aspect = Math.floor(item.image.mediaDetails.width/item.image.mediaDetails.height*100)/100
      if(aspect > 1) { classes = "w-md-31 w-46" } else { classes = "w-md-17 w-25" }
    } else {
      classes = "w-md-31 w-46"
    }
    return classes;
  }

  const renderFigures = items => (
    items.map((item, index) => (
      <figure
        className={classNames(
          classConstructor(item),
          items.length > 1 && "mr-1",
          "slide-wrapper ml-auto"
        )}
        key={getSrc(item.image.localFile) || index}
      >
        <ImageContainer
          altText={item.image && item.image.altText || item.image && item.image.title}
          image={getImage(item?.image?.localFile)}
          src={item.image}
          previewSrc={{
            src: item.image && item.image.sourceUrl || typeof item.image === "string" && item.image || null
          }}
          useStandardImage
          aspectRatios={[0.70, 1.275]}
          classes="mb-1"
          dataSwiperParallax="10%"
          dataSwiperParallaxScale="1.2"
        />
        <figcaption className="pt-2 pr-1">
          {item.imageCaption}
        </figcaption>
      </figure>
    ))
  )

  return (
    <div ref={mySwiperRef} className="section services-teaser-section grid-17-20">
      <div className="container">
        { headline &&
          <SectionHeadline headline={headline} />
        }
        <div className="row">
          <RemoveClassInview>
            <div className="text-side">
              <div className="mt-md-0 mt-2 d-flex align-self-end">
                <TeaserDescription
                  headline={teaserHeadline}
                  text={teaserHtml || teaserText}
                  linkText={teaserLinkText}
                  externalLink={teaserLinkTarget}
                  internalLink={teaserInternalLink && teaserInternalLink.uri}
                  isHtml={!!teaserHtml}
                  showLinkAsButton={showTeaserAsButton}
                  classes="w-md-14 w-40 pl-1"
                />
              </div>
            </div>
          </RemoveClassInview>
          <RemoveClassInview onClick={goNext}>
            <div className={classNames(images.length > 1 && "swiper-element", "image-side w-46 w-md-31 ml-auto")}>
              { images.length > 1 ? (
                <>
                  <Button
                    icon="arrow"
                    classes={classNames(fade && "d-none", "bg-dark-gray-translucent btn-circle")}
                    onClick={goNext}
                  />
                  <Swiper {...fade ? faderParams : swiperParams} getSwiper={updateSwiper}>
                    {
                      renderFigures(images)
                    }
                  </Swiper>
                </>
                ) : (
                  renderFigures(images)
                )
              }
            </div>
          </RemoveClassInview>
        </div>
      </div>
    </div>
  );
};

ServicesTeaserSection.propTypes = {
  headline: PropTypes.string,
  images: PropTypes.instanceOf(Array),
  text: PropTypes.string,
  linkText: PropTypes.string,
  linkTarget: PropTypes.string,
};

export const query = graphql`fragment PageServicesTeaserSectionFragment on WpPage_Flex_Content_ServicesTeaserSection {
  headline
  teaserHeadline
  teaserText
  teaserHtml
  teaserLinkText
  teaserLinkTarget
  teaserInternalLink {
    ... on WpContentNode {
      uri
    }
  }
  showTeaserAsButton
  fade
  images {
    imageCaption
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment PostServicesTeaserSectionFragment on WpPost_Flex_Content_ServicesTeaserSection {
  headline
  teaserHeadline
  teaserText
  teaserHtml
  teaserLinkText
  teaserLinkTarget
  teaserInternalLink {
    ... on WpContentNode {
      uri
    }
  }
  showTeaserAsButton
  fade
  images {
    imageCaption
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment PressFeatureServicesTeaserSectionFragment on WpPressFeature_Flex_Content_ServicesTeaserSection {
  headline
  teaserHeadline
  teaserText
  teaserHtml
  teaserLinkText
  teaserLinkTarget
  teaserInternalLink {
    ... on WpContentNode {
      uri
    }
  }
  showTeaserAsButton
  fade
  images {
    imageCaption
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment ProjectServicesTeaserSectionFragment on WpProject_Flex_Content_ServicesTeaserSection {
  headline
  teaserHeadline
  teaserText
  teaserHtml
  teaserLinkText
  teaserLinkTarget
  teaserInternalLink {
    ... on WpContentNode {
      uri
    }
  }
  showTeaserAsButton
  fade
  images {
    imageCaption
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment CompetenceServicesTeaserSectionFragment on WpCompetence_Flex_Content_ServicesTeaserSection {
  headline
  teaserHeadline
  teaserText
  teaserHtml
  teaserLinkText
  teaserLinkTarget
  teaserInternalLink {
    ... on WpContentNode {
      uri
    }
  }
  showTeaserAsButton
  fade
  images {
    imageCaption
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment JobServicesTeaserSectionFragment on WpJob_Flex_Content_ServicesTeaserSection {
  headline
  teaserHeadline
  teaserText
  teaserHtml
  teaserLinkText
  teaserLinkTarget
  teaserInternalLink {
    ... on WpContentNode {
      uri
    }
  }
  showTeaserAsButton
  fade
  images {
    imageCaption
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`
export default ServicesTeaserSection;
