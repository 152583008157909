/* eslint-disable react/no-array-index-key */
import React from 'react';
import {getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import PageTransitionLink from 'Layout/PageTransition/PageTransitionLink.jsx';
import PropTypes from 'prop-types';
import RemoveClassInview from "Accessories/RemoveClassInview/RemoveClassInview.jsx";
import SectionHeadline from 'Accessories/SectionHeadline/SectionHeadline.jsx';
import ImageContainer from "Accessories/ImageContainer/ImageContainer.jsx"

import './JobTeaserSection.scss';

const JobTeaserSection = props => {

  const { image, headline, listTitle, applyCta, applicationPage, jobs, hidden } = props

  if (!jobs.length || hidden) {
    return null
  }

  return (
    <div className="section WpJobs-teaser-section px-1 px-md-0">
      <div className="container">
        {
          headline && (
            <SectionHeadline
              headline={headline}
            />
          )
        }
        <RemoveClassInview>
          <div className="px-1 grid-1 grid-md-2">
            <ImageContainer
              altText={image.altText || image.title}
              image={getImage(image.localFile)}
              aspectRatios={[300/440]}
              useStandardImage
            />
            <div>

              <h3 className="py-2">
                {listTitle}
              </h3>
              <ol className="job-teaser-section__list">
                {
                  jobs.map(job => (
                    <li key={job.databaseId} className="job-teaser-section__item">
                      <PageTransitionLink
                        internalLink={job.uri}
                        className="job-teaser-section__link"
                      >
                        <div className="job-teaser-section__title">
                          {job.title}
                        </div>
                      </PageTransitionLink>
                      <PageTransitionLink
                        internalLink={applicationPage.uri}
                        className="job-teaser-section__button btn btn-submit btn-border"
                        state={{jobId: job.databaseId}}
                      >
                        {applyCta}
                      </PageTransitionLink>
                    </li>
                  ))
                }
              </ol>
            </div>
          </div>
        </RemoveClassInview>
      </div>
    </div>
  );
};

JobTeaserSection.propTypes = {
  image: PropTypes.object,
  headline: PropTypes.string,
  listTitle: PropTypes.string,
  applyCta: PropTypes.string,
  applicationPage: PropTypes.object,
  jobs: PropTypes.array,
  hidden: PropTypes.bool,
};

export const query = graphql`
  fragment PageJobTeaserSectionFragment on WpPage_Flex_Content_JobTeaserSection {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
    headline
    listTitle
    applyCta
    applicationPage {
      ... on WpPage {
        uri
      }
    }
    jobs {
      __typename
      ... on WpJob {
        id
        databaseId
        title
        uri
      }
    }
    hidden
  }
  fragment PostJobTeaserSectionFragment on WpPost_Flex_Content_JobTeaserSection {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
    headline
    listTitle
    applyCta
    applicationPage {
      ... on WpPage {
        uri
      }
    }
    jobs {
      __typename
      ... on WpJob {
        id
        databaseId
        title
        uri
      }
    }
    hidden
  }
  fragment PressFeatureJobTeaserSectionFragment on WpPressFeature_Flex_Content_JobTeaserSection {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
    headline
    listTitle
    applyCta
    applicationPage {
      ... on WpPage {
        uri
      }
    }
    jobs {
      __typename
      ... on WpJob {
        id
        databaseId
        title
        uri
      }
    }
    hidden
  }
  fragment ProjectJobTeaserSectionFragment on WpProject_Flex_Content_JobTeaserSection {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
    headline
    listTitle
    applyCta
    applicationPage {
      ... on WpPage {
        uri
      }
    }
    jobs {
      __typename
      ... on WpJob {
        id
        databaseId
        title
        uri
      }
    }
    hidden
  }
  fragment CompetenceJobTeaserSectionFragment on WpCompetence_Flex_Content_JobTeaserSection {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
    headline
    listTitle
    applyCta
    applicationPage {
      ... on WpPage {
        uri
      }
    }
    jobs {
      __typename
      ... on WpJob {
        id
        databaseId
        title
        uri
      }
    }
    hidden
  }
  fragment JobJobTeaserSectionFragment on WpJob_Flex_Content_JobTeaserSection {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
    headline
    listTitle
    applyCta
    applicationPage {
      ... on WpPage {
        uri
      }
    }
    jobs {
      __typename
      ... on WpJob {
        id
        databaseId
        title
        uri
      }
    }
    hidden
  }
`

export default JobTeaserSection;
