import React from 'react';
import { useGlobal } from "reactn";
import { graphql } from "gatsby";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SectionHeadline from 'Accessories/SectionHeadline/SectionHeadline.jsx';
import Teaser from 'Layout/Teaser/Teaser.jsx';
import './RelatedStorySection.scss';
import {getImage} from "gatsby-plugin-image";

const RelatedStorySection = props => {

  const [lang] = useGlobal("lang");
  const isLangDE = lang === "de"

  const { headline, relatedStories } = props

  const gridStyles = classNames(
    relatedStories.length > 3 && "grid-md-4 grid-2",
    relatedStories.length === 3 && "grid-md-3 grid-2",
    relatedStories.length === 2 && "grid-md-2 grid-2",
    relatedStories.length === 1 && "grid-md-3 grid-1",
    "grid-rg-5 grid-xs-1 px-xs-2 px-md-0"
  )

  const gridItemStyles = classNames(
    relatedStories.length === 1 && "grid-item-md-2 grid-item-1",
  )

  return (
    <div className="section related-stories-section">
      {
        headline && relatedStories.length > 0 && (
          <SectionHeadline
            headline={headline}
          />
        )
      }
      <div className={gridStyles}>
        {
          relatedStories.length > 0 && relatedStories.map(item => (
            <Teaser
              key={item.databaseId}
              altText={item.featuredImage.node && item.featuredImage.node.altText || item.featuredImage.node && item.featuredImage.node.title}
              image={getImage(item?.featuredImage?.node?.localFile)}
              previewSrc={{
                src: item.featuredImage.node && item.featuredImage.node.sourceUrl || `${item.featuredImage.node}` || null
              }}
              text={item.title}
              linkText={isLangDE ? "Entdecken" : "Explore"}
              internalLink={item.uri}
              alignCenter
              aspectRatios={[0.666]}
              classes={gridItemStyles}
            />
          ))
        }
      </div>
    </div>
  );
};

RelatedStorySection.propTypes = {
  headline: PropTypes.string,
  relatedStories:  PropTypes.instanceOf(Object),
};

export const query = graphql`
  fragment PageRelatedStorySectionFragment on WpPage_Flex_Content_RelatedStorySection {
    headline
  }
  fragment PostRelatedStorySectionFragment on WpPost_Flex_Content_RelatedStorySection {
    headline
  }
  fragment PressFeatureRelatedStorySectionFragment on WpPressFeature_Flex_Content_RelatedStorySection {
    headline
  }
  fragment ProjectRelatedStorySectionFragment on WpProject_Flex_Content_RelatedStorySection {
    headline
  }
  fragment CompetenceRelatedStorySectionFragment on WpCompetence_Flex_Content_RelatedStorySection {
    headline
  }
  fragment JobRelatedStorySectionFragment on WpJob_Flex_Content_RelatedStorySection {
    headline
  }
`

export default RelatedStorySection;
