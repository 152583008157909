/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import {parse} from 'json2csv';
import classNames from 'classnames'
import Swiper from "react-id-swiper"
import { useWindowWidth } from '@react-hook/window-size'
import Button from 'Accessories/Button/Button.jsx'
import IntroOutroStep from "./IntroOutroStep";
import QuestionnaireStep from "./QuestionnaireStep";
import './SurveyForm.scss'

const SurveyForm = ({classes, variant, survey, location}) => {
  const [currentStep, setCurrentStep] = useState("intro") // intro | questionnaire | outro
  const [currentQuestionnaireStep, setCurrentQuestionnaireStep] = useState(0) // 0 .. surveySteps.length
  const windowWidth = useWindowWidth()
  const [swiper, setSwiper] = useState(null)
  const [isQuestionnaireBeginning, setIsQuestionnaireBeginning] = useState(true)
  const [isQuestionnaireEnd, setIsQuestionnaireEnd] = useState(false)
  const totalSlidesIndicator = [];
  const orientation = windowWidth < 1024 ? 'portrait' : 'landscape'

  const swiperParams = {
    slidesPerView: 1,
    grabCursor: false,
    speed: 1200,
    autoplay: false,
    loop: false,
    lazy: true,
    parallax: true,
    activeSlideKey: 0,
    // It is absolutely necessary to disable all kind of swiping as enabling it will influence the click behavior in a negative way
    noSwiping: true,
  }

  const surveyIntro = survey.find(s => s.type === "isIntro")
  const surveySteps = survey.filter(s => s.type !== "isIntro" && s.type !== "isOutro")
  const surveyOutro = survey.find(s => s.type === "isOutro")

  const surveyVariant = variant === location?.hash?.split("-")[1] ? variant : false
  const [surveyId] = useState(location?.hash?.split("-")[0].replace("#", "") || false)

  const csvHeader = ["Variante", "Projektnummer"].concat(surveySteps.map((step) => (step.title)))
  let initResponseData = {}
  csvHeader.forEach(header => {
    let value = 0;

    if (header === "Variante") {
      value = surveyVariant ? parseInt(surveyVariant, 10) : '-';
    } else if (header === "Projektnummer") {
      value = surveyId ? parseInt(surveyId, 10) : '-';
    }

    initResponseData = {
      ...initResponseData,
      [header]: value,
    }
  })
  const [responseData, setResponseData] = useState(initResponseData)

  const updateBullets = () => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < surveySteps.length; i++) {
      (
        totalSlidesIndicator.push(
          <div key={`slider-indicator-${i}`} className={`survey-slider-dots__dot ${i === currentQuestionnaireStep ? 'survey-slider-dots__dot--active' : ''}`} />
        )
      )
    }

    return totalSlidesIndicator
  }

  useEffect(() => {
    if (!swiper) {
      return
    }

    setIsQuestionnaireBeginning(swiper.isBeginning)
    setIsQuestionnaireEnd(swiper.isEnd)
  }, [swiper])

  useEffect(() => {
    let timeout;

    // This is a hacky workaround as the legacy version of Swiper used in this project does not work properly
    // with React
    if (swiper && swiper.params.loopedSlides) {
      timeout = setTimeout(() => {
        swiper.loopDestroy();
        swiper.loopCreate();
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [swiper]);

  const handleSlideChange = () => {
    setIsQuestionnaireBeginning(swiper.isBeginning)
    setIsQuestionnaireEnd(swiper.isEnd)
    setCurrentQuestionnaireStep(swiper.realIndex)
    updateBullets(swiper.realIndex)
  }

  useEffect(() => {
    swiper?.on("slideChange", handleSlideChange);

    return () => {
      swiper?.off("slideChange", handleSlideChange);
    };
  }, [swiper]);

  const submitToServer = async () => {
    const csv = await parse(responseData, {fields: csvHeader})

    try {
      await fetch(`/.netlify/functions/survey`, {
        method: "POST",
        body: JSON.stringify({
          csvParsed: csv,
          surveyId: surveyId || 'no-survey-id'
        }),
        headers: {'Content-Type': 'application/json'}
      })
    } catch(err) {
      // eslint-disable-next-line no-console
      console.log('Error: ', err)
    }
  }

  useEffect(() => {
    if (currentStep === "outro") {
      submitToServer()
    }
  }, [currentStep])

  const handleIntroComplete = () => {
    setCurrentQuestionnaireStep(0);
    setCurrentStep("questionnaire");
  }

  const handleQuestionnaireStepNext = (e) => {
    e.preventDefault()

    swiper.slideNext()
  }

  const handleQuestionnaireStepPrev = (e) => {
    e.preventDefault()

    swiper.slidePrev()
  }

  const handleQuestionnaireStepComplete = (value) => {
    const cs = surveySteps.find((_, index) => index === currentQuestionnaireStep)
    const newResponseData = responseData
    // Eham' CRM system cannot handle stringified delimiters, so we've to kill them all before storing data
    if (typeof value === 'string') {
      newResponseData[cs.title] = value.replace(/[,\n\r]/gm, ' ')
    } else {
      newResponseData[cs.title] = value
    }

    setResponseData(newResponseData)

    if (currentQuestionnaireStep === (surveySteps.length - 1)) {
      setCurrentStep("outro")

      return
    }

    swiper.slideNext()
  }

  return (
    <div className={classNames("flex-column form-container flex-lg-row justify-content-between", classes)}>
      <form className="form-wrapper inquiry-form">
        <div className="input-wrapper d-flex flex-column justify-content-around">
          {currentStep === "intro" && <IntroOutroStep step={surveyIntro} onComplete={handleIntroComplete} orientation={orientation} />}
          {
            currentStep === "questionnaire" && (
              <div className="section-swiper">
                <div className="survey-slider-navigation-container">
                  {!isQuestionnaireBeginning && (
                  <Button
                    icon="arrow"
                    classes={`bg-dark-gray-translucent btn-circle survey-slider-navigation survey-slider-navigation--${currentStep.theme} survey-slider-navigation__prev`}
                    iconRotation="180deg"
                    onClick={handleQuestionnaireStepPrev}
                  />
                  )}
                  <div className="survey-slider-dots">
                    {updateBullets()}
                  </div>
                  {!isQuestionnaireEnd && (
                  <Button
                    icon="arrow"
                    classes={`bg-dark-gray-translucent btn-circle survey-slider-navigation survey-slider-navigation--${currentStep.theme} survey-slider-navigation__next`}
                    onClick={handleQuestionnaireStepNext}
                  />
                  )}
                </div>
                <Swiper {...swiperParams} getSwiper={setSwiper}>
                  {surveySteps.map(surveyStep => (
                    <div key={surveyStep.title} className="slide-container">
                      <QuestionnaireStep
                        key={surveyStep.title}
                        step={surveyStep}
                        onComplete={handleQuestionnaireStepComplete}
                        currentValue={responseData[surveyStep.title]}
                        orientation={orientation}
                      />
                    </div>
                  ))}
                </Swiper>
              </div>
          )}
          {currentStep === "outro" && <IntroOutroStep step={surveyOutro} orientation={orientation} />}
        </div>
      </form>
    </div>
  )
}

export default SurveyForm
