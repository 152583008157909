module.exports = {
  siteTitle: "EHAM", // Site title.
  siteTitleAlt: "Eham", // Alternative site title for SEO.
  siteBrand: "Eham", // Site brand.
  sitePrimaryColor: "rgb(249, 244, 241)",
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://eham-live.netlify.com", // Domain of your website without pathPrefix.
  backendUrl: "https://eham-admin.mobydigg.de", // Domain of your Backend without pathPrefix.
  graphQLUrl: "https://eham-admin.mobydigg.de/graphql", // Domain of your Backend without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "", // Website description used for RSS feeds/meta description tag.
  postDefaultCategoryID: "Design", // Default category for posts.
  localeDirectories: {
    root: {
      de: "/",
      en: "/en",
    },
    competences: {
      de: "/kompetenzen",
      en: "/areas-of-expertise",
    },
    kompetenzen: {
      de: "/kompetenzen",
      en: "/areas-of-expertise",
    },
    project_competences: {
      // wpApiMenues has a differnt key for the same thing..
      de: "/kompetenzen",
      en: "/areas-of-expertise",
    },
    projects: {
      de: "/projekte",
      en: "/projects",
    },
    projekte: {
      de: "/projekte",
      en: "/projects",
    },
    jobs: {
      de: "/karriere",
      en: "/career",
    },
    career: {
      de: "/karriere",
      en: "/career",
    },
    karriere: {
      de: "/karriere",
      en: "/career",
    },
    posts: {
      de: "/aktuelles",
      en: "/news",
    },
    post: {
      de: "/aktuelles",
      en: "/news",
    },
    aktuelles: {
      de: "/aktuelles",
      en: "/news",
    },
    news: {
      de: "/aktuelles",
      en: "/news",
    },
    presse: {
      de: "/presse",
      en: "/press",
    },
    press: {
      de: "/presse",
      en: "/press",
    },
    pressfeatures: {
      de: "/presse",
      en: "/press",
    },
    umfrage: {
      de: "/umfrage",
      en: "/umfrage",
    },
  },
  // hast to match with redirects (netlify.toml) and disallows (robots.txt).
  errorDirectory: "/404-error",
};
