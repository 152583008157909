import React from 'react';
import { graphql } from "gatsby";
import PropTypes from 'prop-types';
import SectionHeadline from 'Accessories/SectionHeadline/SectionHeadline.jsx';
import RemoveClassInview from "Accessories/RemoveClassInview/RemoveClassInview.jsx";
import GoogleMapReact from 'google-map-react';
import './LocationSection.scss';

const Marker = (props) => {
  const { color, name, text } = props;
  return (
    <div
      className="marker"
      style={{ backgroundColor: color, cursor: 'pointer',}}
      title={name}
    >
      <span>{text}</span>
    </div>
  );
};

const LocationSection = props => {

  // eslint-disable-next-line no-unused-vars
  const { headline, description, coords } = props

  return (
    <div className="section location-section">
      <div className="container">
        {
          headline && (
            <SectionHeadline
              headline={headline}
            />
          )
        }
        <div className="row align-items-end">
          <RemoveClassInview>
            <div className="text-container mr-auto mb-5 mb-md-0">
              <div className="text" dangerouslySetInnerHTML={{__html: description}} />
            </div>
          </RemoveClassInview>
          <RemoveClassInview>
            <div className="map-container w-47 w-md-28">
              {
                coords.lng !== "" && coords.lat !== "" && (
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyAT3YmIxnjKZ4IZFgwouoBbtFWwLZkh2j0" }}
                    defaultCenter={{ lat: parseFloat(coords.lat), lng: parseFloat(coords.lng) }}
                    defaultZoom={12}
                    yesIWantToUseGoogleMapApiInternals
                  >
                    <Marker
                      lat={parseFloat(coords.lat)}
                      lng={parseFloat(coords.lng)}
                      name="EHAM"
                      color="red"
                      text="EHAM"
                    />
                  </GoogleMapReact>
                )
              }
            </div>
          </RemoveClassInview>
        </div>
      </div>
    </div>
  );
};

LocationSection.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
  coords: PropTypes.PropTypes.shape({
    lat: PropTypes.string,
    lng: PropTypes.string
  }),
};

export const query = graphql`
  fragment PageLocationSectionFragment on WpPage_Flex_Content_LocationSection {
    headline
    locationDescription
    locationCoordinates {
      lat
      lng
    }
  }
  fragment PostLocationSectionFragment on WpPost_Flex_Content_LocationSection {
    headline
    locationDescription
    locationCoordinates {
      lat
      lng
    }
  }
  fragment PressFeatureLocationSectionFragment on WpPressFeature_Flex_Content_LocationSection {
    headline
    locationDescription
    locationCoordinates {
      lat
      lng
    }
  }
  fragment ProjectLocationSectionFragment on WpProject_Flex_Content_LocationSection {
    headline
    locationDescription
    locationCoordinates {
      lat
      lng
    }
  }
  fragment CompetenceLocationSectionFragment on WpCompetence_Flex_Content_LocationSection {
    headline
    locationDescription
    locationCoordinates {
      lat
      lng
    }
  }
  fragment JobLocationSectionFragment on WpJob_Flex_Content_LocationSection {
    headline
    locationDescription
    locationCoordinates {
      lat
      lng
    }
  }
`

export default LocationSection;
