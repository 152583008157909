import React from "react"
import {graphql} from "gatsby"
import PropTypes from "prop-types"
import SectionHeadline from "Accessories/SectionHeadline/SectionHeadline.jsx"
import ImageContainer from "Accessories/ImageContainer/ImageContainer.jsx"
import VideoPlayer from "Accessories/VideoPlayer/VideoPlayer.jsx"
import "./HeroSection.scss"
import {getImage, getSrc} from "gatsby-plugin-image";

const HeroSection = ({headline, contentType, video, image, poster, hasControls}) => {
  return (
    <div className="section hero-section pl-1">
      {headline && <SectionHeadline headline={headline} isH1 />}
      {(image || video) && (
        contentType === "image" ? (
          <ImageContainer
            altText={image && image.altText || image && image.title}
            image={getImage(image?.localFile)}
            previewSrc={{src: image?.sourceUrl || typeof image === "string" && image  || null}}
          />
        ) : (
          <VideoPlayer
            video={video.mediaItemUrl}
            poster={getSrc(poster?.localFile) || poster}
            hasControls={hasControls}
          />
        )
      )}
    </div>
  );
}

HeroSection.propTypes = {
  headline: PropTypes.string,
  contentType: PropTypes.string,
  video: PropTypes.instanceOf(Object),
  image: PropTypes.instanceOf(Object)
}

export const query = graphql`fragment PageHeroSectionFragment on WpPage_Flex_Content_HeroSection {
  headline
  selectContentType
  image {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
  video {
    mediaItemUrl
    mediaDetails {
      height
      width
    }
  }
  videoPosterImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
  hasControls
}

fragment PostHeroSectionFragment on WpPost_Flex_Content_HeroSection {
  headline
  selectContentType
  image {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
  video {
    mediaItemUrl
    mediaDetails {
      height
      width
    }
  }
  videoPosterImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
  hasControls
}

fragment PressFeatureHeroSectionFragment on WpPressFeature_Flex_Content_HeroSection {
  headline
  selectContentType
  image {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
  video {
    mediaItemUrl
    mediaDetails {
      height
      width
    }
  }
  videoPosterImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
  hasControls
}

fragment ProjectHeroSectionFragment on WpProject_Flex_Content_HeroSection {
  headline
  selectContentType
  image {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
  video {
    mediaItemUrl
    mediaDetails {
      height
      width
    }
  }
  videoPosterImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
  hasControls
}

fragment CompetenceHeroSectionFragment on WpCompetence_Flex_Content_HeroSection {
  headline
  selectContentType
  image {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
  video {
    mediaItemUrl
    mediaDetails {
      height
      width
    }
  }
  videoPosterImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
  hasControls
}

fragment JobHeroSectionFragment on WpJob_Flex_Content_HeroSection {
  headline
  selectContentType
  image {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
  video {
    mediaItemUrl
    mediaDetails {
      height
      width
    }
  }
  videoPosterImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
  hasControls
}
`

export default HeroSection
