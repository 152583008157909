/* eslint-disable no-unused-vars */
import React from 'react'
import classNames from "classnames";
import { TransitionState } from 'gatsby-plugin-transition-link'
import "./PageTransition.scss"

const PageTransition = props => {
  const { children } = props;

  return (
    <TransitionState>
      {({ transitionStatus}) => {

        return (
          <>
            <div className="transition-content">
              {children}
            </div>
            <div className={
              classNames(
                "transition-wiper"
              )}
            />
          </>
        )
      }}
    </TransitionState>
  )
}

export default PageTransition
