/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { graphql } from "gatsby";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Swiper from 'react-id-swiper';
import RemoveClassInview from "Accessories/RemoveClassInview/RemoveClassInview.jsx";
import Button from '../../Accessories/Button/Button'
import ImageContainer from '../../Accessories/ImageContainer/ImageContainer'
import SectionHeadline from '../../Accessories/SectionHeadline/SectionHeadline';

import './BasicSliderSection.scss';
import {getImage, getSrc} from "gatsby-plugin-image";

const BasicSliderSection = props => {
  const { headline, images, autoplay } = props
  const [swiper, updateSwiper] = useState(null);
  const mySwiperRef = useRef(null)

  if(!images || !images.length) return null

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  };

  const setPagination = () => {
      const swiperPagination = mySwiperRef.current.querySelector(".swiper-pagination");

      const imageContainer = mySwiperRef.current.querySelector(".image-container");
      swiperPagination.style.top = `${imageContainer.clientHeight}px`;
  }

  // const swiperParams = {
  //   slidesPerView: 'auto',
  //   grabCursor: false,
  //   loop: true,
  //   loopedSlides: images.length * 2,
  //   lazy: true,
  //   speed: 1200,
  //   parallax: true,
  //   pagination: {
  //     el: '.swiper-pagination',
  //     clickable: true
  //   },
  //   on: {
  //     resize: () => setPagination(),
  //   },
  //   activeSlideKey: 0,
  // }

  const swiperParamsDefault = {
    slidesPerView: 'auto',
    grabCursor: false,
    loop: true,
    loopedSlides: images.length * 2,
    lazy: true,
    parallax: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    on: {
      resize: () => setPagination(),
    },
    activeSlideKey: 0,
  }

  const swiperParamsManual = {
    speed: 1200,
  }

  const swiperParamsAuto = {
    speed: 3000,
    delay: 6000,
    autoplay: {
      disableOnInteraction: false,
    },
  }

  const swiperParams = autoplay ? {...swiperParamsDefault, ...swiperParamsAuto} : {...swiperParamsDefault, ...swiperParamsManual}

  useEffect(() => {
    let timeout;

    if (swiper !== null) {
      timeout = setTimeout(() => {
          setPagination();

          mySwiperRef.current.classList.add("show-swiper");
          swiper.update()
      }, 500)
    }
    return () => {
      clearTimeout(timeout)
    }
  },[swiper])

  useEffect(() => {
    let timeout;

    // This is a hacky workaround as the legacy version of Swiper used in this project does not work properly
    // with React
    if (swiper && swiper.params.loopedSlides) {
      timeout = setTimeout(() => {
        swiper.loopDestroy();
        swiper.loopCreate();
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [swiper]);

  const [imageAspect, setImageAspect] = useState()

  const changeHandler = (ratio) => {
    setImageAspect(ratio)
  }

  const imageConstructor = (item) => {
    const image = item?.image ? {
      aspectRatio: Math.floor(item?.image?.mediaDetails?.width / item?.image?.mediaDetails?.height * 100) / 100,
      src: item?.image?.sourceUrl
    } : null

    return image
  }

  const classConstructor = (item) => {
    let classes;

    if(item?.image?.mediaDetails || imageAspect) {
      const aspect = imageAspect || imageConstructor(item).aspectRatio

      if(aspect > 1) { classes = "w-46 w-md-31" } else { classes = "w-22 w-md-15" }
    } else {
      classes = "w-22 w-md-15"
    }
    return classes;
  }

  const renderFigures = items => (
    items && items.map((item, index) => (
      item.image && (
        <figure
          className={classNames(
            classConstructor(item),
            "slide-wrapper mr-1"
          )}
          key={getSrc(item?.image?.localFile) || index}
        >
          <ImageContainer
            altText={item.image.altText || item.image.title}
            image={
              getImage(item?.image?.localFile) ||
              imageConstructor(item)
            }
            previewSrc={{
              src: item?.image?.sourceUrl || typeof item.image === "string" && item.image || null
            }}
            useStandardImage
            aspectRatios={[1.378, 0.666]}
            newRatio={(ratio) => { changeHandler(ratio) }}
            classes="mb-1"
            dataSwiperParallax="10%"
            dataSwiperParallaxScale="1.2"
          />
          <figcaption className="pt-2 pr-1">
            {item.imageCaption}
          </figcaption>
        </figure>
      )
    ))
  )

  return (
    <div ref={mySwiperRef} className="section basic-slider-section">
      <div className="container">
        <SectionHeadline headline={headline} />
        <RemoveClassInview onClick={goNext}>
          <div className={classNames(images.length > 1 && "swiper-element", "row ml-auto w-46 w-md-47")}>
            { images.length > 1 ? (
              <>
                <Button
                  icon="arrow"
                  classes="bg-dark-gray-translucent btn-circle"
                  onClick={goNext}
                />
                <Swiper {...swiperParams} getSwiper={updateSwiper}>
                  {
                    renderFigures(images)
                  }
                </Swiper>
              </>
              ) : (
              renderFigures(images)
              )
            }
          </div>
        </RemoveClassInview>
      </div>
    </div>
  );
};

BasicSliderSection.propTypes = {
  headline: PropTypes.string,
  images: PropTypes.instanceOf(Array),
  autoplay: PropTypes.bool,
};

export const query = graphql`fragment PageBasicSliderSectionFragment on WpPage_Flex_Content_BasicSliderSection {
  autoplay
  headline
  images {
    imageCaption
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment PostBasicSliderSectionFragment on WpPost_Flex_Content_BasicSliderSection {
  autoplay
  headline
  images {
    imageCaption
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment PressFeatureBasicSliderSectionFragment on WpPressFeature_Flex_Content_BasicSliderSection {
  autoplay
  headline
  images {
    imageCaption
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment ProjectBasicSliderSectionFragment on WpProject_Flex_Content_BasicSliderSection {
  autoplay
  headline
  images {
    imageCaption
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment CompetenceBasicSliderSectionFragment on WpCompetence_Flex_Content_BasicSliderSection {
  autoplay
  headline
  images {
    imageCaption
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment JobBasicSliderSectionFragment on WpJob_Flex_Content_BasicSliderSection {
  autoplay
  headline
  images {
    imageCaption
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`

export default BasicSliderSection;
