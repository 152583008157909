/* eslint-disable camelcase */
import React, {useState} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import Button from 'Accessories/Button/Button.jsx'
import Icon from 'Accessories/Icon/Icon.jsx'
import './NewsletterForm.scss'

const NewsletterForm = ({classes, lang}) => {
  const data = useStaticQuery(graphql`
    query NewsLetterQuery {
      optionsPage: wp {
        globalData {
          newsletter {
            newsletter {
              headline
              headlineEn
              privacyPolicy
              privacyPolicyEn
            }
          }
        }
      }
    }
  `)

  // eslint-disable-next-line camelcase
  const {headline, privacyPolicy, headlineEn, privacyPolicyEn} = data.optionsPage.globalData.newsletter.newsletter

  const isLangDE = lang === "de"
  const [email, setEmail] = useState("")
  const [isHidden, setIsHidden] = useState(true)

  const newsletterEndpoint = isLangDE ? (
    "https://tcc758948.emailsys1a.net/22/3887/5fae41e2f7/subscribe/form.html"
  ) : (
    "https://tcc758948.emailsys1a.net/22/5033/796e8f502e/subscribe/form.html"
  )

  const handleChange = (e)  => {
    setEmail(e.target.value)
  }

  const toggleSubmit = () => {
    setIsHidden(!isHidden)
  }

  return (
    <div className={classNames("newsletter-section bg-clay w-47 pb-10", classes)}>
      <div className="container">
        <div className="row py-10 py-md-5 px-1">
          <h2 className="headline p w-35 w-md-12">{isLangDE ? headline : headlineEn}</h2>
          <form className="form-wrapper w-45 w-md-21 pt-5 pt-md-0" action={newsletterEndpoint} method="post">
            <div className="input-wrapper">
              <Input
                placeholder={isLangDE ? "Vorname" : "First name"}
                inputProps={{
                  'aria-label': 'firstname',
                  pattern: "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+(([',. -][a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ])?[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]*)*$"
                }}
                id="firstname"
                name="firstname"
                className="input-text mb-3"
                required
                autoComplete="given-name"
                fullWidth
                disableUnderline
              />
              <Input
                placeholder={isLangDE ? "Nachname" : "Surname"}
                inputProps={{
                  'aria-label': 'lastname',
                  pattern: "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+(([',. -][a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ])?[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]*)*$"
                }}
                id="lastname"
                name="lastname"
                className="input-text mb-3"
                required
                autoComplete="family-name"
                fullWidth
                disableUnderline
              />
              <Input
                name="email"
                id="email"
                placeholder={isLangDE ? "E-Mail" : "Email"}
                inputProps={{
                  'aria-label': 'email',
                }}
                className="input-text mb-3"
                type="email"
                required
                autoComplete="email"
                fullWidth
                disableUnderline
                value={email}
                onChange={(e) => handleChange(e)}
              />
              <div className="policy-wrapper d-flex mb-8 mb-md-0 w-md-19 pr-1">
                <Checkbox
                  value="yes"
                  inputProps={{
                    'aria-label': 'policy',
                  }}
                  icon={<Icon iconType="checkboxBlank" />}
                  checkedIcon={<Icon iconType="checkboxChecked" />}
                  className="input-checkbox mb-auto"
                  required
                  disableRipple
                  color="default"
                  onChange={toggleSubmit}
                  id="consent_text"
                  name="consent_text"
                />
                <div
                  className="policy"
                  dangerouslySetInnerHTML={{ __html: isLangDE ? privacyPolicy : privacyPolicyEn }}
                />
              </div>
            </div>
            <div className="submit-wrapper d-flex">
              <Button
                text={isLangDE ? "Senden" : "Submit"}
                type="submit"
                realButton
                classes="btn-submit btn-border ml-auto w-45 w-md-5"
              />
            </div>
            <input type="hidden" name="url_ok" value={isLangDE ? "https://www.eham.de/erfolgreiche-newsletter-anmeldung" : "https://www.eham.de/en/successful-newsletter-registration"} />
            <input type="hidden" name="url_error" value={isLangDE ? "https://www.eham.de/newsletter-anmeldung-fehlgeschlagen" : "https://www.eham.de/en/newsletter-registration-failed"} />
          </form>
        </div>
      </div>
    </div>
  )
}

NewsletterForm.propTypes = {
  classes: PropTypes.string
}

NewsletterForm.defaultProps = {
  classes: ""
}

export default NewsletterForm
