import React from "react"
import {graphql} from "gatsby"
import PropTypes from "prop-types"
import PostOverview from "Layout/PostOverview/PostOverview.jsx"
import SectionHeadline from "Accessories/SectionHeadline/SectionHeadline.jsx"

const FeaturedContentSection = ({sectionHeadline, items}) => {
  return (
    <div className="section related-stories-section">
      {sectionHeadline && (
        <SectionHeadline headline={sectionHeadline} />
      )}
      <PostOverview items={items} postType="featured" />
    </div>
  )
}

FeaturedContentSection.propTypes = {
  sectionHeadline: PropTypes.string,
  items: PropTypes.instanceOf(Object),
}

export const query = graphql`fragment PageFeaturedContentSectionFragment on WpPage_Flex_Content_FeaturedContentSection {
  sectionHeadline
  items {
    text
    internallLink {
      ... on WpContentNode {
        uri
      }
    }
    linkTarget
    linkText
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment PostFeaturedContentSectionFragment on WpPost_Flex_Content_FeaturedContentSection {
  sectionHeadline
  items {
    text
    internallLink {
      ... on WpContentNode {
        uri
      }
    }
    linkTarget
    linkText
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment PressFeatureFeaturedContentSectionFragment on WpPressFeature_Flex_Content_FeaturedContentSection {
  sectionHeadline
  items {
    text
    internallLink {
      ... on WpContentNode {
        uri
      }
    }
    linkTarget
    linkText
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment ProjectFeaturedContentSectionFragment on WpProject_Flex_Content_FeaturedContentSection {
  sectionHeadline
  items {
    text
    internallLink {
      ... on WpContentNode {
        uri
      }
    }
    linkTarget
    linkText
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment CompetenceFeaturedContentSectionFragment on WpCompetence_Flex_Content_FeaturedContentSection {
  sectionHeadline
  items {
    text
    internallLink {
      ... on WpContentNode {
        uri
      }
    }
    linkTarget
    linkText
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment JobFeaturedContentSectionFragment on WpJob_Flex_Content_FeaturedContentSection {
  sectionHeadline
  items {
    text
    internallLink {
      ... on WpContentNode {
        uri
      }
    }
    linkTarget
    linkText
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`

export default FeaturedContentSection
