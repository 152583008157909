/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import {graphql} from "gatsby"
import PropTypes from "prop-types"
import RemoveClassInview from "Accessories/RemoveClassInview/RemoveClassInview.jsx"
import SectionHeadline from "Accessories/SectionHeadline/SectionHeadline.jsx"
import InquiryForm from "Layout/Forms/InquiryForm/InquiryForm.jsx"
import "./InquirySection.scss"

const InquirySection = ({
    headline,
    text,
    email
  }) => {

  return (
    <div className="section inquiry-section">
      <div className="container">
        {
          headline && <SectionHeadline headline={headline} />
        }
        <div className="row justify-content-md-center">
          <RemoveClassInview>
            <div className="form-container w-47 w-md-17 w-lg-14 w-xl-10 ml-1 ml-md-0 mr-1 mr-md-3 order-2 order-md-1">
              <InquiryForm />
            </div>
          </RemoveClassInview>
          <RemoveClassInview>
            <div className="text-container w-47 w-md-17 w-xl-10 w-lg-14 mb-5 mb-md-0 ml-1 ml-md-0 mr-1 mr-md-0 order-1 order-md-2">
              <div dangerouslySetInnerHTML={{__html: text}} />
            </div>
          </RemoveClassInview>
        </div>
      </div>
    </div>
  )
}

InquirySection.propTypes = {
  headline: PropTypes.string,
  text: PropTypes.string,
}

export const query = graphql`
  fragment PageInquirySectionFragment on WpPage_Flex_Content_InquirySection {
    headline
    text
  }
  fragment PostInquirySectionFragment on WpPost_Flex_Content_InquirySection {
    headline
    text
  }
  fragment PressFeatureInquirySectionFragment on WpPressFeature_Flex_Content_InquirySection {
    headline
    text
  }
  fragment ProjectInquirySectionFragment on WpProject_Flex_Content_InquirySection {
    headline
    text
  }
  fragment CompetenceInquirySectionFragment on WpCompetence_Flex_Content_InquirySection {
    headline
    text
  }
  fragment JobInquirySectionFragment on WpJob_Flex_Content_InquirySection {
    headline
    text
  }
`

export default InquirySection
