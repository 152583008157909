import React from 'react'
import './ErrorList.scss'

const ErrorList = props => {
  const { errors, className } = props

  if (!errors.length) {
    return null
  }

  if (errors.length === 1) {
    return (
      <div className={`text-error ${className || ""}`}>{errors[0]}</div>
    )
  }

  return (
    <ul className={`error-list-list ${className || ""}`}>
      {errors.map((error, index) => (<li key={index} className="text-error">{error}</li>))}
    </ul>
  );
};

export default ErrorList;
