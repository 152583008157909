/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState }  from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import Button from "../Button/Button"
import Icon from "../Icon/Icon"
import "./VideoPlayer.scss"

const VideoPlayer = ({
  video,
  poster,
  hasControls,
  classes,
  dataSwiperParallax,
  dataSwiperParallaxScale
}) => {
  const videoEl = useRef(null)

  useEffect(() => {
    if (!hasControls) {
      const playPromise = videoEl.current.play()

      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Automatic playback started!
          // Show playing UI.
          // We can now safely pause video...
        })
        .catch(error => {
          // Auto-play was prevented
          /* eslint-disable-next-line */
          console.log(error)
        })
      }
    }
  })

  return (
    <div className={classnames("video-player video-container", classes)}>
      <div className="video-background">
        <div
          className="video-foreground"
          data-swiper-parallax={dataSwiperParallax}
          data-swiper-parallax-scale={dataSwiperParallaxScale}
        >
          <video
            ref={videoEl}
            src={video}
            poster={poster}
            controls={hasControls}
            controlsList="nodownload"
            autoPlay
            playsInline={!hasControls}
            disablePictureInPicture
            loop
            muted
          />
        </div>
      </div>
    </div>
  )
}

VideoPlayer.defaultProps = {
  hasControls: false
}

VideoPlayer.propTypes = {
  video: PropTypes.string,
  hasControls: PropTypes.bool,
  classes: PropTypes.string
}

export default VideoPlayer
