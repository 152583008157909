import React, {useEffect, useState} from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { useGlobal } from "reactn"
import SectionHeadline from 'Accessories/SectionHeadline/SectionHeadline.jsx'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import ListSubheader from '@material-ui/core/ListSubheader'
import Button from 'Accessories/Button/Button.jsx'
import RemoveClassInview from "Accessories/RemoveClassInview/RemoveClassInview.jsx"
import ImageContainer from "Accessories/ImageContainer/ImageContainer.jsx"
import TeaserDescription from 'Layout/Teaser/TeaserDescription/TeaserDescription.jsx';
import './JobApplicationForm.scss'
import ErrorList from "./ErrorList";
import {getImage} from "gatsby-plugin-image";
import {TextareaAutosize} from "@material-ui/core";

const JobApplicationForm = props => {
  const {
    buttonLabelApply,
    buttonLabelCv,
    buttonLabelMoreDocuments,
    documentInfos,
    failureMessage,
    headline,
    labelDocuments,
    labelPersonalData,
    labelPosition,
    labelApplicationText,
    placeholderCity,
    placeholderEmailAddress,
    placeholderFirstname,
    placeholderHouseNumber,
    placeholderLastname,
    placeholderMobileNumber,
    placeholderPhoneNumber,
    placeholderPosition,
    placeholderStreet,
    placeholderZip,
    placeholderApplicationText,
    successMessageHeadline,
    successMessage,
    successMessageLink,
    successMessageLinkText,
    successImage,
    location,
  } = props

  let referrer = null;
  let jobId = null

  if (location?.state?.jobId) {
    jobId = location.state.jobId
  } else if (location?.state?.referrer) {
    referrer = location.state.referrer
  }

  const [city, setCity] = useState("")
  const [cityErrors, setCityErrors] = useState([])
  const [emailAddress, setEmailAddress] = useState("")
  const [emailAddressErrors, setEmailAddressErrors] = useState([])
  const [firstname, setFirstname] = useState("")
  const [firstnameErrors, setFirstnameErrors] = useState([])
  const [houseNumber, setHouseNumber] = useState("")
  const [houseNumberErrors, setHouseNumberErrors] = useState([])
  const [lastname, setLastname] = useState("")
  const [lastnameErrors, setLastnameErrors] = useState([])
  const [mobileNumber, setMobileNumber] = useState("")
  const [mobileNumberErrors, setMobileNumberErrors] = useState([])
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNumberErrors, setPhoneNumberErrors] = useState([])
  const [position, setPosition] = useState("")
  const [positionErrors, setPositionErrors] = useState([])
  const [street, setStreet] = useState("")
  const [streetErrors, setStreetErrors] = useState([])
  const [zip, setZip] = useState("")
  const [zipErrors, setZipErrors] = useState([])
  const [applicationText, setApplicationText] = useState("")
  const [applicationTextErrors, setApplicationTextErrors] = useState([])
  const [cv, setCv] = useState("")
  const [cvErrors, setCvErrors] = useState([])
  const [moreDocuments, setMoreDocuments] = useState("")
  const [moreDocumentsErrors, setMoreDocumentsErrors] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false)
  const [formErrors, setFormErrors] = useState([])

  const [lang] = useGlobal("lang");

  const {allWpJob, allWpJobGroup} = useStaticQuery(graphql`
    query allJobsQuery {
      allWpJobGroup(sort: {fields: menuOrder, order: ASC}) {
        edges {
          node {
            databaseId
            title
            language {
              slug
            }
            featuredImage {
              node {
                sourceUrl
                mediaDetails {
                  height
                  width
                }
                altText
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
      allWpJob(filter: {title: {ne: "DO NOT DELETE"}}, sort: {fields: menuOrder, order: ASC}) {
        edges {
          node {
            databaseId
            uri
            language {
              slug
            }
            title
            content
            excerpt
            jobGroup {
              jobGroup {
                ... on WpJobGroup {
                  id
                  databaseId
                }
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (!referrer || position) {
      return;
    }

    const job = allWpJob.edges.find(job => job.node.uri === `${referrer}/`)

    if (job) {
      setPosition(job.node.databaseId)
    }
  }, [referrer, allWpJob, position])

  useEffect(() => {
    if (!jobId || position) {
      return;
    }

    const job = allWpJob.edges.find(job => job.node.databaseId === jobId)

    if (job) {
      setPosition(job.node.databaseId)
    }
  }, [jobId, allWpJob, position])

  const jobs = allWpJob.edges.filter(job => job.node.language.slug === lang)
  const jobGroups = allWpJobGroup.edges
    .filter(jobGroup => jobGroup.node.language.slug === lang)
    .filter(jobGroup => jobs.some(job => job.node.jobGroup.jobGroup.databaseId === jobGroup.node.databaseId))

  const handleSubmit = (e) => {
    e.preventDefault();

    if (submitting) {
      return
    }

    setSubmitting(true)
    setFormErrors([])
    setPositionErrors([]);
    setFirstnameErrors([]);
    setLastnameErrors([]);
    setEmailAddressErrors([]);
    setPhoneNumberErrors([]);
    setMobileNumberErrors([]);
    setStreetErrors([]);
    setHouseNumberErrors([]);
    setZipErrors([]);
    setCityErrors([]);
    setApplicationTextErrors([]);
    setCvErrors([]);
    setFirstnameErrors([]);

    const form = e.target;
    const formData = new FormData();
    formData.append('jobTitle', jobs.find(job => job.node.databaseId === position).node.title);
    formData.append('firstName', firstname);
    formData.append('lastName', lastname);
    formData.append('email', emailAddress);
    formData.append('phoneNumber', phoneNumber);
    formData.append('mobileNumber', mobileNumber);
    formData.append('street', street);
    formData.append('houseNr', houseNumber);
    formData.append('zip', zip);
    formData.append('city', city);
    formData.append('applicationText', applicationText);
    formData.append('cv', cv);
    formData.append('documents', moreDocuments);

    fetch("/.netlify/functions/apply", {
      method: "post",
      body: formData,
      headers: {
        'Accept-Language': lang,
      },
    })
      .then(res => {
        if (!res.ok) {
          return res
            .json()
            .then((resp) => {
              if ("errorMessage" in resp) {
                throw new Error(resp.errorMessage);
              }

              if (!("formErrors" in resp)) {
                throw new Error();
              }

              const { formErrors, fieldErrors } = resp;

              if (formErrors) {
                setFormErrors(formErrors)
              }

              Object.keys(fieldErrors).forEach(field => {
                switch (field) {
                  case 'jobTitle':
                    setPositionErrors(fieldErrors[field]);
                    break;
                  case 'firstName':
                    setFirstnameErrors(fieldErrors[field]);
                    break;
                  case 'lastName':
                    setLastnameErrors(fieldErrors[field]);
                    break;
                  case 'email':
                    setEmailAddressErrors(fieldErrors[field]);
                    break;
                  case 'phoneNumber':
                    setPhoneNumberErrors(fieldErrors[field]);
                    break;
                  case 'mobileNumber':
                    setMobileNumberErrors(fieldErrors[field]);
                    break;
                  case 'street':
                    setStreetErrors(fieldErrors[field]);
                    break;
                  case 'houseNr':
                    setHouseNumberErrors(fieldErrors[field]);
                    break;
                  case 'zip':
                    setZipErrors(fieldErrors[field]);
                    break;
                  case 'city':
                    setCityErrors(fieldErrors[field]);
                    break;
                  case 'applicationText':
                    setApplicationTextErrors(fieldErrors[field]);
                    break;
                  case 'cv':
                    setCvErrors(fieldErrors[field]);
                    break;
                  case 'documents':
                    setMoreDocumentsErrors(fieldErrors[field]);
                    break;
                }
              });

              setSubmitting(false);
            })
            .catch(e => {
              throw e;
            });
        }

        setPosition("")
        setFirstname("")
        setLastname("")
        setEmailAddress("")
        setPhoneNumber("")
        setMobileNumber("")
        setStreet("")
        setHouseNumber("")
        setZip("")
        setCity("")
        setCv(null)
        setMoreDocuments(null)
        form.reset();
        setSubmitting(false);

        setSuccessfullySubmitted(true)
      })
      .catch(() => {
        setSubmitting(false);
        setFormErrors([failureMessage])
      });
  }

  return (
    <div className="section job-application-form">
      <div className={`job-application-form__success ${successfullySubmitted ? "job-application-form__success--visible" : ""} grid-17-20 `}>
        <div className="row">
          <RemoveClassInview>
            <div className="text-side">
              <div className="mt-md-0 mt-2 d-flex align-self-end">
                <TeaserDescription
                  headline={successMessageHeadline}
                  text={successMessage}
                  linkText={successMessageLinkText}
                  internalLink={successMessageLink?.uri || ""}
                  isHtml={true}
                  classes="w-md-14 w-40 pl-1"
                />
              </div>
            </div>
          </RemoveClassInview>
          <RemoveClassInview>
            <div className="w-46 w-md-31 ml-auto image-side">
              <div className="w-full">
                <ImageContainer
                  altText={successImage.altText || successImage.title}
                  image={getImage(successImage?.localFile)}
                  previewSrc={{src: successImage?.sourceUrl || typeof successImage === "string" && image  || null}}
                />
              </div>
            </div>
          </RemoveClassInview>
        </div>
      </div>

      <div className={`job-application-form__form ${!successfullySubmitted ? "job-application-form__form--visible" : ""} w-47`}>
        <div className="container">
          <SectionHeadline
            headline={headline || "Bewerbung"}
          />

          <RemoveClassInview>
            <div>
              <form className="form-wrapper" onSubmit={handleSubmit}>
                {formErrors.length > 0 && (
                  <div className="row py-2 px-1">
                    <div className="headline p w-35 w-md-12 d-flex align-items-center" />
                    <div className="w-45 w-md-21 pt-5 pt-md-0">
                      <ErrorList errors={formErrors} />
                    </div>
                  </div>
                )}
                <div className="row py-10 py-md-5 px-1">
                  <h2 className="headline p w-35 w-md-12 d-flex align-items-center">{labelPosition}</h2>
                  <div className="w-45 w-md-21 pt-5 pt-md-0">
                    <FormControl className={`input-wrapper ${positionErrors.length > 0 ? "input-wrapper--with-errors" : ""}`}>
                      <InputLabel id="position-label">{placeholderPosition}*</InputLabel>
                      <Select
                        placeholder={placeholderPosition}
                        id="position"
                        name="position"
                        className="input-text mb-3"
                        required
                        fullWidth
                        disableUnderline
                        value={position}
                        onChange={e => setPosition(e.target.value)}
                      >
                        {
                          jobGroups.map((jobGroup) => {
                            return [
                              <ListSubheader key={jobGroup.node.databaseId}>{jobGroup.node.title}</ListSubheader>,
                              jobs.filter(job => job.node.jobGroup.jobGroup.databaseId === jobGroup.node.databaseId).map(job => (
                                <MenuItem value={job.node.databaseId}>{job.node.title}</MenuItem>
                              )),
                            ]
                          })
                        }
                      </Select>
                      <ErrorList className="mb-3" errors={positionErrors} />
                    </FormControl>
                  </div>
                </div>

                <div className="row py-10 py-md-5 px-1">
                  <h2 className="headline p w-35 w-md-12">{labelPersonalData}</h2>
                  <div className="w-45 w-md-21 pt-5 pt-md-0">
                    <div className="row">
                      <div className={`col-12 col-sm-6 pl-0 pr-0 pr-sm-1 input-wrapper ${firstnameErrors.length > 0 ? "input-wrapper--with-errors" : ""}`}>
                        <Input
                          placeholder={`${placeholderFirstname}*`}
                          inputProps={{
                            'aria-label': 'firstname',
                            pattern: "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+(([',. -][a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ])?[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]*)*$"
                          }}
                          id="firstname"
                          name="firstname"
                          className="input-text mb-3"
                          required
                          autoComplete="given-name"
                          fullWidth
                          disableUnderline
                          value={firstname}
                          onChange={e => setFirstname(e.target.value)}
                        />
                        <ErrorList className="mb-3" errors={firstnameErrors} />
                      </div>
                      <div className={`col-12 col-sm-6 pr-0 pl-0 pl-sm-1 input-wrapper ${lastnameErrors.length > 0 ? "input-wrapper--with-errors" : ""}`}>
                        <Input
                          placeholder={`${placeholderLastname}*`}
                          inputProps={{
                            'aria-label': 'lastname',
                            pattern: "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+(([',. -][a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ])?[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]*)*$"
                          }}
                          id="lastname"
                          name="lastname"
                          className="input-text mb-3"
                          required
                          autoComplete="family-name"
                          fullWidth
                          disableUnderline
                          value={lastname}
                          onChange={e => setLastname(e.target.value)}
                        />
                        <ErrorList className="mb-3" errors={lastnameErrors} />
                      </div>
                    </div>
                    <div className={`input-wrapper ${emailAddressErrors.length > 0 ? "input-wrapper--with-errors" : ""}`}>
                      <Input
                        placeholder={`${placeholderEmailAddress}*`}
                        inputProps={{
                          'aria-label': 'emailAddress',
                        }}
                        id="emailAddress"
                        name="emailAddress"
                        type="email"
                        className="input-text mb-3"
                        required
                        autoComplete="email"
                        fullWidth
                        disableUnderline
                        value={emailAddress}
                        onChange={e => setEmailAddress(e.target.value)}
                      />
                      <ErrorList className="mb-3" errors={emailAddressErrors} />
                    </div>
                    <div className={`input-wrapper ${phoneNumberErrors.length > 0 ? "input-wrapper--with-errors" : ""}`}>
                      <Input
                        placeholder={`${placeholderPhoneNumber}*`}
                        inputProps={{
                          'aria-label': 'phoneNumber',
                        }}
                        id="phoneNumber"
                        name="phoneNumber"
                        type="tel"
                        className="input-text mb-3"
                        required
                        autoComplete="tel"
                        fullWidth
                        disableUnderline
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.target.value)}
                      />
                      <ErrorList className="mb-3" errors={phoneNumberErrors} />
                    </div>
                    <div className={`input-wrapper ${mobileNumberErrors.length > 0 ? "input-wrapper--with-errors" : ""}`}>
                      <Input
                        placeholder={placeholderMobileNumber}
                        inputProps={{
                          'aria-label': 'mobileNumber',
                        }}
                        id="mobileNumber"
                        name="mobileNumber"
                        type="tel"
                        className="input-text mb-3"
                        fullWidth
                        disableUnderline
                        value={mobileNumber}
                        onChange={e => setMobileNumber(e.target.value)}
                      />
                      <ErrorList className="mb-3" errors={mobileNumberErrors} />
                    </div>

                    <div className="row">
                      <div className={`col-12 col-sm-6 pl-0 pr-0 pr-sm-1 input-wrapper ${streetErrors.length > 0 ? "input-wrapper--with-errors" : ""}`}>
                        <Input
                          placeholder={`${placeholderStreet}`}
                          inputProps={{
                            'aria-label': 'street',
                          }}
                          id="street"
                          name="street"
                          className="input-text mb-3"
                          autoComplete="address-line1"
                          fullWidth
                          disableUnderline
                          value={street}
                          onChange={e => setStreet(e.target.value)}
                        />
                        <ErrorList className="mb-3" errors={streetErrors} />
                      </div>
                      <div className={`col-12 col-sm-6 pr-0 pl-0 pl-sm-1 input-wrapper ${houseNumberErrors.length > 0 ? "input-wrapper--with-errors" : ""}`}>
                        <Input
                          placeholder={`${placeholderHouseNumber}`}
                          inputProps={{
                            'aria-label': 'houseNumber',
                          }}
                          id="houseNumber"
                          name="houseNumber"
                          className="input-text mb-3"
                          fullWidth
                          disableUnderline
                          value={houseNumber}
                          onChange={e => setHouseNumber(e.target.value)}
                        />
                        <ErrorList className="mb-3" errors={houseNumberErrors} />
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-12 col-sm-6 pl-0 pr-0 pr-sm-1 input-wrapper ${zipErrors.length > 0 ? "input-wrapper--with-errors" : ""}`}>
                        <Input
                          placeholder={`${placeholderZip}*`}
                          inputProps={{
                            'aria-label': 'zip',
                          }}
                          id="zip"
                          name="zip"
                          className="input-text mb-3"
                          required
                          autoComplete="address-line1"
                          fullWidth
                          disableUnderline
                          value={zip}
                          onChange={e => setZip(e.target.value)}
                        />
                        <ErrorList className="mb-3" errors={zipErrors} />
                      </div>
                      <div className={`col-12 col-sm-6 pr-0 pl-0 pl-sm-1 input-wrapper ${cityErrors.length > 0 ? "input-wrapper--with-errors" : ""}`}>
                        <Input
                          placeholder={`${placeholderCity}*`}
                          inputProps={{
                            'aria-label': 'city',
                          }}
                          id="city"
                          name="city"
                          className="input-text mb-3"
                          required
                          fullWidth
                          disableUnderline
                          value={city}
                          onChange={e => setCity(e.target.value)}
                        />
                        <ErrorList className="mb-3" errors={cityErrors} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row py-10 py-md-5 px-1">
                  <h2 className="headline p w-35 w-md-12">{labelApplicationText}</h2>
                  <div className="w-45 w-md-21 pt-5 pt-md-0">
                    <div className={`input-wrapper ${applicationTextErrors.length > 0 ? "input-wrapper--with-errors" : ""}`}>
                      <div className="input-text mb-3">
                        <TextareaAutosize
                          className="w-full"
                          disableUnderline
                          minRows={3}
                          placeholder={placeholderApplicationText}
                          onChange={e => setApplicationText(e.target.value)}
                        />
                      </div>
                      <ErrorList className="mb-3" errors={applicationTextErrors} />
                    </div>
                  </div>
                </div>
                <div className="row py-10 py-md-5 px-1">
                  <h2 className="headline p w-35 w-md-12 d-flex flex-column">
                    <div>{labelDocuments}</div>
                    <div className="document-infos">{documentInfos}</div>
                  </h2>
                  <div className="w-45 w-md-21 pt-5 pt-md-0">
                    <div className="row">
                      <div className={`col-12 col-sm-6 pl-0 pr-0 pr-sm-1 input-wrapper ${cvErrors.length > 0 ? "input-wrapper--with-errors" : ""}`}>
                        <Input
                          inputProps={{
                            'aria-label': 'cv',
                            'data-placeholder': `${cv ? cv.name : `${buttonLabelCv}`}`,
                            accept: ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.txt,.rtf",
                          }}
                          type="file"
                          id="cv"
                          name="cv"
                          className="input-text mb-3"
                          fullWidth
                          disableUnderline
                          onChange={e => setCv(e.target.files[0])}
                        />
                        <ErrorList className="mb-3" errors={cvErrors} />
                      </div>
                      <div className={`col-12 col-sm-6 pr-0 pl-0 pl-sm-1 input-wrapper ${moreDocumentsErrors.length > 0 ? "input-wrapper--with-errors" : ""}`}>
                        <Input
                          inputProps={{
                            'aria-label': 'cv',
                            'data-placeholder': `${moreDocuments ? moreDocuments.name : `${buttonLabelMoreDocuments}`}`,
                            accept: ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.txt,.rtf",
                          }}
                          type="file"
                          id="moreDocument"
                          name="moreDocuments"
                          className="input-text mb-3"
                          fullWidth
                          disableUnderline
                          onChange={e => setMoreDocuments(e.target.files[0])}
                        />
                        <ErrorList className="mb-3" errors={moreDocumentsErrors} />
                      </div>
                    </div>

                    <div className="submit-wrapper d-flex mt-10">
                      <Button
                        text={
                          submitting ?
                            (<svg width="14" height="14" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
                              <defs>
                                <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                                  <stop stopColor="currentColor" stopOpacity="0" offset="0%"/>
                                  <stop stopColor="currentColor" stopOpacity=".631" offset="63.146%"/>
                                  <stop stopColor="currentColor" offset="100%"/>
                                </linearGradient>
                              </defs>
                              <g fill="none" fillRule="evenodd">
                                <g transform="translate(1 1)">
                                  <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" strokeWidth="2">
                                    <animateTransform
                                      attributeName="transform"
                                      type="rotate"
                                      from="0 18 18"
                                      to="360 18 18"
                                      dur="0.9s"
                                      repeatCount="indefinite" />
                                  </path>
                                  <circle fill="currentColor" cx="36" cy="18" r="1">
                                    <animateTransform
                                      attributeName="transform"
                                      type="rotate"
                                      from="0 18 18"
                                      to="360 18 18"
                                      dur="0.9s"
                                      repeatCount="indefinite" />
                                  </circle>
                                </g>
                              </g>
                            </svg>)
                            :
                            buttonLabelApply
                        }
                        type="submit"
                        disabled={submitting}
                        realButton
                        classes="btn-submit btn-border mx-auto w-45 w-md-5"
                      />
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </RemoveClassInview>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`fragment PageJobApplicationFormFragment on WpPage_Flex_Content_JobApplicationForm {
  buttonLabelApply
  buttonLabelCv
  buttonLabelMoreDocuments
  documentInfos
  failureMessage
  headline
  labelDocuments
  labelPersonalData
  labelPosition
  labelApplicationText
  placeholderCity
  placeholderEmailAddress
  placeholderFirstname
  placeholderHouseNumber
  placeholderLastname
  placeholderMobileNumber
  placeholderPhoneNumber
  placeholderPosition
  placeholderStreet
  placeholderZip
  placeholderApplicationText
  successMessageHeadline
  successMessage
  successMessageLinkText
  successMessageLink {
    ... on WpContentNode {
      uri
    }
  }
  successImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
}

fragment PostJobApplicationFormFragment on WpPost_Flex_Content_JobApplicationForm {
  buttonLabelApply
  buttonLabelCv
  buttonLabelMoreDocuments
  documentInfos
  failureMessage
  headline
  labelDocuments
  labelPersonalData
  labelPosition
  labelApplicationText
  placeholderCity
  placeholderEmailAddress
  placeholderFirstname
  placeholderHouseNumber
  placeholderLastname
  placeholderMobileNumber
  placeholderPhoneNumber
  placeholderPosition
  placeholderStreet
  placeholderZip
  placeholderApplicationText
  successMessageHeadline
  successMessage
  successMessageLinkText
  successMessageLink {
    ... on WpContentNode {
      uri
    }
  }
  successImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
}

fragment PressFeatureJobApplicationFormFragment on WpPressFeature_Flex_Content_JobApplicationForm {
  buttonLabelApply
  buttonLabelCv
  buttonLabelMoreDocuments
  documentInfos
  failureMessage
  headline
  labelDocuments
  labelPersonalData
  labelPosition
  labelApplicationText
  placeholderCity
  placeholderEmailAddress
  placeholderFirstname
  placeholderHouseNumber
  placeholderLastname
  placeholderMobileNumber
  placeholderPhoneNumber
  placeholderPosition
  placeholderStreet
  placeholderZip
  placeholderApplicationText
  successMessageHeadline
  successMessage
  successMessageLinkText
  successMessageLink {
    ... on WpContentNode {
      uri
    }
  }
  successImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
}

fragment ProjectJobApplicationFormFragment on WpProject_Flex_Content_JobApplicationForm {
  buttonLabelApply
  buttonLabelCv
  buttonLabelMoreDocuments
  documentInfos
  failureMessage
  headline
  labelDocuments
  labelPersonalData
  labelPosition
  labelApplicationText
  placeholderCity
  placeholderEmailAddress
  placeholderFirstname
  placeholderHouseNumber
  placeholderLastname
  placeholderMobileNumber
  placeholderPhoneNumber
  placeholderPosition
  placeholderStreet
  placeholderZip
  placeholderApplicationText
  successMessageHeadline
  successMessage
  successMessageLinkText
  successMessageLink {
    ... on WpContentNode {
      uri
    }
  }
  successImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
}

fragment CompetenceJobApplicationFormFragment on WpCompetence_Flex_Content_JobApplicationForm {
  buttonLabelApply
  buttonLabelCv
  buttonLabelMoreDocuments
  documentInfos
  failureMessage
  headline
  labelDocuments
  labelPersonalData
  labelPosition
  labelApplicationText
  placeholderCity
  placeholderEmailAddress
  placeholderFirstname
  placeholderHouseNumber
  placeholderLastname
  placeholderMobileNumber
  placeholderPhoneNumber
  placeholderPosition
  placeholderStreet
  placeholderZip
  placeholderApplicationText
  successMessageHeadline
  successMessage
  successMessageLinkText
  successMessageLink {
    ... on WpContentNode {
      uri
    }
  }
  successImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
}

fragment JobJobApplicationFormFragment on WpJob_Flex_Content_JobApplicationForm {
  buttonLabelApply
  buttonLabelCv
  buttonLabelMoreDocuments
  documentInfos
  failureMessage
  headline
  labelDocuments
  labelPersonalData
  labelPosition
  labelApplicationText
  placeholderCity
  placeholderEmailAddress
  placeholderFirstname
  placeholderHouseNumber
  placeholderLastname
  placeholderMobileNumber
  placeholderPhoneNumber
  placeholderPosition
  placeholderStreet
  placeholderZip
  placeholderApplicationText
  successMessageHeadline
  successMessage
  successMessageLinkText
  successMessageLink {
    ... on WpContentNode {
      uri
    }
  }
  successImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
}
`

export default JobApplicationForm;
