/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from 'Accessories/Button/Button.jsx'

const ButtonGroup = ({
  label,
  unselect,
  options,
  onChange
}) => {

  const [ value, setValue ] = useState("")

  const handleSelect = (optionValue) => {
    setValue(optionValue)
  }

  useEffect(() => {
    if(onChange !== null) onChange(value)
  })

  return (
    <ul
      className="d-flex flex-wrap"
      role="listbox"
      aria-label={label}
    >
      <li
        className="ml-1"
        role="option"
        aria-selected={value !== ""}
        onClick={() => handleSelect("", "")}
      >
        <Button
          text={unselect}
          realButton
          classes={classNames("btn-border btn-select", value === "" && "is-active")}
        />
      </li>
      {options.map(option => (
        <li
          className="ml-1"
          role="option"
          aria-selected={value === option.value}
          onClick={() => handleSelect(option.value)}
          key={option.value}
        >
          <Button
            text={option.label}
            realButton
            classes={classNames("btn-border btn-select", value === option.value && "is-active")}
          />
        </li>
      ))}
    </ul>
  )
}

ButtonGroup.defaultProps = {
  onChange: null,
}

ButtonGroup.propTypes = {
  label: PropTypes.string,
  unselect: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape(
    {
      label: PropTypes.string,
      value: PropTypes.string
    }
  )),
  onChange: PropTypes.instanceOf(Function),
}

export default ButtonGroup
