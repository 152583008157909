/* eslint-disable react/no-array-index-key */
import React, {useEffect, useState} from 'react';
import { useGlobal } from "reactn";
import { graphql } from "gatsby";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageTransitionLink from 'Layout/PageTransition/PageTransitionLink.jsx';
import './TeaserSection.scss';
import Teaser from 'Layout/Teaser/Teaser.jsx';
import SectionHeadline from 'Accessories/SectionHeadline/SectionHeadline.jsx';
import {getImage} from "gatsby-plugin-image";

const TeaserSection = props => {
  const {
    headline,
    teaserItems,
    linkTarget,
    internalLink,
    linkText,
    showAllTeaser
  } = props;
  // console.log("teaserItems", teaserItems)

  const [lang] = useGlobal("lang");
  const isLangDE = lang === "de"
  const linkLangString = isLangDE ? "Entdecken" : "Explore"
  const [gridStyles, setGridStyles] = useState(null)
  const [gridItemStyles, setGridItemStyles] = useState(null)

  useEffect(() => {
    const gridStylesIn = classNames(
      teaserItems.length > 3 && "grid-md-4 grid-2",
      teaserItems.length === 3 && "grid-md-3 grid-2",
      teaserItems.length === 2 && "grid-md-2 grid-2",
      teaserItems.length === 1 && "grid-md-3 grid-2",
      "grid-rg-5 grid-xs-1 px-xs-2 px-md-0"
    )
    if(!gridStyles || gridStyles !== gridStylesIn) setGridStyles(gridStylesIn)

    const gridItemStylesIn = classNames(
      teaserItems.length === 1 && "grid-item-md-2 grid-item-1",
    )
    if(!gridItemStyles || gridItemStyles !== gridItemStylesIn) setGridItemStyles(gridItemStylesIn)
  }, [])

  return (
    <div className={classNames("section teaser-section", showAllTeaser && "show-all-teaser")}>
      <div className="container">
        {
          headline && <SectionHeadline headline={headline} />
        }
        <div className={gridStyles}>
          {
            teaserItems && teaserItems.map((post, index) => {
              switch (post.teaserType) {
                case ("employee_teaser"):
                  if(!post.employeeTeaser || post.employeeTeaser.employeeInfo.employee.image == null || post.employeeTeaser.employeeInfo.employee.image.localFile == null) return null
                  return (
                    <Teaser
                      key={`${post.employeeTeaser.databaseId}_${index}`}
                      alignCenter
                      altText={post.employeeTeaser.employeeInfo.employee.image && post.employeeTeaser.employeeInfo.employee.image.altText || post.employeeTeaser.employeeInfo.employee.image && post.employeeTeaser.employeeInfo.employee.image.title}
                      image={getImage(post?.employeeTeaser?.employeeInfo?.employee?.image?.localFile)}
                      previewSrc={{
                        src: post.employeeTeaser.employeeInfo.employee.image.sourceUrl || `${post.employeeTeaser.employeeInfo.employee.image}` || null
                      }}
                      text={post.employeeTeaser.teaserDescription || post.employeeTeaser.employeeInfo.employee.name}
                      linkText={post.employeeTeaser.employeeInfo.employee.email}
                      externalLink={post.employeeTeaser.employeeInfo.employee.email}
                      linkStyle="appear"
                      linkType="email"
                      classes={gridItemStyles}
                    />
                  );
                case ("competence_teaser"):
                  if(!post.competenceTeaser || !post.competenceTeaser.featuredImage || post.competenceTeaser.featuredImage.node == null || post.competenceTeaser.featuredImage.node.localFile == null) return null
                  return (
                    <Teaser
                      key={`${post.competenceTeaser.databaseId}_${index}`}
                      alignCenter
                      altText={post.competenceTeaser.featuredImage.node && post.competenceTeaser.featuredImage.node.altText || post.competenceTeaser.featuredImage.node && post.competenceTeaser.featuredImage.node.title}
                      image={getImage(post?.competenceTeaser?.featuredImage?.node?.localFile)}
                      previewSrc={{
                        src: post.competenceTeaser.featuredImage.node.sourceUrl || `${post.competenceTeaser.featuredImage.node}` || null
                      }}
                      text={post.competenceTeaser.teaserDescription || post.competenceTeaser.title}
                      linkText={linkLangString}
                      internalLink={post.competenceTeaser.uri}
                      classes={gridItemStyles}
                    />
                  );
                case ("project_teaser"):
                  if(!post.projectTeaser || post.projectTeaser.postImages.postImages.landscapeImage == null || post.projectTeaser.postImages.postImages.portaitImage == null || post.projectTeaser.postImages.postImages.landscapeImage.localFile == null || post.projectTeaser.postImages.postImages.portaitImage.localFile == null) return null
                  return (
                    <Teaser
                      key={`${post.projectTeaser.databaseId}_${index}`}
                      alignCenter
                      altText={index % 2 === 1 ? post.projectTeaser.postImages.postImages.landscapeImage.altText || post.projectTeaser.postImages.postImages.landscapeImage.title : post.projectTeaser.postImages.postImages.portaitImage.altText || post.projectTeaser.postImages.postImages.portaitImage.title}
                      image={index % 2 === 1 ? getImage(post?.projectTeaser?.postImages?.postImages?.landscapeImage?.localFile) : getImage(post?.projectTeaser?.postImages?.postImages?.portaitImage?.localFile)}
                      previewSrc={{
                        src: index % 2 === 1 ? post.projectTeaser.postImages.postImages.landscapeImage.sourceUrl || `${post.projectTeaser.postImages.postImages.landscapeImage}` : post.projectTeaser.postImages.postImages.portaitImage.sourceUrl || `${post.projectTeaser.postImages.postImages.portaitImage}`
                      }}
                      text={post.projectTeaser.teaserDescription || post.projectTeaser.title}
                      linkText={linkLangString}
                      internalLink={post.projectTeaser.uri}
                      classes={gridItemStyles}
                    />
                  );
                  case ("post_teaser"):
                    if(!post.postTeaser || !post.postTeaser.featuredImage || post.postTeaser.featuredImage.node == null || post.postTeaser.featuredImage.node.localFile == null) return null
                    return (
                      <Teaser
                        key={`${post.postTeaser.databaseId}_${index}`}
                        alignCenter
                        altText={post.postTeaser.featuredImage.node && post.postTeaser.featuredImage.node.altText || post.postTeaser.featuredImage.node && post.postTeaser.featuredImage.node.title}
                        image={getImage(post?.postTeaser?.featuredImage?.node?.localFile)}
                        previewSrc={{
                          src: post.postTeaser.featuredImage.node.sourceUrl || `${post.postTeaser.featuredImage.node}` || null
                        }}
                        text={post.postTeaser.teaserDescription || post.postTeaser.title}
                        linkText={linkLangString}
                        internalLink={post.postTeaser.uri}
                        classes={gridItemStyles}
                      />
                    );
                  case ("press_teaser"):
                    if(!post.pressTeaser || !post.pressTeaser.featuredImage || post.pressTeaser.featuredImage.node == null || post.pressTeaser.featuredImage.node.localFile == null) return null
                    return (
                      <Teaser
                        key={`${post.pressTeaser.databaseId}_${index}`}
                        alignCenter
                        altText={post.pressTeaser.featuredImage.node && post.pressTeaser.featuredImage.node.altText || post.pressTeaser.featuredImage.node && post.pressTeaser.featuredImage.node.title}
                        image={getImage(post?.pressTeaser?.featuredImage?.node?.localFile)}
                        previewSrc={{
                          src: post.pressTeaser.featuredImage.node.sourceUrl || `${post.pressTeaser.featuredImage.node}` || null
                        }}
                        text={post.pressTeaser.teaserDescription || post.pressTeaser.title}
                        linkText={linkLangString}
                        internalLink={post.pressTeaser.uri}
                        classes={gridItemStyles}
                      />
                    );
                  default:
                    return null
              }
            })
          }
        </div>
        <div className="row justify-content-center mt-5 mt-md-10">
          {
            linkTarget && linkText && (
              <PageTransitionLink
                externalLink={linkTarget}
                internalLink={internalLink && internalLink.uri}
                className="text-link"
              >
                {linkText}
              </PageTransitionLink>
            )
          }
        </div>
      </div>
    </div>
  );
};

TeaserSection.defaultProps= {
  showAllTeaser: false,
}

TeaserSection.propTypes = {
  headline: PropTypes.string,
  teaserItems: PropTypes.instanceOf(Array),
  showAllTeaser: PropTypes.bool,
};

export const query = graphql`fragment PageTeaserSectionFragment on WpPage_Flex_Content_TeaserSection {
  headline
  linkTarget
  internalLink {
    ... on WpContentNode {
      uri
    }
  }
  linkText
  teaserItems {
    teaserType
    competenceTeaser {
      ... on WpCompetence {
        internal {
          type
        }
        language {
          slug
        }
        databaseId
        uri
        title
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    projectTeaser {
      ... on WpProject {
        internal {
          type
        }
        language {
          slug
        }
        databaseId
        uri
        title
        postImages {
          postImages {
            landscapeImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
            portaitImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    employeeTeaser {
      ... on WpEmployee {
        databaseId
        internal {
          type
        }
        language {
          slug
        }
        uri
        employeeInfo {
          employee {
            name
            email
            competence {
              ... on WpCompetence {
                databaseId
              }
            }
            image {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    postTeaser {
      ... on WpPost {
        internal {
          type
        }
        language {
          slug
        }
        title
        uri
        databaseId
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    pressTeaser {
      ... on WpPressFeature {
        internal {
          type
        }
        language {
          slug
        }
        title
        uri
        databaseId
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    teaserDescription
  }
}

fragment PostTeaserSectionFragment on WpPost_Flex_Content_TeaserSection {
  headline
  linkTarget
  internalLink {
    ... on WpContentNode {
      uri
    }
  }
  linkText
  teaserItems {
    teaserType
    competenceTeaser {
      ... on WpCompetence {
        internal {
          type
        }
        language {
          slug
        }
        databaseId
        uri
        title
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    projectTeaser {
      ... on WpProject {
        internal {
          type
        }
        language {
          slug
        }
        databaseId
        uri
        title
        postImages {
          postImages {
            landscapeImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
            portaitImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    employeeTeaser {
      ... on WpEmployee {
        databaseId
        internal {
          type
        }
        language {
          slug
        }
        uri
        employeeInfo {
          employee {
            name
            email
            competence {
              ... on WpCompetence {
                databaseId
              }
            }
            image {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    postTeaser {
      ... on WpPost {
        internal {
          type
        }
        language {
          slug
        }
        title
        uri
        databaseId
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    pressTeaser {
      ... on WpPressFeature {
        internal {
          type
        }
        language {
          slug
        }
        title
        uri
        databaseId
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    teaserDescription
  }
}

fragment PressFeatureTeaserSectionFragment on WpPressFeature_Flex_Content_TeaserSection {
  headline
  linkTarget
  internalLink {
    ... on WpContentNode {
      uri
    }
  }
  linkText
  teaserItems {
    teaserType
    competenceTeaser {
      ... on WpCompetence {
        internal {
          type
        }
        language {
          slug
        }
        databaseId
        uri
        title
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    projectTeaser {
      ... on WpProject {
        internal {
          type
        }
        language {
          slug
        }
        databaseId
        uri
        title
        postImages {
          postImages {
            landscapeImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
            portaitImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    employeeTeaser {
      ... on WpEmployee {
        databaseId
        internal {
          type
        }
        language {
          slug
        }
        uri
        employeeInfo {
          employee {
            name
            email
            competence {
              ... on WpCompetence {
                databaseId
              }
            }
            image {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    postTeaser {
      ... on WpPost {
        internal {
          type
        }
        language {
          slug
        }
        title
        uri
        databaseId
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    pressTeaser {
      ... on WpPressFeature {
        internal {
          type
        }
        language {
          slug
        }
        title
        uri
        databaseId
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    teaserDescription
  }
}

fragment ProjectTeaserSectionFragment on WpProject_Flex_Content_TeaserSection {
  headline
  linkTarget
  internalLink {
    ... on WpContentNode {
      uri
    }
  }
  linkText
  teaserItems {
    teaserType
    competenceTeaser {
      ... on WpCompetence {
        internal {
          type
        }
        language {
          slug
        }
        databaseId
        uri
        title
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    projectTeaser {
      ... on WpProject {
        internal {
          type
        }
        language {
          slug
        }
        databaseId
        uri
        title
        postImages {
          postImages {
            landscapeImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
            portaitImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    employeeTeaser {
      ... on WpEmployee {
        databaseId
        internal {
          type
        }
        language {
          slug
        }
        uri
        employeeInfo {
          employee {
            name
            email
            competence {
              ... on WpCompetence {
                databaseId
              }
            }
            image {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    postTeaser {
      ... on WpPost {
        internal {
          type
        }
        language {
          slug
        }
        title
        uri
        databaseId
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    pressTeaser {
      ... on WpPressFeature {
        internal {
          type
        }
        language {
          slug
        }
        title
        uri
        databaseId
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    teaserDescription
  }
}

fragment CompetenceTeaserSectionFragment on WpCompetence_Flex_Content_TeaserSection {
  headline
  linkTarget
  internalLink {
    ... on WpContentNode {
      uri
    }
  }
  linkText
  teaserItems {
    teaserType
    competenceTeaser {
      ... on WpCompetence {
        internal {
          type
        }
        language {
          slug
        }
        databaseId
        uri
        title
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    projectTeaser {
      ... on WpProject {
        internal {
          type
        }
        language {
          slug
        }
        databaseId
        uri
        title
        postImages {
          postImages {
            landscapeImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
            portaitImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    employeeTeaser {
      ... on WpEmployee {
        databaseId
        internal {
          type
        }
        language {
          slug
        }
        uri
        employeeInfo {
          employee {
            name
            email
            competence {
              ... on WpCompetence {
                databaseId
              }
            }
            image {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    postTeaser {
      ... on WpPost {
        internal {
          type
        }
        language {
          slug
        }
        title
        uri
        databaseId
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    pressTeaser {
      ... on WpPressFeature {
        internal {
          type
        }
        language {
          slug
        }
        title
        uri
        databaseId
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    teaserDescription
  }
}

fragment JobTeaserSectionFragment on WpJob_Flex_Content_TeaserSection {
  headline
  linkTarget
  internalLink {
    ... on WpContentNode {
      uri
    }
  }
  linkText
  teaserItems {
    teaserType
    competenceTeaser {
      ... on WpCompetence {
        internal {
          type
        }
        language {
          slug
        }
        databaseId
        uri
        title
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    projectTeaser {
      ... on WpProject {
        internal {
          type
        }
        language {
          slug
        }
        databaseId
        uri
        title
        postImages {
          postImages {
            landscapeImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
            portaitImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    employeeTeaser {
      ... on WpEmployee {
        databaseId
        internal {
          type
        }
        language {
          name
        }
        uri
        employeeInfo {
          employee {
            name
            email
            competence {
              ... on WpCompetence {
                databaseId
              }
            }
            image {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    postTeaser {
      ... on WpPost {
        internal {
          type
        }
        language {
          slug
        }
        title
        uri
        databaseId
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    pressTeaser {
      ... on WpPressFeature {
        internal {
          type
        }
        language {
          slug
        }
        title
        uri
        databaseId
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    teaserDescription
  }
}
`

export default TeaserSection;
