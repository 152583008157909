/* eslint-disable no-unused-vars */
import React from 'react';
import { useGlobal } from "reactn";
import PropTypes from 'prop-types';
import classNames from "classnames";
import PageTransitionLink from 'Layout/PageTransition/PageTransitionLink.jsx';
import ImageContainer from "Accessories/ImageContainer/ImageContainer.jsx";
import RemoveClassInview from "Accessories/RemoveClassInview/RemoveClassInview.jsx";
import TeaserDescription from './TeaserDescription/TeaserDescription';

import './Teaser.scss';

const Teaser = props => {

  const {
    image,
    altText,
    previewSrc,
    cropImage,
    aspectRatios,
    headline,
    text,
    subText,
    subTextIsHeadline,
    linkText,
    linkType,
    linkStyle,
    href,
    internalLink,
    externalLink,
    linkSecondaryTarget,
    alignCenter,
    classes
  } = props
  // console.log("subTextIsHeadline", subTextIsHeadline)

  const imageContainer = (() => (
    <ImageContainer
      image={image}
      altText={altText}
      cropImage={cropImage}
      aspectRatios={aspectRatios}
      classes="mb-1"
      previewSrc={{
        src: previewSrc.src
      }}
    />
  ))()

  const content = (() => (
    <>
      {subText && subTextIsHeadline && (<h3 className="sub-text-headline d-block text-center" dangerouslySetInnerHTML={{__html: subText}} />)}
      {imageContainer}
      <TeaserDescription
        headline={headline}
        text={text}
        subText={subText}
        subTextIsHeadline={subTextIsHeadline}
        linkText={linkText}
        linkTarget={internalLink}
        linkSecondaryTarget={linkSecondaryTarget}
        alignCenter={alignCenter}
        linkStyle={linkStyle}
      />
    </>
  ))()

  return (
    <RemoveClassInview>
      <figure className={classNames("teaser", linkType === "email" && "teaser-has-mail", linkStyle === "appear" && "link-style-appear", classes)}>
        {linkType === "internal" && (internalLink || externalLink) && (
          <PageTransitionLink
            internalLink={internalLink}
            externalLink={externalLink}
          >
            {content}
          </PageTransitionLink>
        )}
        {linkType === "internal" && (!internalLink && !externalLink) && content }
        {linkType === "email" && (
          <>
            {content}
          </>
        )}
      </figure>
    </RemoveClassInview>
  );
};

Teaser.defaultProps = {
  linkType: "internal",
  cropImage: true,
  alignCenter: false,
  subTextIsHeadline: false
};

Teaser.propTypes = {
  image: PropTypes.instanceOf(Object),
  previewSrc: PropTypes.instanceOf(Object).isRequired,
  cropImage: PropTypes.bool,
  aspectRatios: PropTypes.instanceOf(Array),
  headline: PropTypes.string,
  text: PropTypes.string,
  subText: PropTypes.string,
  subTextIsHeadline: PropTypes.bool,
  linkText: PropTypes.string,
  linkType: PropTypes.string,
  linkStyle: PropTypes.string,
  href: PropTypes.string,
  alignCenter: PropTypes.bool,
  classes: PropTypes.string,
};

export default Teaser;
