import React from 'react';
import { graphql } from "gatsby";
import PropTypes from 'prop-types';
import SectionHeadline from 'Accessories/SectionHeadline/SectionHeadline.jsx';
import RemoveClassInview from "Accessories/RemoveClassInview/RemoveClassInview.jsx";

import './TextItemsColumnsSection.scss';

const TextItemsColumnsSection = props => {

  // eslint-disable-next-line no-unused-vars
  const { headline, items } = props

  return (
    <div className="section text-items-columns-section">
      <div className="container">
        {
          headline && (
            <SectionHeadline
              headline={headline}
            />
          )
        }
        <div className="grid-2 grid-lg-4 px-3 grid-cg-3 grid-rg-15">
          {
            items && items.map(item => (
              <RemoveClassInview
                key={item.textItemHeadline || item.text}
              >
                <div className="text-column-item">
                  {
                    item.textItemHeadline && <h3 className="mb-1">{item.textItemHeadline}</h3>
                  }
                  {
                    item.text && <div dangerouslySetInnerHTML={{ __html: item.text}} />
                  }
                </div>
              </RemoveClassInview>
            ))
          }
        </div>
      </div>
    </div>
  );
};

TextItemsColumnsSection.propTypes = {
  headline: PropTypes.string,
  items: PropTypes.instanceOf(Array),
};

export const query = graphql`
  fragment PageTextItemsColumnsSectionFragment on WpPage_Flex_Content_TextItemsColumnsSection {
    headline
    textItems {
      textItemHeadline
      text
    }
  }
  fragment PostTextItemsColumnsSectionFragment on WpPost_Flex_Content_TextItemsColumnsSection {
    headline
    textItems {
      textItemHeadline
      text
    }
  }
  fragment PressFeatureTextItemsColumnsSectionFragment on WpPressFeature_Flex_Content_TextItemsColumnsSection {
    headline
    textItems {
      textItemHeadline
      text
    }
  }
  fragment ProjectTextItemsColumnsSectionFragment on WpProject_Flex_Content_TextItemsColumnsSection {
    headline
    textItems {
      textItemHeadline
      text
    }
  }
  fragment CompetenceTextItemsColumnsSectionFragment on WpCompetence_Flex_Content_TextItemsColumnsSection {
    headline
    textItems {
      textItemHeadline
      text
    }
  }
  fragment JobTextItemsColumnsSectionFragment on WpJob_Flex_Content_TextItemsColumnsSection {
    headline
    textItems {
      textItemHeadline
      text
    }
  }
`

export default TextItemsColumnsSection;
