import React, {useEffect} from 'react';
import { useGlobal } from "reactn";
import PropTypes from "prop-types";
import Select from 'Accessories/Input/Select/Select.jsx'
import Search from 'Accessories/Input/Search/Search.jsx'

const TeamFilter = (props) => {

  const [lang] = useGlobal("lang");
  const isLangDE = lang === "de"

  const {
    departmentOptions,
    changeHandlerDepartment,
    department,
    searchItems,
    searchPlaceholderTextDe,
    searchPlaceholderTextEn
  } = props

  const capFirstChar = string => (string[0].toUpperCase() + string.slice(1))

  useEffect(() => {
  }, [department])

  return (
    <div className="filter-wrapper d-flex flex-column flex-md-row mb-5 ml-lg-1">
      <Select
        label={isLangDE ? "Abteilung" : "Department"}
        unselect={isLangDE ? "Alle" : "All"}
        options={departmentOptions.map(o => ({label: capFirstChar(o), value: o}))}
        onChange={changeHandlerDepartment}
        className="eham-select pl-0"
      />
      <Search
        placeholder={isLangDE ? searchPlaceholderTextDe : searchPlaceholderTextEn}
        onChange={searchItems}
        className="eham-search"
      />
    </div>
  )
}

TeamFilter.propTypes = {
  searchPlaceholderTextDe: PropTypes.string,
  searchPlaceholderTextEn: PropTypes.string
};

TeamFilter.defaultProps = {
  searchPlaceholderTextDe: "Suchbegriff eingeben",
  searchPlaceholderTextEn: "Search"
};

export default TeamFilter