/* eslint-disable no-console */
/* eslint-disable no-fallthrough */
const log = (type, message) => {
  const styleWaring = "background: #000; color: #bada55";

  switch (type) {
    case "warning":
      console.log(`%c${message}`, styleWaring);
    default:
      console.log(`%c${message}`, styleWaring);
  }
};

module.exports = { log };
