/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import classNames from "classnames"
import Swiper from "react-id-swiper"
import Button from "Accessories/Button/Button.jsx"
import RemoveClassInview from "Accessories/RemoveClassInview/RemoveClassInview.jsx"
import SectionHeadline from "Accessories/SectionHeadline/SectionHeadline.jsx"
import TeaserDescription from "Layout/Teaser/TeaserDescription/TeaserDescription.jsx"
import ImageContainer from "Accessories/ImageContainer/ImageContainer.jsx"
import "./ProgressSliderSection.scss"
import {getImage, getSrc} from "gatsby-plugin-image";

const ProgressSliderSection = ({
    headline,
    teaserImage,
    teaserHeadline,
    teaserText,
    teaserLink,
    teaserInternalLink,
    teaserLinkText,
    slides
  }) => {
  const [swiper, updateSwiper] = useState(null)
  const mySwiperRef = useRef(null)

  if(!slides || !slides.length) return null

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const setPagination = () => {
    const swiperPagination = mySwiperRef.current.querySelector(".swiper-pagination")

    const imageContainer = mySwiperRef.current.querySelector(".image-container")
    swiperPagination.style.top = `${imageContainer.clientHeight}px`
  }

  const swiperParams = {
    slidesPerView: "auto",
    grabCursor: false,
    lazy: true,
    loop: true,
    loopedSlides: slides.length * 2,
    parallax: true,
    speed: 1200,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    on: {
      resize: () => setPagination(),
    },
  }

  useEffect(() => {
    let timeout

    if (swiper !== null && slides.length > 0) {
      timeout = setTimeout(() => {
        setPagination()
        mySwiperRef.current.classList.add("show-swiper")
        swiper.update()
      }, 500)
    }
    return () => {
      clearTimeout(timeout)
    }
  },[swiper])

  useEffect(() => {
    let timeout;

    // This is a hacky workaround as the legacy version of Swiper used in this project does not work properly
    // with React
    if (swiper && swiper.params.loopedSlides) {
      timeout = setTimeout(() => {
        swiper.loopDestroy();
        swiper.loopCreate();
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [swiper]);

  const classConstructor = (item) => {
    let classes;
    if(item.image && item.image.localFile){
      const aspect = Math.floor(item.image.mediaDetails.width/item.image.mediaDetails.height*100)/100
      if(aspect > 1) { classes = "w-md-20 w-46" } else { classes = "w-md-10 w-25" }
    } else {
      classes = "w-md-20 w-46"
    }
    return classes;
  }

  const renderFigures = items => (
    items.map((item, index) => {
      if(item.image) return (
        <figure
          className={classNames(
            classConstructor(item),
            "mr-1 slide-wrapper "
          )}
          key={getSrc(item.image.localFile) || index}
        >
          <ImageContainer
            altText={item && item.image.altText || item && item.image.title}
            image={getImage(item?.image?.localFile)}
            previewSrc={{
              src: item.image && item.image.sourceUrl || typeof item.image.url === "string" && item.image.url || null
            }}
            useStandardImage
            aspectRatios={[1.335, 0.666]}
            classes="mb-1"
            dataSwiperParallax="10%"
            dataSwiperParallaxScale="1.2"
          />
          {/* Removing empty figcaption will make pagination disappear */}
          <figcaption className="pr-1" />
        </figure>
      );
      return null
    })
  )

  return (
    <div ref={mySwiperRef} className="section progress-slider-section grid-17-20 pb-20">
      <div className="container">
        { headline &&
          <SectionHeadline headline={headline} />
        }
        <div className="row">
          <RemoveClassInview>
            <div className="text-side w-md-12 w-40">
              <div className="ml-1 mt-md-0 mt-2 d-flex align-self-end">
                <TeaserDescription
                  headline={teaserHeadline}
                  text={teaserText}
                  linkText={teaserLinkText}
                  externalLink={teaserLink}
                  internalLink={teaserInternalLink && teaserInternalLink.uri}
                />
              </div>
            </div>
          </RemoveClassInview>
          <div onClick={goNext} className="image-side ml-auto w-46 w-md-31">
            <RemoveClassInview>
              { teaserImage && (
                <div className="teaser-image w-md-10 w-46 pb-1 pb-md-0">
                  <ImageContainer
                    altText={teaserImage.altText || teaserImage.title}
                    image={getImage(teaserImage?.localFile)}
                    previewSrc={{
                      src: teaserImage && teaserImage.sourceUrl || typeof teaserImage === "string" && teaserImage || null
                    }}
                    aspectRatios={[1.335]}
                  />
                </div>
              )}
            </RemoveClassInview>
            {slides && (
              <RemoveClassInview>
                <div className={classNames(slides.length > 1 && "swiper-element", "w-46 w-md-20 ml-auto")}>
                  { slides.length > 1 ? (
                    <>
                      <Button
                        icon="arrow"
                        classes="bg-dark-gray-translucent btn-circle"
                        onClick={goNext}
                      />
                      <Swiper {...swiperParams} getSwiper={updateSwiper}>
                        {
                          renderFigures(slides)
                        }
                      </Swiper>
                    </>
                    ) : (
                      renderFigures(slides)
                    )
                  }
                </div>
              </RemoveClassInview>
            )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

ProgressSliderSection.propTypes = {
  headline: PropTypes.string,
  teaserImage: PropTypes.instanceOf(Object),
  teaserText: PropTypes.string,
  teaserLink: PropTypes.string,
  teaserLinkText: PropTypes.string,
  slides: PropTypes.instanceOf(Array),
}

export const query = graphql`fragment PageProgressSliderSectionFragement on WpPage_Flex_Content_ProgressSliderSection {
  headline
  teaserHeadline
  teaserText
  teaserLink
  teaserInternalLink {
    ... on WpContentNode {
      uri
    }
  }
  teaserLinkText
  teaserBild {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
  slides {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment PostProgressSliderSectionFragement on WpPost_Flex_Content_ProgressSliderSection {
  headline
  teaserHeadline
  teaserText
  teaserLink
  teaserInternalLink {
    ... on WpContentNode {
      uri
    }
  }
  teaserLinkText
  teaserBild {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
  slides {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment PressFeatureProgressSliderSectionFragement on WpPressFeature_Flex_Content_ProgressSliderSection {
  headline
  teaserHeadline
  teaserText
  teaserLink
  teaserInternalLink {
    ... on WpContentNode {
      uri
    }
  }
  teaserLinkText
  teaserBild {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
  slides {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment ProjectProgressSliderSectionFragement on WpProject_Flex_Content_ProgressSliderSection {
  headline
  teaserHeadline
  teaserText
  teaserLink
  teaserInternalLink {
    ... on WpContentNode {
      uri
    }
  }
  teaserLinkText
  teaserBild {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
  slides {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment CompetenceProgressSliderSectionFragement on WpCompetence_Flex_Content_ProgressSliderSection {
  headline
  teaserHeadline
  teaserText
  teaserLink
  teaserInternalLink {
    ... on WpContentNode {
      uri
    }
  }
  teaserLinkText
  teaserBild {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
  slides {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment JobProgressSliderSectionFragement on WpJob_Flex_Content_ProgressSliderSection {
  headline
  teaserHeadline
  teaserText
  teaserLink
  teaserInternalLink {
    ... on WpContentNode {
      uri
    }
  }
  teaserLinkText
  teaserBild {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
  slides {
    image {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`

export default ProgressSliderSection
