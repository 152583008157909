/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useGlobal } from "reactn";
import {getImage, getSrc} from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import PageTransitionLink from 'Layout/PageTransition/PageTransitionLink.jsx';
import PropTypes from 'prop-types';
import RemoveClassInview from "Accessories/RemoveClassInview/RemoveClassInview.jsx";
import SectionHeadline from 'Accessories/SectionHeadline/SectionHeadline.jsx';
import ImageContainer from "Accessories/ImageContainer/ImageContainer.jsx"
import Icon from "../../Accessories/Icon/Icon"

import './JobOverviewSection.scss';

const JobOverviewSection = props => {

  const { headline } = props

  const [lang] = useGlobal("lang");

  const {allWpJob, allWpJobGroup} = useStaticQuery(graphql`
    query JobsQuery {
      allWpJobGroup(sort: {fields: menuOrder, order: ASC}) {
        edges {
          node {
            databaseId
            title
            language {
              slug
            }
            featuredImage {
              node {
                sourceUrl
                mediaDetails {
                  height
                  width
                }
                altText
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
      allWpJob(filter: {title: {ne: "DO NOT DELETE"}}, sort: {fields: menuOrder, order: ASC}) {
        edges {
          node {
            databaseId
            uri
            language {
              slug
            }
            title
            content
            excerpt
            jobGroup {
              hide
              jobGroup {
                ... on WpJobGroup {
                  id
                  databaseId
                }
              }
            }
          }
        }
      }
    }
  `)

  const jobs = allWpJob.edges
    .filter(job => job.node.language.slug === lang)
    .filter(job => job.node.jobGroup.hide !== true)

  const jobGroups = allWpJobGroup.edges
    .filter(jobGroup => jobGroup.node.language.slug === lang)
    .filter(jobGroup => jobs.some(job => job.node.jobGroup.jobGroup.databaseId === jobGroup.node.databaseId))

  return (
    <div className="section jobs-overview-section px-1 px-md-0">
      <div className="container">
        {
          headline && (
            <SectionHeadline
              headline={headline}
            />
          )
        }
        {
          jobGroups.length && (
            <RemoveClassInview>
              <ol className="job-group__list grid-1 grid-md-3 px-1">
                {
                  jobGroups.map((jobGroup, index) => (
                    <li key={jobGroup.node.databaseId} className={index > 0 ? "mt-12 mt-md-0" : ""}>
                      <div>
                        <ImageContainer
                          altText={jobGroup.node?.featuredImage?.node?.altText || jobGroup.node?.featuredImage?.node?.title}
                          image={getImage(jobGroup.node?.featuredImage?.node?.localFile)}
                          aspectRatios={[300/440]}
                          useStandardImage
                        />
                        <h3 className="py-2">
                          {jobGroup.node.title}
                        </h3>
                        <ol className="job-teaser__list">
                          {
                            jobs.filter(job => job.node.jobGroup.jobGroup.databaseId === jobGroup.node.databaseId).map(job => (
                              <li key={job.node.databaseId} className="job-teaser__item">
                                <PageTransitionLink
                                  internalLink={job.node.uri}
                                  className="job-teaser__link"
                                >
                                  <div className="job-teaser__title">{job.node.title}</div>
                                  <div className="job-teaser__icon">
                                    <Icon iconType="chevron" rotation="270deg" />
                                  </div>
                                </PageTransitionLink>
                              </li>
                            ))
                          }
                        </ol>
                      </div>
                    </li>
                  ))
                }
              </ol>
            </RemoveClassInview>
          )
        }
      </div>
    </div>
  );
};

JobOverviewSection.propTypes = {
  headline: PropTypes.string,
};

export const query = graphql`
  fragment PageJobOverviewSectionFragment on WpPage_Flex_Content_JobOverviewSection {
    headline
  }
  fragment PostJobOverviewSectionFragment on WpPost_Flex_Content_JobOverviewSection {
    headline
  }
  fragment PressFeatureJobOverviewSectionFragment on WpPressFeature_Flex_Content_JobOverviewSection {
    headline
  }
  fragment ProjectJobOverviewSectionFragment on WpProject_Flex_Content_JobOverviewSection {
    headline
  }
  fragment CompetenceJobOverviewSectionFragment on WpCompetence_Flex_Content_JobOverviewSection {
    headline
  }
  fragment JobJobOverviewSectionFragment on WpJob_Flex_Content_JobOverviewSection {
    headline
  }
`

export default JobOverviewSection;
