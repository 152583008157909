/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-sequences */
/* eslint-disable func-names */
import React from "react"
import {graphql} from "gatsby"
import SectionHeadline from "Accessories/SectionHeadline/SectionHeadline.jsx"
import classNames from "classnames"
import Teaser from "Layout/Teaser/Teaser.jsx"
import "./ContactPersonsSection.scss"
import {getImage} from "gatsby-plugin-image";
// import { image } from "../../../../node_modules/image-q/dist/iq"

const ContactPersonsSection = ({ headline, subHeadline, contactPersons }) => {
  return (
    <div className="section contact-persons-section">
      <div className="container">
        <SectionHeadline headline={headline || ""} copy={subHeadline || ""} />
        <div
          className={
            classNames(
              `row`,
              contactPersons?.length > 2 && `justify-content-between` || `justify-content-around`,
            )
          }
        >
          {
            contactPersons?.length > 0 && contactPersons.map((contact, i) => contact.contactPerson && (
              <div
                key={contact.contactPerson.id}
                className="teaser-container w-23 w-md-11"
              >
                <Teaser
                  classes="mb-15 mb-md-0"
                  alignCenter
                  altText={contact?.contactPerson?.employeeInfo?.employee?.image?.altText || contact?.contactPerson?.employeeInfo?.employee?.image?.title}
                  image={getImage(contact?.contactPerson?.employeeInfo?.employee?.image?.localFile)}
                  previewSrc={{src: contact?.contactPerson?.employeeInfo?.employee?.image?.sourceUrl || contact?.contactPerson?.employeeInfo?.employee?.image || null}}
                  text={contact?.contactPerson?.employeeInfo?.employee?.name || contact?.employeeInfo?.employee?.name}
                  subText={(contactPersons[i].competenceField) || (contact?.contactPerson?.employeeInfo?.employee?.function) || (contact?.employeeInfo?.employee?.function) || ""}
                  subTextIsHeadline
                  linkText={contact?.contactPerson?.employeeInfo?.employee?.email || contact?.employeeInfo?.employee?.email}
                  externalLink={contact?.contactPerson?.employeeInfo?.employee?.email || contact?.employeeInfo?.employee?.email}
                  linkSecondaryTarget={contact?.contactPerson?.employeeInfo?.employee?.phone || contact?.employeeInfo?.employee?.phone}
                  linkType="email"
                  linkStyle="appear"
                />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export const query = graphql`fragment PageContactPersonsSectionFragment on WpPage_Flex_Content_ContactPersonsSection {
  headline
  subHeadline
  contactPersons {
    competenceField
    contactPerson {
      ... on WpEmployee {
        id
        employeeInfo {
          employee {
            name
            email
            phone
            competence {
              ... on WpCompetence {
                databaseId
              }
            }
            function
            image {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
}

fragment PostContactPersonsSectionFragment on WpPost_Flex_Content_ContactPersonsSection {
  headline
  subHeadline
  contactPersons {
    competenceField
    contactPerson {
      ... on WpEmployee {
        id
        employeeInfo {
          employee {
            name
            email
            phone
            competence {
              ... on WpCompetence {
                databaseId
              }
            }
            function
            image {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
}

fragment PressFeatureContactPersonsSectionFragment on WpPressFeature_Flex_Content_ContactPersonsSection {
  headline
  subHeadline
  contactPersons {
    competenceField
    contactPerson {
      ... on WpEmployee {
        id
        employeeInfo {
          employee {
            name
            email
            phone
            competence {
              ... on WpCompetence {
                databaseId
              }
            }
            function
            image {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
}

fragment ProjectContactPersonsSectionFragment on WpProject_Flex_Content_ContactPersonsSection {
  headline
  subHeadline
  contactPersons {
    competenceField
    contactPerson {
      ... on WpEmployee {
        id
        employeeInfo {
          employee {
            name
            email
            phone
            competence {
              ... on WpCompetence {
                databaseId
              }
            }
            function
            image {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
}

fragment CompetenceContactPersonsSectionFragment on WpCompetence_Flex_Content_ContactPersonsSection {
  headline
  subHeadline
  contactPersons {
    competenceField
    contactPerson {
      ... on WpEmployee {
        id
        employeeInfo {
          employee {
            name
            email
            phone
            competence {
              ... on WpCompetence {
                databaseId
              }
            }
            function
            image {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
}

fragment JobContactPersonsSectionFragment on WpJob_Flex_Content_ContactPersonsSection {
  headline
  subHeadline
  contactPersons {
    competenceField
    contactPerson {
      ... on WpEmployee {
        id
        employeeInfo {
          employee {
            name
            email
            phone
            competence {
              ... on WpCompetence {
                databaseId
              }
            }
            function
            image {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default ContactPersonsSection
