/* eslint-disable jsx-a11y/no-distracting-elements */
import React from 'react'
import {useState, useEffect, useGlobal} from 'reactn'
import {graphql, useStaticQuery} from 'gatsby'
import classNames from 'classnames'
import config from 'data/SiteConfig'
import PageTransitionLink from 'Layout/PageTransition/PageTransitionLink.jsx'
import {ReactComponent as MenuIconSvg} from 'static/assets/svg/icons/menu.svg'
import {ReactComponent as MenuLogoSvg} from 'static/assets/svg/icons/logo_normal_menu.svg'
import NavigationOverlay from 'Layout/Navigation/NavigationOverlay/NavigationOverlay.jsx'
import Headroom from 'react-headroom'

import './MainNavigation.scss'

const MainNavigation = (props) => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      menus: allWpMenu {
        edges {
          node {
            name
            menuItems {
              nodes {
                url
                label
                parentId
                childItems {
                  nodes {
                    label
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [lang] = useGlobal("lang")
  const isLangDE = lang === "de" || !lang

  const { location, translations } = props
  const { menus } = data

  const mainMenu = isLangDE ? menus.edges.filter(item => item.node.name === "Main Menu - DE") : menus.edges.filter(item => item.node.name === "Main Menu - EN")
  // console.log("MainNavigation -> mainMenu", mainMenu)

  const [menuOverlay, setMenuOverlay] = useState(false)
  const initMainNavHeight = typeof window !== 'undefined' && window.innerWidth < 1240 && 59 || 87
  const [mouseHoverNavSize, setMouseHoverNavSize] = useState(initMainNavHeight)

  const competencesHighlights = [
    isLangDE ? `${config.localeDirectories.competences.de}/der-weg-zu-ihrem-individuellen-boden` :
    `${config.localeDirectories.root.en}${config.localeDirectories.competences.en}/the-way-to-your-individual-floor`
  ]

  useEffect(
    () => {
      const className = 'modal-open'
      const element = window.document.body
      if (menuOverlay) {
        element.classList.add(className)
      } else {
        element.classList.remove(className)
      }
      return function cleanup() {
        element.classList.remove(className)
      }
    },
    [menuOverlay] // Only re-call effect when value changes
  )

  const handleCloseClick = () => {
    setMenuOverlay(false)
  }

  const handleMenuClick = () => {
    setMenuOverlay(true)
  }

  const handleMouseEnter = (e, hasChildren) => {
    if(hasChildren) setMouseHoverNavSize(177)
  }

  const handleMouseLeave = () => {
    setMouseHoverNavSize(initMainNavHeight)
  }

  const translationLinkDE = translations && translations.filter(item => item.language.slug === "de")[0] || { uri: null }
  const translationLinkEN = translations && translations.filter(item => item.language.slug === "en")[0] || { uri: null }

  return (
    <>
      <Headroom disableInlineStyles>
        <div
          className="navigation d-flex px-1 pt-3 pt-lg-5 pb-3 pb-lg-5"
          style={{
            height: `${mouseHoverNavSize}px`,
          }}
        >
          <PageTransitionLink internalLink="/"><MenuLogoSvg className="menu-logo" /></PageTransitionLink>
          <nav className="desktop-navigation d-none d-lg-block w-md-22 ml-auto">
            <ul className="primary-menu d-flex justify-content-between">
              {
                mainMenu[0].node.menuItems.nodes.map((menuItem) => {
                  const currentLocation = `/${location.pathname.split('/')[isLangDE ? 1 : 2]}/`
                  const isSubDirectory = location.pathname.split('/').filter(Boolean).length > 1 || false
                  const currentSlug = menuItem.url
                  let parentActive = false

                  if (currentLocation === currentSlug) {
                    // parent (custom post type) active
                    parentActive = true
                  } else if(menuItem.childItems.nodes && menuItem.childItems.nodes.length > 0) {
                    // children (without url hint) active
                    parentActive = menuItem.childItems.nodes.filter(edge => edge.url === currentLocation).length > 0
                  }

                  return !menuItem.parentId && (
                    <li
                      key={menuItem.url}
                      className={
                        classNames(
                          "menu-item-level-1",
                          menuItem.childItems.nodes && menuItem.childItems.nodes.length > 0 && "has-children"
                        )
                      }
                      onMouseEnter={(e) => {handleMouseEnter(e, menuItem.childItems.nodes && menuItem.childItems.nodes.length > 0 || false)}}
                      onMouseLeave={() => {handleMouseLeave()}}
                    >
                      <PageTransitionLink
                        internalLink={menuItem.type !== "custom" && menuItem.url}
                        externalLink={menuItem.type === "custom" && menuItem.url}
                        className={classNames("mb-1 mb-lg-0", parentActive ? "is-active" : "")}
                        activeClass="is-active"
                      >
                        {menuItem.label}
                      </PageTransitionLink>
                      {
                        menuItem.childItems.nodes && (
                          <ul className="sub-menu d-flex flex-column">
                            {
                              menuItem.childItems.nodes.map(item => (
                                <li
                                  key={item.url || item.url}
                                  className={
                                    classNames(
                                      "menu-item-level-2 mb-1",
                                      isSubDirectory &&
                                      item.url === currentLocation ||
                                      item.url.split('/').filter(Boolean).filter(dir => dir === (isLangDE ? "boden" : "flooring")).length &&
                                      competencesHighlights.filter(competency => competency === location.pathname).length
                                      ? "is-active" : ""
                                    )
                                  }
                                >
                                  <PageTransitionLink
                                    internalLink={item.url}
                                    activeClass="is-active"
                                  >
                                    {item.label}
                                  </PageTransitionLink>
                                </li>
                              ))
                            }
                          </ul>
                        )
                      }
                    </li>
                  )
                })
              }
              <li className="menu-item-level-1 language-switcher">
                {isLangDE && translationLinkEN && (
                  <PageTransitionLink
                    internalLink={translationLinkEN.uri || `${location.pathname}/`}
                    lang={translationLinkEN.uri && "en" || ""}
                    activeClassName="is-active"
                  >
                    {translationLinkEN.uri && "EN" || "DE"}
                  </PageTransitionLink>
                ) || (
                  <PageTransitionLink
                    internalLink={translationLinkDE.uri || `${location.pathname}/`}
                    lang={translationLinkDE.uri && "de" || ""}
                    activeClassName="is-active"
                  >
                    {translationLinkDE.uri && "DE" || "EN"}
                  </PageTransitionLink>
                )}
              </li>
            </ul>
          </nav>
          <MenuIconSvg className="menu-open-icon d-block d-lg-none ml-auto" onClick={handleMenuClick} />
        </div>
      </Headroom>
      <NavigationOverlay closeHandler={handleCloseClick} menus={menus} menuOpen={menuOverlay} />
    </>
  )
}
export default MainNavigation
