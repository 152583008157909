/* eslint-disable no-console */
import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { useGlobal } from "reactn";
import { createPath } from '../../../helper/localePath';
import { convertPath } from '../../../helper/convertPath';
import { localeDirectories } from '../../../../data/SiteConfig';

import "./PageTransition.scss"

const PageTransitionLink = props => {
  const {
    externalLink,
    internalLink,
    lang,
    children,
    onClick,
    className,
    state,
  } = props
  // console.log("internalLink", internalLink)

  if(!externalLink && !internalLink) return null;

  let modifiedPath = "";

  if(internalLink){
    const directories = convertPath(internalLink) && convertPath(internalLink).lowestDir
    const slugModifed = convertPath(internalLink) && convertPath(internalLink).slug
    // console.log("slugModifed", slugModifed)

    if(!directories && !slugModifed) return null
    const [langState] = useGlobal("lang");

    modifiedPath = createPath(
      localeDirectories,
      lang || langState || "de",
      directories,
      slugModifed
    )

  } else {
    modifiedPath = externalLink
  }

  return (
    <>
      {
        internalLink ? (
          <TransitionLink
            className={className}
            onClick={onClick}
            to={modifiedPath}
            exit={{ length: 0.5 }}
            entry={{ delay: 0.5, length: 0.5 }}
            state={state}
          >
            {children}
          </TransitionLink>
        ) : (
          <a
            href={modifiedPath}
            className={className}
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        )
      }
    </>
  );
};

export default PageTransitionLink
