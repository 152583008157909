/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react"
import { useGlobal } from "reactn"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import PageTransitionLink from "Layout/PageTransition/PageTransitionLink.jsx"
import "./CurrentNewsTeaserSection.scss"
import Teaser from "Layout/Teaser/Teaser.jsx"
import SectionHeadline from "Accessories/SectionHeadline/SectionHeadline.jsx"
import {getImage} from "gatsby-plugin-image";

const CurrentNewsTeaserSection = ({ headline, linkText, internalLink }) => {
  const allPosts = useStaticQuery(graphql`query CurrentNewsTeaserSectionQuery {
  allWpPost(filter: {title: {ne: "DO NOT DELETE"}}) {
    edges {
      node {
        internal {
          type
        }
        language {
          slug
        }
        title
        link
        databaseId
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 680, quality: 80, placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
}
`)

  const [lang] = useGlobal("lang")
  const isLangDE = lang === "de"
  const linkLangString = isLangDE ? "Entdecken" : "Explore"
  const filterPostsLang = allPosts.allWpPost.edges.filter(item => item.node.language.slug === lang)

  const shufflePosts = (a) => {
    for (let i = a.length - 1; i > 0; i-=1) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]]
    }
    return a
  }

  const [shuffledPosts, setShuffledPosts] = useState(null)
  useEffect(() => {
    const shuffledPostsIn = shufflePosts(filterPostsLang)
    setShuffledPosts(shuffledPostsIn)
  }, [])

  return (
    <div className="section current-news-teaser-section">
      <div className="container">
        {headline && <SectionHeadline headline={headline} />}
        <div className="grid-2 grid-md-3 grid-xs-1 grid-rg-5 px-xs-2 px-md-0">
          {
            shuffledPosts?.map((post, index) => {
              if(post?.node?.featuredImage?.node == null || post?.node?.featuredImage?.node?.localFile == null) return null
              if(index >= 3) return null
              return (
                <Teaser
                  key={`${post.node.databaseId}--${index}`}
                  alignCenter
                  altText={post?.node?.featuredImage?.node?.altText || post?.node?.featuredImage?.node?.title}
                  image={getImage(post?.node?.featuredImage?.node?.localFile)}
                  previewSrc={{src: post?.node?.featuredImage?.node?.sourceUrl || post?.node && `${post?.node?.featuredImage?.node}` || null}}
                  text={post?.node?.teaserDescription || post?.node?.title}
                  linkText={linkLangString}
                  internalLink={post?.node?.link}
                />
              );
            })
          }
        </div>
        <div className="row justify-content-center mt-5 mt-md-10">
          <PageTransitionLink internalLink={internalLink} className="text-link">
            {linkText}
          </PageTransitionLink>
        </div>
      </div>
    </div>
  );
}

CurrentNewsTeaserSection.propTypes = {
  headline: PropTypes.string
}

export const query = graphql`
  fragment PageCurrentNewsTeaserSectionFragment on WpPage_Flex_Content_CurrentNewsTeaserSection {
    headline
    internalLink {
      ... on WpContentNode {
        uri
      }
    }
    linkText
  }
  fragment PostCurrentNewsTeaserSectionFragment on WpPost_Flex_Content_CurrentNewsTeaserSection {
    headline
    internalLink {
      ... on WpContentNode {
        uri
      }
    }
    linkText
  }
  fragment PressFeatureCurrentNewsTeaserSectionFragment on WpPressFeature_Flex_Content_CurrentNewsTeaserSection {
    headline
    internalLink {
      ... on WpContentNode {
        uri
      }
    }
    linkText
  }
  fragment ProjectCurrentNewsTeaserSectionFragment on WpProject_Flex_Content_CurrentNewsTeaserSection {
    headline
    internalLink {
      ... on WpContentNode {
        uri
      }
    }
    linkText
  }
  fragment CompetenceCurrentNewsTeaserSectionFragment on WpCompetence_Flex_Content_CurrentNewsTeaserSection {
    headline
    internalLink {
      ... on WpContentNode {
        uri
      }
    }
    linkText
  }
  fragment JobCurrentNewsTeaserSectionFragment on WpJob_Flex_Content_CurrentNewsTeaserSection {
    headline
    internalLink {
      ... on WpContentNode {
        uri
      }
    }
    linkText
  }
`

export default CurrentNewsTeaserSection
