import React from 'react';
import { graphql } from 'gatsby'
import SectionHeadline from 'Accessories/SectionHeadline/SectionHeadline.jsx';
import PageTransitionLink from "Layout/PageTransition/PageTransitionLink.jsx"
import VideoPlayer from "Accessories/VideoPlayer/VideoPlayer.jsx"
import Teaser from 'Layout/Teaser/Teaser.jsx';
import RemoveClassInview from "Accessories/RemoveClassInview/RemoveClassInview.jsx";
import './JobApplicationSection.scss';
import {getImage, getSrc} from "gatsby-plugin-image";

const JobApplicationSection = props => {
  const { headline, subHeadline, contactPerson, showVideo, applicationFormPage, applicationFormPageCta, video, poster } = props

  // This component has various implicit rendering modes
  // 1. If no contact person is given: render nothing
  // 2. If the contact person is a special comployee created for job application only
  // 2.1 Render a simple colorized row with contact data only
  // 2.2 Render the contact data paired with a video (if configured)
  // 3. Render the contact person if its a real human person

  if (!contactPerson) {
    return null
  }

  if (contactPerson.employeeInfo.employee.name === "Karriere bei EHAM" || contactPerson.employeeInfo.employee.name === "Career with EHAM") {
    const content = (
      <>
        <SectionHeadline
          headline={headline || "Bewerbungsformular"}
          copy={subHeadline && subHeadline}
        />
        <div className="row justify-content-center">
          <div className="teaser-container w-23 w-md-11">
            <div className="teaser-description text-align-center">
              {applicationFormPage && (
                <div className="pt-3 pb-7">
                  <PageTransitionLink
                    internalLink={applicationFormPage.uri}
                    className="btn btn-submit btn-border"
                  >
                    {applicationFormPageCta}
                  </PageTransitionLink>
                </div>
              )}
              <p className="multi-link-wrapper">
                <a href={`mailto:${contactPerson.employeeInfo.employee.email}`} className="teaser-mail d-inline text-link">{contactPerson.employeeInfo.employee.email}</a>
                <br />
                <a href={`tel:${contactPerson.employeeInfo.employee.phone}`} className="teaser-phone d-inline text-link">{contactPerson.employeeInfo.employee.phone}</a>
              </p>
            </div>
          </div>
        </div>
      </>
    )

    if (!showVideo) {
      return (
        <div className="section job-application-section job-application-section--plain">
          <div className="container">
            {content}
          </div>
        </div>
      )
    }

    return (
      <div className="section job-application-section grid-17-20 ">
        <div className="row">
          <div className="text-side flex-column align-self-center mx-auto">
            {content}
          </div>
          <RemoveClassInview>
            <div className="w-46 w-md-31 ml-auto image-side">
              <VideoPlayer
                video={video.mediaItemUrl}
                poster={getSrc(poster?.localFile) || poster}
                hasControls
              />
            </div>
          </RemoveClassInview>
        </div>
      </div>
    );
  }

  return (
    <div className="section job-application-section">
      <div className="container">
        <SectionHeadline
          headline={headline || "Bewerbungsformular"}
          copy={subHeadline && subHeadline}
        />
        <div className="row justify-content-center">
          <div className="teaser-container w-23 w-md-11">
            <Teaser
              key={getSrc(contactPerson.employeeInfo.employee.image.localFile)}
              alignCenter
              altText={contactPerson.employeeInfo.employee.image && contactPerson.employeeInfo.employee.image.altText || contactPerson.employeeInfo.employee.image && contactPerson.employeeInfo.employee.image.title}
              image={getImage(contactPerson?.employeeInfo?.employee?.image?.localFile)}
              previewSrc={{
                src: contactPerson.employeeInfo.employee.image && contactPerson.employeeInfo.employee.image.sourceUrl || contactPerson.employeeInfo && `${contactPerson.employeeInfo.employee.image}` || null
              }}
              text={contactPerson.employeeInfo.employee.name || contactPerson.employeeInfo.employee.name}
              subText={contactPerson.employeeInfo && contactPerson.employeeInfo.employee && contactPerson.employeeInfo.employee.function}
              subTextIsHeadline
              linkText={contactPerson.employeeInfo.employee.email}
              externalLink={contactPerson.employeeInfo.employee.email}
              linkSecondaryTarget={contactPerson.employeeInfo.employee.phone}
              linkType="email"
              linkStyle="appear"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`fragment PageJobApplicationSectionFragment on WpPage_Flex_Content_JobApplicationSection {
  headline
  subHeadline
  applicationFormPage {
    ... on WpPage {
      uri
    }
  }
  applicationFormPageCta
  showVideo
  video {
    mediaItemUrl
    mediaDetails {
      height
      width
    }
  }
  videoPosterImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
  contactPerson {
    ... on WpEmployee {
      id
      employeeInfo {
        employee {
          name
          email
          phone
          competence {
            ... on WpCompetence {
              databaseId
            }
          }
          function
          image {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
}

fragment PostJobApplicationSectionFragment on WpPost_Flex_Content_JobApplicationSection {
  headline
  subHeadline
  applicationFormPage {
    ... on WpPage {
      uri
    }
  }
  applicationFormPageCta
  showVideo
  video {
    mediaItemUrl
    mediaDetails {
      height
      width
    }
  }
  videoPosterImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
  contactPerson {
    ... on WpEmployee {
      id
      employeeInfo {
        employee {
          name
          email
          phone
          competence {
            ... on WpCompetence {
              databaseId
            }
          }
          function
          image {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
}

fragment PressFeatureJobApplicationSectionFragment on WpPressFeature_Flex_Content_JobApplicationSection {
  headline
  subHeadline
  applicationFormPage {
    ... on WpPage {
      uri
    }
  }
  applicationFormPageCta
  showVideo
  video {
    mediaItemUrl
    mediaDetails {
      height
      width
    }
  }
  videoPosterImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
  contactPerson {
    ... on WpEmployee {
      id
      employeeInfo {
        employee {
          name
          email
          phone
          competence {
            ... on WpCompetence {
              databaseId
            }
          }
          function
          image {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
}

fragment ProjectJobApplicationSectionFragment on WpProject_Flex_Content_JobApplicationSection {
  headline
  subHeadline
  applicationFormPage {
    ... on WpPage {
      uri
    }
  }
  applicationFormPageCta
  showVideo
  video {
    mediaItemUrl
    mediaDetails {
      height
      width
    }
  }
  videoPosterImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
  contactPerson {
    ... on WpEmployee {
      id
      employeeInfo {
        employee {
          name
          email
          phone
          competence {
            ... on WpCompetence {
              databaseId
            }
          }
          function
          image {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
}

fragment CompetenceJobApplicationSectionFragment on WpCompetence_Flex_Content_JobApplicationSection {
  headline
  subHeadline
  applicationFormPage {
    ... on WpPage {
      uri
    }
  }
  applicationFormPageCta
  showVideo
  video {
    mediaItemUrl
    mediaDetails {
      height
      width
    }
  }
  videoPosterImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
  contactPerson {
    ... on WpEmployee {
      id
      employeeInfo {
        employee {
          name
          email
          phone
          competence {
            ... on WpCompetence {
              databaseId
            }
          }
          function
          image {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
}

fragment JobJobApplicationSectionFragment on WpJob_Flex_Content_JobApplicationSection {
  headline
  subHeadline
  applicationFormPage {
    ... on WpPage {
      uri
    }
  }
  applicationFormPageCta
  showVideo
  video {
    mediaItemUrl
    mediaDetails {
      height
      width
    }
  }
  videoPosterImage {
    sourceUrl
    mediaDetails {
      height
      width
    }
    altText
    title
    localFile {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
  contactPerson {
    ... on WpEmployee {
      id
      employeeInfo {
        employee {
          name
          email
          phone
          competence {
            ... on WpCompetence {
              databaseId
            }
          }
          function
          image {
            sourceUrl
            mediaDetails {
              height
              width
            }
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
}
`

export default JobApplicationSection;
