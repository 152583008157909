import React from 'react';
import { useGlobal } from "reactn";
import { graphql, useStaticQuery } from 'gatsby';
import PostOverview from 'Layout/PostOverview/PostOverview.jsx'

import './CompetenceSection.scss';


const CompetenceSection = (props) => {

  const data = useStaticQuery(graphql`query CompetenceSectionFilterQuery {
  competences: allWpCompetence(filter: {title: {ne: "DO NOT DELETE"}}) {
    edges {
      node {
        databaseId
        title
        language {
          slug
        }
        slug
        uri
        postImages {
          postImages {
            portaitImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 862, quality: 80, placeholder: NONE, layout: CONSTRAINED)
                }
              }
            }
            landscapeImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
}
`);

  const [lang] = useGlobal("lang");

  const { text } = props
  const competences = data.competences.edges;
  const cleanCompetences = competences.filter(competence => competence.node.language.slug === lang)

  return (
    <div className="competence-section section">
      <div className="container">
        <PostOverview items={cleanCompetences} initialItems={cleanCompetences} text={text} postType="competence" />
      </div>
    </div>
  );
};

export const query = graphql`
  fragment PageCompetenceSectionFragment on WpPage_Flex_Content_CompetenceSection {
    text
  }
  fragment PostCompetenceSectionFragment on WpPost_Flex_Content_CompetenceSection {
    text
  }
  fragment PressFeatureCompetenceSectionFragment on WpPressFeature_Flex_Content_CompetenceSection {
    text
  }
  fragment ProjectCompetenceSectionFragment on WpProject_Flex_Content_CompetenceSection {
    text
  }
  fragment CompetenceCompetenceSectionFragment on WpCompetence_Flex_Content_CompetenceSection {
    text
  }
  fragment JobCompetenceSectionFragment on WpJob_Flex_Content_CompetenceSection {
    text
  }
`;

export default CompetenceSection;
