/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState, useRef} from "react"
import {graphql} from "gatsby"
import PropTypes from "prop-types"
import classNames from "classnames"
import Swiper from "react-id-swiper"
import Button from "Accessories/Button/Button.jsx"
import SectionHeadline from "Accessories/SectionHeadline/SectionHeadline.jsx"
import TeaserDescription from "Layout/Teaser/TeaserDescription/TeaserDescription.jsx"
import ImageContainer from "Accessories/ImageContainer/ImageContainer.jsx"
import RemoveClassInview from "Accessories/RemoveClassInview/RemoveClassInview.jsx"
import "./TimelineSliderSection.scss"
import {getImage, getSrc} from "gatsby-plugin-image";

const TimelineSliderSection = ({
    headline, slides
  }) => {
  const [swiper, updateSwiper] = useState(null)

  const mySwiperRef = useRef(null)

  if(!slides || !slides.length) return null

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const setPagination = () => {
    const swiperPagination = mySwiperRef.current.querySelector(".swiper-pagination")
    const imageContainer = mySwiperRef.current.querySelector(".image-container")
    swiperPagination.style.top = `${imageContainer.clientHeight}px`
  }

  const swiperParams = {
    slidesPerView: "auto",
    grabCursor: false,
    lazy: true,
    loop: true,
    loopedSlides: slides.length * 2,
    speed: 1200,
    parallax: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    on: {
      resize: () => setPagination(),
    },
    activeSlideKey: 0,
  }

  useEffect(() => {
    let timeout

    if (swiper !== null && slides.length > 0) {
      timeout = setTimeout(() => {
          setPagination()

          mySwiperRef.current.classList.add("show-swiper")
          swiper.update()
      }, 1000)
    }
    return () => {
      clearTimeout(timeout)
    }
  },[swiper])

  useEffect(() => {
    let timeout;

    // This is a hacky workaround as the legacy version of Swiper used in this project does not work properly
    // with React
    if (swiper && swiper.params.loopedSlides) {
      timeout = setTimeout(() => {
        swiper.loopDestroy();
        swiper.loopCreate();
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [swiper]);

  const renderFigures = items => (
    items && items.map((item, index) => {

      if(item.slideImage && item.slideImage.localFile) {
        return (
          <div
            className="slide-wrapper d-flex w-47"
            key={getSrc(item.slideImage.localFile) || index}
          >
            <div className="text-side w-md-12 w-40">
              <div className="ml-1 mt-md-0 mt-2 d-flex align-self-end">
                <TeaserDescription
                  headline={item.slideHeadline}
                  text={item.slideText}
                />
              </div>
            </div>
            <div className="image-side ml-auto">
              <figure className={classNames((Math.floor(item.slideImage.mediaDetails.width/item.slideImage.mediaDetails.height*100)/100) > 1 ? "w-46 w-md-31" : "w-24 w-md-15")}>
                <ImageContainer
                  altText={item.slideImage.altText || item.slideImage.title}
                  image={getImage(item?.slideImage?.localFile)}
                  previewSrc={{
                    src: item.slideImage.sourceUrl || typeof item.slideImage === "string" && item.slideImage || null
                  }}
                  useStandardImage
                  aspectRatios={[1.378, 0.666]}
                  classes="mb-1"
                  dataSwiperParallax="10%"
                  dataSwiperParallaxScale="1.2"
                />
                {/* Removing empty figcaption will make pagination disappear */}
                <figcaption className="pr-1" />
              </figure>
            </div>
          </div>
        );
      }
      return null
    }
  ))

  return (
    <div ref={mySwiperRef} className="section timline-slider-section">
      <div className="container">
        { headline &&
          <SectionHeadline headline={headline} />
        }
        {
          slides && (
            <RemoveClassInview onClick={goNext}>
              <div onClick={goNext} className={classNames(slides.length > 1 && "swiper-element row")}>
                <Button
                  icon="arrow"
                  classes="bg-dark-gray-translucent btn-circle"
                  onClick={goNext}
                />
                <Swiper {...swiperParams} getSwiper={updateSwiper}>
                  {
                    renderFigures(slides)
                  }
                </Swiper>
              </div>
            </RemoveClassInview>
          )
        }
      </div>
    </div>
  )
}

TimelineSliderSection.propTypes = {
  headline: PropTypes.string,
  teaserImage: PropTypes.instanceOf(Object),
  teaserText: PropTypes.string,
  teaserLink: PropTypes.string,
  teaserLinkText: PropTypes.string,
  slides: PropTypes.instanceOf(Array)
}

export const query = graphql`fragment PageTimelineSliderSectionFragement on WpPage_Flex_Content_TimelineSliderSection {
  headline
  slides {
    slideHeadline
    slideText
    slideImage {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment PostTimelineSliderSectionFragement on WpPost_Flex_Content_TimelineSliderSection {
  headline
  slides {
    slideHeadline
    slideText
    slideImage {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment PressFeatureTimelineSliderSectionFragement on WpPressFeature_Flex_Content_TimelineSliderSection {
  headline
  slides {
    slideHeadline
    slideText
    slideImage {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment ProjectTimelineSliderSectionFragement on WpProject_Flex_Content_TimelineSliderSection {
  headline
  slides {
    slideHeadline
    slideText
    slideImage {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment CompetenceTimelineSliderSectionFragement on WpCompetence_Flex_Content_TimelineSliderSection {
  headline
  slides {
    slideHeadline
    slideText
    slideImage {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}

fragment JobTimelineSliderSectionFragement on WpJob_Flex_Content_TimelineSliderSection {
  headline
  slides {
    slideHeadline
    slideText
    slideImage {
      sourceUrl
      mediaDetails {
        height
        width
      }
      altText
      title
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`

export default TimelineSliderSection
