import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './TextSection.scss';

const TextSection = props => {

  // eslint-disable-next-line no-unused-vars
  const { headline, text, textColumns } = props;
  const [ hasVideoWrapper, setHasVideoWrapper ] = useState(false);

  const filterItems = () => {
    const paragraphCollection = document.getElementsByTagName("p");

    for(let i = 0; i < paragraphCollection.length; i += 1) {
      if(paragraphCollection[i].children.length && paragraphCollection[i].children[0].localName === "iframe") {
        paragraphCollection[i].classList.add("video-wrapper")
        setHasVideoWrapper(true)
      }
    }
  }

  useEffect(() => {
    filterItems()
  }, []);

  return (
    <div className="section text-section">
      <div className="container">
        <div className="row">
          {
            headline && (
              <div className="headline-column mr-1 w-47 w-md-12 mb-5">
                <h3 className="ml-1">{headline}</h3>
              </div>
            )
          }
          <div className={classNames("text-column w-47 w-md-26 w-lg-22 ml-1 ml-md-auto", hasVideoWrapper ? "" : "mr-1")}>
            {
                  text && (
                    <div className="text-container mb-5" dangerouslySetInnerHTML={{__html: text}} />
                  )
                }
            {
                  textColumns && (
                    <div className="text-column-wrapper d-md-flex">
                      {
                        textColumns.map(column => (
                          <div
                            key={column.textColumn}
                            className="text-column"
                            dangerouslySetInnerHTML={{__html: column.textColumn}}
                          />
                        ))
                      }
                    </div>
                  )
                }
          </div>
        </div>
      </div>
    </div>
  );
};

TextSection.propTypes = {
  headline: PropTypes.string,
  text: PropTypes.string,
  textColumns: PropTypes.instanceOf(Array),
};

export const query = graphql`
  fragment PageTextSectionFragment on WpPage_Flex_Content_TextSection {
    headline
    text
    textColumns {
      textColumn
    }
  }
  fragment PostTextSectionFragment on WpPost_Flex_Content_TextSection {
    headline
    text
    textColumns {
      textColumn
    }
  }
  fragment PressFeatureTextSectionFragment on WpPressFeature_Flex_Content_TextSection {
    headline
    text
    textColumns {
      textColumn
    }
  }
  fragment ProjectTextSectionFragment on WpProject_Flex_Content_TextSection {
    headline
    text
    textColumns {
      textColumn
    }
  }
  fragment CompetenceTextSectionFragment on WpCompetence_Flex_Content_TextSection {
    headline
    text
    textColumns {
      textColumn
    }
  }
  fragment JobTextSectionFragment on WpJob_Flex_Content_TextSection {
    headline
    text
    textColumns {
      textColumn
    }
  }
`

export default TextSection;
