import React from 'react';
import { graphql } from "gatsby";
import PropTypes from 'prop-types';
import SectionHeadline from '../../Accessories/SectionHeadline/SectionHeadline';

const HeadlineSection = props => {

  // eslint-disable-next-line no-unused-vars
  const { headline, subHeadline } = props

  return (
    <div className="headline-section">
      <SectionHeadline
        headline={headline}
        copy={subHeadline}
      />
    </div>
  );
};

HeadlineSection.propTypes = {
  headline: PropTypes.string,
  subHeadline: PropTypes.string,
};

export const query = graphql`
  fragment PageHeadlineSectionFragment on WpPage_Flex_Content_HeadlineSection {
    headline
    subHeadline
  }
  fragment PostHeadlineSectionFragment on WpPost_Flex_Content_HeadlineSection {
    headline
    subHeadline
  }
  fragment PressFeatureHeadlineSectionFragment on WpPressFeature_Flex_Content_HeadlineSection {
    headline
    subHeadline
  }
  fragment ProjectHeadlineSectionFragment on WpProject_Flex_Content_HeadlineSection {
    headline
    subHeadline
  }
  fragment CompetenceHeadlineSectionFragment on WpCompetence_Flex_Content_HeadlineSection {
    headline
    subHeadline
  }
  fragment JobHeadlineSectionFragment on WpJob_Flex_Content_HeadlineSection {
    headline
    subHeadline
  }
`

export default HeadlineSection;
